import { useState, useEffect } from "react";
import { getVersion } from "Functions/tableroApi";
import { GitVersion } from "Models/models";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { jsDates } from "Functions/functions";
import packageJson from "../../../package.json";

export default function About(): JSX.Element {
  const [git, setgit] = useState<GitVersion>();
  useEffect(() => {
    getVersion().then((ver) => {
      setgit(ver);
    });
  }, []);
  const Version = `v${packageJson.version}`;
  const VersionGit = git?.name;
  const VersionGitDate = jsDates(git?.authored_date);
  return (
    <>
      {Version !== VersionGit && (
        <>
          <h4>Sistema Desactualizado</h4>
          <br />
          <h5>Versión instalda: {Version}</h5>
          <br/>
          <h5>
            Ultima Versión: {VersionGit} {VersionGitDate}
          </h5>
        </>
      )}
      {Version === VersionGit && <h4>{git?.name}</h4>}
      <hr/>
      <p>
        {git?.message} {}
      </p>
      <MarkdownPreview
        wrapperElement={{
          "data-color-mode": "light",
        }}
        source={git?.description}
      />
    </>
  );
}
