import React, { useState, useContext, useEffect, ChangeEvent } from "react";
import { GlobalStatesContext } from "Providers/context";
import { encriptar } from "Functions/login";
import { login } from "Functions/tableroApi";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { GoogleReCaptcha, GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import packageJson from "../../../package.json";
import { createSecureCookie } from "Functions/functions";
import { verify } from "Functions/tableroApi";

function Login() {
  const [seePass, setseePass] = useState(false);
  const [form, setform] = useState({ user: "", pass: "" });
  const { state, setContext } = useContext(GlobalStatesContext);
  const [noBot, setnoBot] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (state.token !== null) {
      navigate(`/login`);
    }
  }, [navigate, state.token]);

  const change = (event: ChangeEvent<HTMLInputElement>) => {
    setform({
      ...form,
      [event.target.name]: event.target.value,
    });
  };


  function onVerify(token: string) {
    return new Promise<void>(function (resolve, reject) {

      verify(token).then(resp => {
        if(resp.success) {
          setnoBot(true);
          resolve();
        } else {
          console.error("Error en Captcha")
          setnoBot(false);
          reject();
        }
      });

    });
}
  

  const validar = () => {
    if (noBot) {
      Login();
    }
  };

  const Login = () => {
    setnoBot(false);
    //localStorage.setItem("login", JSON.stringify(form));
    setContext({ ...state, user: form.user });
    const encrypted = encriptar(form.user, form.pass);
    login(encrypted)
      .then((resp) => {
        //console.log(resp.lastlogOn);
        // const Fecha = new Date(resp.lastlogOn);
        if (resp.valor === 1) {
          // localStorage.setItem("token", resp.token);
         
          createSecureCookie(resp.token);
       
          setContext({
            ...state,
            token: resp.token,
            toast: {
              text: `El usuario ${form.user} se ha conectado ${resp.lastlogOn}`,
              show: true,
            },
          });
          setTimeout(() => {
            navigate(`/dashboard`);
          }, 60);
        } else {
          setContext({
            ...state,
            token: "",
            toast: {
              text: resp.mensaje,
              show: true,
            },
          });
        }
      })
      .catch((err) => {
        setContext({ ...state, toast: { text: err, show: true } });
      });
  };

  const pressEnter = (key: React.KeyboardEvent<HTMLElement>) => {
    if (key.key === "Enter") {
      validar();
    }
  };

  return (
    <div className="loginContainer" onKeyDown={pressEnter}>
      <div className="loginSistemaID">
        <div className="loginTitulo textFont"> TABLERO COMANDOS </div>
        <div className="loginSubTitulo textFont">descripción del sistema</div>
      </div>
      <div className="loginContenido">
        <input
          type="text"
          className="input iUser"
          name="user"
          placeholder="Nombre de Usuario"
          onChange={change}
        ></input>
        <input
          type={!seePass ? "password" : "text"}
          className="input iPassword"
          name="pass"
          placeholder="Contraseña"
          onChange={change}
        ></input>
        <Icon
          icon={!seePass ? "charm:eye-slash" : "charm:eye"}
          className="iconPassword"
          style={{ color: "#535353", height: "24px", width: "24px" }}
          onClick={() => setseePass(!seePass)}
        />
        <button type="submit" className="loginButton" onClick={validar}>
          aceptar
        </button>
        <a href="sitio" className="linkOlvidastePs">
          ¿olvidaste tu contraseña? <strong>hacé click aquí</strong>
        </a>
        <p className="disclaimer">® Div. Desarrollo - v {packageJson.version}</p>
        <div className="recaptcha">
          <GoogleReCaptchaProvider reCaptchaKey="6LewRMMkAAAAAItg9eHi9HKwi_cD9y06IMldN3OV">
            <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={noBot} />
          </GoogleReCaptchaProvider>
        </div>
      </div>
    </div>
  );
}

export default Login;
