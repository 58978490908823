import { useContext, useState, useEffect } from "react";
import { GlobalStatesContext } from "Providers/context";
import Panel1 from "components/Dashboard/Budget/Panel/Panel1";
import Panel2 from "components/Dashboard/Budget/Panel/Panel2";

export default function Budget() {
  const { state } = useContext(GlobalStatesContext);
  const [fecha, setfecha] = useState<string>();
  const [fecha1, setfecha1] = useState<string>();
  const [fecha2, setfecha2] = useState<string>();
  useEffect(() => {
    const aux = state.date.replace("-", "").replace("-", "");
    const anio = aux.substring(0, aux.length - 4);
    const mes = aux.substring(4, aux.length - 2);

    const Fecha = Number(anio).toString() + mes + "01";
    const Fecha1 = (Number(anio) - 1).toString() + mes + "01";
    const Fecha2 = (Number(anio) - 2).toString() + mes + "01";

    setfecha(Fecha);
    setfecha1(Fecha1);
    setfecha2(Fecha2);
  }, [state.date]);

  return (
    <>
      <div className="containerContent horizontal">
        {fecha !== undefined &&
          fecha1 !== undefined &&
          fecha2 !== undefined && (
            <>
            <div className="card mitadHoriPlusMinus">
              <Panel1 Fecha={fecha} Fecha1={fecha1} Fecha2={fecha2} />
            </div>
            <div className="card mitadHoriPlusPlus">
              <Panel2 Fecha={fecha} Fecha1={fecha1} Fecha2={fecha2} />
            </div>
            </>
          )}
      </div>

    </>
  );
}
