import { useEffect, useState } from "react";
import { PotencialesAPI } from "Functions/tableroApi";
import { Potenciales, dateCompProps } from "Models/models";
import { ParseYearMonthDay } from "Functions/functions";
import Numbers from "components/Numbers/Numbers";

export default function AgenciasMayorRiesgoPotencial(props: dateCompProps) {
  const [AgenciasMayorRiesgoPotencial, setAgenciasMayorRiesgoPotencial] =
    useState<Potenciales.AgenciasMayorRiesgoPotencial[]>();

  useEffect(() => {
    const DateSplit = ParseYearMonthDay(props.date);
    PotencialesAPI.getAgenciasMayorRiesgoPotencial(
      Number(DateSplit?.day),
      Number(DateSplit?.month),
      Number(DateSplit?.year)
    ).then((data) => {
      setAgenciasMayorRiesgoPotencial(data);
    });
  }, [props.date]);

  return (
    <>
      <div className="tablaContainerComercialXS" id="potentialTablaSecond">
        <div className="cardTitulo">
          <div className="icon "></div> Agencias con Mayor Riesgo Potencial [
          {ParseYearMonthDay(props.date)?.day}/
          {ParseYearMonthDay(props.date)?.month}/
          {ParseYearMonthDay(props.date)?.year}]
        </div>
        <table className="tabla" id="potenciales">
          <thead className="textFont">
            <tr>
              <th>Monto Apostado</th>
              <th>Premio Potencial</th>
              <th>agencia</th>
              <th>localidad</th>
              <th>loteria</th>
            </tr>
          </thead>
          <tbody>
            {AgenciasMayorRiesgoPotencial?.map((item, i) => {
              return (
                <tr key={`agenciasmasticketsvendidos${i}`}>
                  <td>
                    <div className="celda dataFont">
                      <span className="dataSymbol">$</span>
                      <Numbers value={item.MontoApostado} />
                    </div>
                  </td>
                  <td>
                    <div className="celda dataFont">
                      <span className="dataSymbol">$</span>
                      <Numbers value={item.PremioPotencial}/>
                    </div>
                  </td>

                  <td>
                    <div className="celda celdaCenter textFont">{item.Agencia}</div>
                  </td>
                  <td>
                    <div className="celda textFont">{item.Localidad}</div>
                  </td>
                  <td>
                    <div className="celda celdaCenter textFont">{item.Loterias}</div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
