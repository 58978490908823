import img from "../../img/error404.png";

export default function Error404() {
  document.title = "Error 404 | Página no econtrada";
  return (
    <>
      <img src={img} alt="Error 404 página no encontrada"></img>
    </>
  );
}
