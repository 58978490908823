import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./custom.scss";
import "leaflet/dist/leaflet.css";
import { GlobalStatesProvider } from "./Providers/context";
import { TCToast } from "components/Toast/Toast";
import { TCModal } from "components/Modal/Modal";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { motion } from "framer-motion";

import Login from "components/Login/Login";
import Dashboard from "components/Dashboard/Dashboard";
import TragIAFAS from "components/Dashboard/TragIAFAS/TragIAFAS";
import TragCon from "components/Dashboard/TragCon/TragCon";
import Casinos from "components/Dashboard/Casinos/Casinos";
import Comercial from "components/Dashboard/Comercial/Comercial";
import OnLine from "components/Dashboard/OnLine/OnLine";
import ComparativeBusinessUnits from "components/Dashboard/ComparativeBusinessUnits/ComparativeBusinessUnits";
import AgencyDensity from "components/Dashboard/AgencyDensity/AgencyDensity";
import CasinosSalasMaps from "components/Dashboard/CasinosSalasMaps/CasinosSalasMaps";
import PotentialPrizes from "components/Dashboard/PotentialPrizes/PotentialPrizes";
import Partial from "components/Dashboard/Partial/Partial";
import Budget from "components/Dashboard/Budget/Budget";
import UploadBudget from "components/Dashboard/Budget/Upload";
import Setting from "components/Dashboard/Setting/Setting";

import Home from "components/Dashboard/Home/Home";
import Error403 from "components/Error/Error403";
import Error404 from "components/Error/Error404";


/*
const Login = lazy(() => import('components/Login/Login'));
const Dashboard = lazy(() => import('components/Dashboard/Dashboard'));
const TragIAFAS = lazy(() => import('components/Dashboard/TragIAFAS/TragIAFAS'));
const TragCon = lazy(() => import('components/Dashboard/TragCon/TragCon'));
const Casinos = lazy(() => import('components/Dashboard/Casinos/Casinos'));
const Comercial = lazy(() => import('components/Dashboard/Comercial/Comercial'));
const OnLine = lazy(() => import('components/Dashboard/OnLine/OnLine'));
const ComparativeBusinessUnits = lazy(() => import('components/Dashboard/ComparativeBusinessUnits/ComparativeBusinessUnits'));
const AgencyDensity = lazy(() => import('components/Dashboard/AgencyDensity/AgencyDensity'));
const PotentialPrizes = lazy(() => import('components/Dashboard/PotentialPrizes/PotentialPrizes'));
const Partial = lazy(() => import('components/Dashboard/Partial/Partial'));
const Budget = lazy(() => import('components/Dashboard/Budget/Budget'));
const Setting = lazy(() => import('components/Dashboard/Setting/Setting'));

const Error403 = lazy(() => import('components/Error/Error403'));
const Error404 = lazy(() => import('components/Error/Error404'));
const Home = lazy(() => import('components/Dashboard/Home/Home'));
*/

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <GlobalStatesProvider>
      <motion.div
        initial={{ opacity: 0, scale: 1 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<Error404 />} />
            <Route path="/" element={<App />} />
            <Route path="/login" element={<Login />} />

            <Route path="/dashboard" element={<Dashboard />}>
              <Route index element={<Home />} />
              <Route path="/dashboard/home" element={<Home />} />
              <Route
                path="/dashboard/utilidades/comercial"
                element={<Comercial />}
              />
              <Route
                path="/dashboard/utilidades/tragiafas"
                element={<TragIAFAS />}
              />
              <Route
                path="/dashboard/utilidades/exportar"
                element={<Partial />}
              />
              <Route path="/dashboard/user/setting" element={<Setting />} />
              <Route path="/dashboard/utilidades/tragcon" element={<TragCon />} />
              <Route path="/dashboard/utilidades/casinos" element={<Casinos />} />
              <Route path="/dashboard/utilidades/online" element={<OnLine />} />
              <Route
                path="/dashboard/utilidades/comparativos"
                element={<ComparativeBusinessUnits />}
              />
              <Route
                path="/dashboard/infocomercial/densdeagencias"
                element={<AgencyDensity />}
              />
              <Route 
              path="/dashboard/maps"
              element={<CasinosSalasMaps/>}/>
              <Route
                path="/dashboard/infocomercial/premiospotenciales"
                element={<PotentialPrizes />}
              />
              <Route path="/dashboard/presupuestos" element={<Budget />} />
              <Route path="/dashboard/presupuestosupload" element={<UploadBudget />} />
            </Route>
            <Route path="/Error403" element={<Error403 />} />
            <Route path="/Error404" element={<Error404 />} />
          </Routes>
          <TCToast />
          <TCModal />
        </BrowserRouter>
      </motion.div>

    </GlobalStatesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
