import React, { useEffect, useState, useContext, useCallback } from "react";
import {
  getAportes,
  getEstadoCuentas,
  getEstadoFlujoCaja,
  getParteDiario,
} from "Functions/tableroApi";
import {
  dateCompProps,
  Graph,
  banckAccounts,
  ParteDiario,
} from "Models/models";
import { ParseYearMonthDay } from "Functions/functions";
import Numbers from "components/Numbers/Numbers";
import { Bar } from "react-chartjs-2";
import { GlobalStatesContext } from "Providers/context";
import CashFlow from "components/Dashboard/Home/CashFlow";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  BarElement,
} from "chart.js";
import ContributionsDetails from "components/Dashboard/Home/ContributionsDetails";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const optionsRight: ChartOptions<'bar'> = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || "";

          if (label) {
            label += ": ";
          }
          if (context.parsed.y !== null) {
            label = label + " $ " + Number(context.parsed.y.toFixed(2)) + " M";
          }
          return label;
        },
      },
    },
  },
};

interface contributionForYearType {
  anio: string;
  importe: number;
}

export default function Contributions(props: dateCompProps) {
  const [dataTable, setdataTable] = useState<contributionForYearType[]>();
  const [dataGraph, setdataGraph] = useState<Graph.ChartData>();
  const [bankA, setbankA] = useState<banckAccounts[]>();
  const [flujoCaja, setflujoCaja] = useState<number>();
  const [parteDiario, setparteDiario] = useState<ParteDiario[]>();
  const { state, setContext } = useContext(GlobalStatesContext);

  useEffect(() => {
    UpdateData();

  }, [props.date]);

  useEffect(() => {
    const timer = setInterval(() => {
      UpdateData();
    }, 60000);
    return () => clearInterval(timer);

  }, []);

  const salirModal = () => {
    setContext({
      ...state,
      modal: {
        ...state.modal,
        show: false,

      },
    });
  }

  const DetalleFlujoCaja = useCallback(
    () => {
      setContext({
        ...state,
        modal: {
          ...state.modal,
          body: <CashFlow date={state.date} />,
          title: "Tablero de comandos",
          subtitle: "Flujo de Caja",
          show: true,
          button: false,
          closeModal: salirModal,
        },
      });
    },
    [],
  )


  const DetalleAportes = () => {
    setContext({
      ...state,
      modal: {
        ...state.modal,
        body: <ContributionsDetails date={state.date}></ContributionsDetails>,
        subtitle: "Detalle",
        show: true,
        closeModal: salirModal,
      },
    });
  };

  const UpdateData = () => {
    const DateSplit = ParseYearMonthDay(props.date);
    const Years: string[] = [];
    const Contributiontly: number[] = [];
    const contributionsData: contributionForYearType[] = [];
    getAportes(Number(DateSplit?.year))
      .then((contributions) => {
        contributions.forEach((contribution) => {
          if (contribution.Orden === 0) {
            Years.push(contribution.A1.toString());
            Years.push(contribution.A2.toString());
            Years.push(contribution.A3.toString());
            Years.push(contribution.A4.toString());
          }
          if (contribution.Orden === 2) {
            Contributiontly.push(contribution.A1 / 1000000);
            Contributiontly.push(contribution.A2 / 1000000);
            Contributiontly.push(contribution.A3 / 1000000);
            Contributiontly.push(contribution.A4 / 1000000);
          }
        });
        Years.forEach((years, i) => {
          contributionsData.push({
            anio: Years[i],
            importe: Contributiontly[i],
          });
        });
        setdataTable(contributionsData);

        const labels = Years;
        const data = Contributiontly;

        const dataRight = {
          labels,
          datasets: [
            {
              label: "Aportes",
              data,
              backgroundColor: [
                "rgba(142, 214, 146, 0.5)",
                "rgba(31, 209, 41, 0.5)",
                "rgba(30, 148, 36, 0.5)",
                "rgba(13, 101, 18, 0.5)",
              ],
              borderColor: [
                "rgba(142, 214, 146, 1)",
                "rgba(31, 209, 41, 1)",
                "rgba(30, 148, 36, 1)",
                "rgba(13, 101, 18, 1)",
              ],
              borderWidth: 1,
            },
          ],
        };

        setdataGraph(dataRight);
      })
      .catch(() => {
        console.error("No esta corriendo la API");
        // window.location.replace("/login");
      });
    getEstadoCuentas(
      Number(DateSplit?.day),
      Number(DateSplit?.month),
      Number(DateSplit?.year)
    ).then((est) => {
      setbankA(est);
    });
    getEstadoFlujoCaja(
      Number(props.date.replace("-", "").replace("-", "")),
      0
    ).then((flujoCaja) => {
      if (flujoCaja[0].Importe !== undefined) {
        setflujoCaja(flujoCaja[0].Importe / 1000000);
      }
    });
    getParteDiario(
      Number(props.date.replace("-", "").replace("-", "")),
      0,
      0
    ).then((parteDiario) => {
      setparteDiario(parteDiario);
    });
  };
  return (
    <div className="card cardRight" >
      <div className="cardTitulo cursorManito" onClick={DetalleAportes}>
        <div className="icon"></div>APORTES IAFAS
      </div>
      <div>
        <table className="tabla tablaRight" key={"aportesiafas"}>
          <tbody>
            {dataTable?.map((cont, i) => {
              return (
                <tr key={i + "contrib"}>
                  <td>
                    <div className="celda textFont">
                      <div className="icon"></div>
                      {cont.anio}
                    </div>
                  </td>
                  <td>
                    <div className="celda dataFont">
                      <span className="dataSymbol">$</span>
                      <Numbers value={cont.importe} />
                      <span>M</span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div id="graphContainerRight">
          {dataGraph !== undefined && (
            <Bar
              id="graphContainerRight"
              options={optionsRight}
              data={dataGraph}
            />
          )}
        </div>
      </div>
      <div className="cardTitulo">
        <div className="icon "></div>ESTADOS DE CUENTA
      </div>
      <table className="tabla tablaRight" key={"estadoCuentas"}>
        <tbody>
          {bankA?.map((acc, i) => {
            return (
              <React.Fragment key={`${i}${acc.Cuenta}`}>
                <tr>
                  <td>
                    <div className="celda textFont">
                      <div className="icon"></div>
                      {acc.Cuenta} B
                    </div>
                  </td>
                  <td>
                    <div className="celda dataFont">
                      <span className="dataSymbol">$</span>
                      <Numbers value={acc.Bancaria} />
                    </div>
                  </td>
                </tr>
                {acc.Cuenta === "9003/0         " && (
                  <tr>
                    <td>
                      <div className="celda textFont">
                        <div className="icon"></div>
                        {acc.Cuenta} C
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={acc.Contable} />
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <div id="flujoCaja" className="cardTitulo" onClick={DetalleFlujoCaja}>
        <div className="icon "></div>FLUJO DE CAJA
      </div>
      <table className="tabla tablaRight" key={"flujoCaja"}>
        <tbody>
          <tr key={"flujo"}>
            <td>
              <div className="celda textFont">
                <div className="icon"></div>9003/0
              </div>
            </td>
            <td>
              <div className="celda dataFont">
                {flujoCaja !== undefined && (
                  <>
                    <span className="dataSymbol">$</span>
                    <Numbers value={flujoCaja} />
                    <span>M</span>
                  </>
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="cardTitulo">
        <div className="icon"></div>PERSONAL
      </div>
      <table className="tabla tablaRight" key={"parteDiario"}>
        <thead className="textFont">
          <tr>
            <th></th>
            <th>casinos</th>
            <th>salas</th>
          </tr>
        </thead>
        <tbody>
          {parteDiario !== undefined &&
            parteDiario.map((pd, i) => {
              return (
                <tr key={i + 201}>
                  <td>
                    <div className="celda textFont">
                      <div className="icon"></div>
                      {pd.Nombre}
                    </div>
                  </td>
                  <td>
                    <div className="celda celdaCenter dataFont">
                      <Numbers value={pd.Central} intenger={true} />
                    </div>
                  </td>
                  <td>
                    <div className="celda celdaCenter dataFont">
                      <Numbers value={pd.Casinos} intenger={true} />
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
