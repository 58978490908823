import { useEffect, useState, useContext } from "react";
import { GlobalStatesContext } from "Providers/context";
import { ComparativosUnidades } from "Models/models";
import {
  getcomparativojuegos,
  getcomparativojuegosUtilidadNetaOnline,
} from "Functions/tableroApi";
import { ParseYearMonthDay } from "Functions/functions";
import Numbers from "components/Numbers/Numbers";
import TrafficLight from "components/TrafficLight/TrafficLight";
import GraphComparative from "components/Dashboard/ComparativeBusinessUnits/GraphComparative";

export default function ComparativeBusinessUnits() {
  const [source, setsource] = useState(true);
  const [dataU, setdataU] = useState<ComparativosUnidades[]>();
  const [dataR, setdataR] = useState<ComparativosUnidades[]>();
  const [dataUc, setdataUc] = useState<ComparativosUnidades[]>();
  //const [dataRc, setdataRc] = useState<ComparativosUnidades[]>();

  const [totalesUc, settotalesUc] = useState<ComparativosUnidades>({
    AnualActual: 0,
    AnualAnterior: 0,
    AnualPorcentaje: 0,
    Juego: "",
    MesActual: 0,
    MesAnterior: 0,
    MesPorcentaje: 0,
    Orden: "",
  });
  const [totalesRc, settotalesRc] = useState<ComparativosUnidades>({
    AnualActual: 0,
    AnualAnterior: 0,
    AnualPorcentaje: 0,
    Juego: "",
    MesActual: 0,
    MesAnterior: 0,
    MesPorcentaje: 0,
    Orden: "",
  });

  const [totalesU, settotalesU] = useState<ComparativosUnidades>({
    AnualActual: 0,
    AnualAnterior: 0,
    AnualPorcentaje: 0,
    Juego: "",
    MesActual: 0,
    MesAnterior: 0,
    MesPorcentaje: 0,
    Orden: "",
  });
  const [totalesR, settotalesR] = useState<ComparativosUnidades>({
    AnualActual: 0,
    AnualAnterior: 0,
    AnualPorcentaje: 0,
    Juego: "",
    MesActual: 0,
    MesAnterior: 0,
    MesPorcentaje: 0,
    Orden: "",
  });
  const { state, setContext } = useContext(GlobalStatesContext);

  useEffect(() => {
    const DateSplit = ParseYearMonthDay(state.date);
    let AccR: ComparativosUnidades[] = [];
    let AccU: ComparativosUnidades[] = [];

    let comparativo = {
      AccR,
      AccU,
    };

    if (DateSplit !== undefined) {
      setContext({ ...state, spinner: { loading: true } });
      getcomparativojuegos(DateSplit.day, DateSplit.month, DateSplit.year)
        .then(async (comp) => {
          comparativo = await calcularComparativoJuegos(comp, 1);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setdataR(comparativo.AccR);
          setdataU(comparativo.AccU);
          setContext({ ...state, spinner: { loading: false } });
        });
    }

    AccR = [];
    AccU = [];

    comparativo = {
      AccR,
      AccU,
    };

    if (DateSplit !== undefined) {
      setContext({ ...state, spinner: { loading: true } });
      getcomparativojuegosUtilidadNetaOnline(
        DateSplit.day,
        DateSplit.month,
        DateSplit.year
      )
        .then(async (comp) => {
          comparativo = await calcularComparativoJuegos(comp, 2);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setdataUc(comparativo.AccU);
          setContext({ ...state, spinner: { loading: false } });
        });
    }
  }, [state.date]);

  const calcularComparativoJuegos = async (
    comp: ComparativosUnidades[],
    tipo: number
  ) => {
    const AccR: ComparativosUnidades[] = [];
    const AccU: ComparativosUnidades[] = [];

    let MensualActual = 0;
    let MensualAnterior = 0;
    let AnualActual = 0;
    let AnualAnterior = 0;

    let UMensualActual = 0;
    let UMensualAnterior = 0;
    let UAnualActual = 0;
    let UAnualAnterior = 0;

    comp.forEach((row) => {
      if (row.Orden === "Rec" && row.Juego !== "Total" && tipo === 1) {
        AccR.push(row);

        if (row.MesActual !== null)
          MensualActual = MensualActual + row.MesActual;
        if (row.MesAnterior !== null)
          MensualAnterior = MensualAnterior + row.MesAnterior;
        if (row.AnualActual !== null)
          AnualActual = AnualActual + row.AnualActual;
        if (row.AnualAnterior !== null)
          AnualAnterior = AnualAnterior + row.AnualAnterior;
      }

      if (row.Orden === "Uti" && row.Juego !== "Total") {
        AccU.push(row);
        if (row.MesActual !== null)
          UMensualActual = UMensualActual + row.MesActual;
        if (row.MesAnterior !== null)
          UMensualAnterior = UMensualAnterior + row.MesAnterior;
        if (row.AnualActual !== null)
          UAnualActual = UAnualActual + row.AnualActual;
        if (row.AnualAnterior !== null)
          UAnualAnterior = UAnualAnterior + row.AnualAnterior;
      }
    });
    if (tipo === 1) {
      settotalesR({
        ...totalesR,
        MesActual: MensualActual,
        MesAnterior: MensualAnterior,
        AnualActual: AnualActual,
        AnualAnterior: AnualAnterior,
      });
      settotalesU({
        ...totalesR,
        MesActual: UMensualActual,
        MesAnterior: UMensualAnterior,
        AnualActual: UAnualActual,
        AnualAnterior: UAnualAnterior,
      });
    }

    if (tipo === 2) {
      settotalesRc({
        ...totalesRc,
        MesActual: MensualActual,
        MesAnterior: MensualAnterior,
        AnualActual: AnualActual,
        AnualAnterior: AnualAnterior,
      });
      settotalesUc({
        ...totalesRc,
        MesActual: UMensualActual,
        MesAnterior: UMensualAnterior,
        AnualActual: UAnualActual,
        AnualAnterior: UAnualAnterior,
      });
    }

    return { AccR, AccU };
  };

  return (
    <>
      <div className="cardContainerGral">
        <div className="cardContainerSup">
          <div className="card mitadSemiPlus">
            <div className="cardTitulo">
              <div className="icon "></div>TOTALES COMPARATIVOS - FICHAJE [
              {ParseYearMonthDay(state.date)?.day}/
              {ParseYearMonthDay(state.date)?.month}/
              {ParseYearMonthDay(state.date)?.year}]
            </div>
            <table className="tabla">
              <thead>
                <tr className="cardTotales">
                  <th>
                    <div className="celda dataFont smallTotal">
                      dif. mes / año
                    </div>
                  </th>
                  <th colSpan={2}>
                    <div className="celda dataFont smallTotal">
                      <span className="dataSymbol">$</span>
                      <Numbers
                        value={totalesR.MesActual - totalesR.MesAnterior}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="celda dataFont smallTotal"></div>
                  </th>
                  <th colSpan={2}>
                    <div className="celda dataFont smallTotal">
                      <span className="dataSymbol">$</span>
                      <Numbers
                        value={totalesR.AnualActual - totalesR.AnualAnterior}
                      />
                    </div>
                  </th>
                </tr>
                <tr className="cardTotales">
                  <th>
                    <div className="celda dataFont smallTotal">
                      totales mes / año
                    </div>
                  </th>
                  <th>
                    <div className="celda dataFont smallTotal">
                      <span className="dataSymbol">$</span>
                      <Numbers value={totalesR.MesActual} />
                    </div>
                  </th>
                  <th>
                    <div className="celda dataFont smallTotal">
                      <span className="dataSymbol">$</span>
                      <Numbers value={totalesR.MesAnterior} />
                    </div>
                  </th>
                  <th>
                    <div className="celda dataFont smallTotal porcentaje">
                      <Numbers
                        value={
                          (totalesR.MesActual / totalesR.MesAnterior) * 100 -
                          100
                        }
                      />
                      <span>%</span>
                    </div>
                  </th>
                  <th>
                    <div className="celda dataFont smallTotal">
                      <span className="dataSymbol">$</span>
                      <Numbers value={totalesR.AnualActual} />
                    </div>
                  </th>
                  <th>
                    <div className="celda dataFont smallTotal">
                      <span className="dataSymbol">$</span>
                      <Numbers value={totalesR.AnualAnterior} />
                    </div>
                  </th>
                  <th>
                    <div className="celda dataFont smallTotal">
                      <span className="dataSymbol"></span>
                      <Numbers
                        value={
                          (totalesR.AnualActual / totalesR.AnualAnterior) *
                            100 -
                          100
                        }
                      />{" "}
                      <span>%</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <thead className="textFont">
                <tr>
                  <th>recaudación / juego</th>
                  <th>mes actual</th>
                  <th>mes anterior</th>
                  <th>%</th>
                  <th>anual actual</th>
                  <th>anual anterior</th>
                  <th>%</th>
                </tr>
              </thead>
              <tbody>
                {dataR !== undefined &&
                  dataR.map((rec, i) => {
                    return (
                      <tr key={`comparativosjuegos${i}`}>
                        <td>
                          <div className="celda textFont">{rec.Juego}</div>
                        </td>
                        <td>
                          <div className="celda dataFont">
                            <span className="dataSymbol">$</span>
                            <Numbers value={rec.MesActual} />
                          </div>
                        </td>
                        <td>
                          <div className="celda dataFont">
                            <span className="dataSymbol">$</span>
                            <Numbers value={rec.MesAnterior} />
                          </div>
                        </td>
                        <td>
                          <div className="celda dataFont porcentaje">
                            <TrafficLight value={rec.MesPorcentaje} />
                          </div>
                        </td>
                        <td>
                          <div className="celda dataFont">
                            <span className="dataSymbol">$</span>
                            <Numbers value={rec.AnualActual} />
                          </div>
                        </td>
                        <td>
                          <div className="celda dataFont">
                            <span className="dataSymbol">$</span>
                            <Numbers value={rec.AnualAnterior} />
                          </div>
                        </td>
                        <td>
                          <div className="celda dataFont porcentaje">
                            <TrafficLight value={rec.AnualPorcentaje} />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="card mitadSemiPlus">
            <div className="cardTitulo">
              <div className="icon "></div>TOTALES COMPARATIVOS - UTILIDADES [
              {ParseYearMonthDay(state.date)?.day}/
              {ParseYearMonthDay(state.date)?.month}/
              {ParseYearMonthDay(state.date)?.year}]
            </div>
            <table className="tabla">
              <thead>
                <tr className="cardTotales">
                  <th>
                    <div className="celda dataFont smallTotal">
                      dif. mes / año
                    </div>
                  </th>
                  <th colSpan={2}>
                    <div className="celda dataFont smallTotal">
                      <span className="dataSymbol">$</span>
                      <Numbers
                        value={totalesU.MesActual - totalesU.MesAnterior}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="celda dataFont smallTotal"></div>
                  </th>
                  <th colSpan={2}>
                    <div className="celda dataFont smallTotal">
                      <span className="dataSymbol">$</span>
                      <Numbers
                        value={totalesU.AnualActual - totalesU.AnualAnterior}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="celda dataFont smallTotal"></div>
                  </th>
                </tr>
              </thead>
              <thead>
                <tr className="cardTotales">
                  <th>
                    <div className="celda dataFont smallTotal">
                      totales mes / año
                    </div>
                  </th>
                  <th>
                    <div className="celda dataFont smallTotal">
                      <span className="dataSymbol">$</span>
                      <Numbers value={totalesU.MesActual} />
                    </div>
                  </th>
                  <th>
                    <div className="celda dataFont smallTotal">
                      <span className="dataSymbol">$</span>
                      <Numbers value={totalesU.MesAnterior} />
                    </div>
                  </th>
                  <th>
                    <div className="celda dataFont smallTotal porcentaje">
                      <Numbers
                        value={
                          (totalesU.MesActual / totalesU.MesAnterior) * 100 -
                          100
                        }
                      />
                      <span>%</span>
                    </div>
                  </th>
                  <th>
                    <div className="celda dataFont smallTotal">
                      <span className="dataSymbol">$</span>
                      <Numbers value={totalesU.AnualActual} />
                    </div>
                  </th>
                  <th>
                    <div className="celda dataFont smallTotal">
                      <span className="dataSymbol">$</span>
                      <Numbers value={totalesU.AnualAnterior} />
                    </div>
                  </th>
                  <th>
                    <div className="celda dataFont smallTotal">
                      <span className="dataSymbol"></span>
                      <Numbers
                        value={
                          (totalesU.AnualActual / totalesU.AnualAnterior) *
                            100 -
                          100
                        }
                      />{" "}
                      <span>%</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <thead className="textFont">
                <tr>
                  <th>utilidad / juego</th>
                  <th>mes actual</th>
                  <th>mes anterior</th>
                  <th>%</th>
                  <th>anual actual</th>
                  <th>anual anterior</th>
                  <th>%</th>
                </tr>
              </thead>
              <tbody>
                {dataU !== undefined &&
                  dataU.map((rec, i) => {
                    return (
                      <tr key={`comparativosjuegosUtilidades${i}`}>
                        <td>
                          <div className="celda textFont">{rec.Juego}</div>
                        </td>
                        <td>
                          <div className="celda dataFont">
                            <span className="dataSymbol">$</span>
                            <Numbers value={rec.MesActual} />
                          </div>
                        </td>
                        <td>
                          <div className="celda dataFont">
                            <span className="dataSymbol">$</span>
                            <Numbers value={rec.MesAnterior} />
                          </div>
                        </td>
                        <td>
                          <div className="celda dataFont porcentaje">
                            <TrafficLight value={rec.MesPorcentaje} />
                          </div>
                        </td>
                        <td>
                          <div className="celda dataFont">
                            <span className="dataSymbol">$</span>
                            <Numbers value={rec.AnualActual} />
                          </div>
                        </td>
                        <td>
                          <div className="celda dataFont">
                            <span className="dataSymbol">$</span>
                            <Numbers value={rec.AnualAnterior} />
                          </div>
                        </td>
                        <td>
                          <div className="celda dataFont porcentaje">
                            <TrafficLight value={rec.AnualPorcentaje} />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="cardContainerInf">
          <div className="card mitadPlus">
            <div className="cardTitulo">
              <div className="icon "></div>TOTALES COMPARATIVOS - UTILIDADES [
              {ParseYearMonthDay(state.date)?.day}/
              {ParseYearMonthDay(state.date)?.month}/
              {ParseYearMonthDay(state.date)?.year}]
            </div>
            <div className="inline">
              <div className="cardTitulo">
                <div className="icon "></div>
              </div>
              <div
                className={
                  source ? "bt btText switchActive" : "bt btText switchInactive"
                }
                onClick={() => setsource(!source)}
              >
                {"neta"}
              </div>
              <div
                className={
                  source ? "bt btText switchInactive" : "bt btText switchActive"
                }
                onClick={() => setsource(!source)}
              >
                {"bruta"}
              </div>
            </div>

            {dataU !== undefined && dataUc !== undefined && (
              <GraphComparative datos={source ? dataUc : dataU} />
            )}
          </div>
          <div className="card mitadPlus">
            <div className="">
              <div className="cardTitulo">
                <div className="icon "></div>
                {`${"TOTALES COMPARATIVOS - UTILIDADES NETA ["}${
                  ParseYearMonthDay(state.date)?.day
                }/${ParseYearMonthDay(state.date)?.month}/${
                  ParseYearMonthDay(state.date)?.year
                }]`}
              </div>
              <table className="tabla">
                <thead>
                  <tr className="cardTotales">
                    <th>
                      <div className="celda dataFont smallTotal">
                        dif. mes / año
                      </div>
                    </th>
                    <th colSpan={2}>
                      <div className="celda dataFont smallTotal">
                        <span className="dataSymbol">$</span>
                        <Numbers
                          value={totalesUc.MesActual - totalesUc.MesAnterior}
                        />
                      </div>
                    </th>
                    <th>
                      <div className="celda dataFont smallTotal"></div>
                    </th>
                    <th colSpan={2}>
                      <div className="celda dataFont smallTotal">
                        <span className="dataSymbol">$</span>
                        <Numbers
                          value={
                            totalesUc.AnualActual - totalesUc.AnualAnterior
                          }
                        />
                      </div>
                    </th>
                    <th>
                      <div className="celda dataFont smallTotal"></div>
                    </th>
                  </tr>
                </thead>
                <thead>
                  <tr className="cardTotales">
                    <th>
                      <div className="celda dataFont smallTotal">
                        totales mes / año
                      </div>
                    </th>
                    <th>
                      <div className="celda dataFont smallTotal">
                        <span className="dataSymbol">$</span>
                        <Numbers value={totalesUc.MesActual} />
                      </div>
                    </th>
                    <th>
                      <div className="celda dataFont smallTotal">
                        <span className="dataSymbol">$</span>
                        <Numbers value={totalesUc.MesAnterior} />
                      </div>
                    </th>
                    <th>
                      <div className="celda dataFont smallTotal porcentaje">
                        <Numbers
                          value={
                            (totalesUc.MesActual / totalesUc.MesAnterior) *
                              100 -
                            100
                          }
                        />
                        <span>%</span>
                      </div>
                    </th>
                    <th>
                      <div className="celda dataFont smallTotal">
                        <span className="dataSymbol">$</span>
                        <Numbers value={totalesUc.AnualActual} />
                      </div>
                    </th>
                    <th>
                      <div className="celda dataFont smallTotal">
                        <span className="dataSymbol">$</span>
                        <Numbers value={totalesUc.AnualAnterior} />
                      </div>
                    </th>
                    <th>
                      <div className="celda dataFont smallTotal">
                        <span className="dataSymbol"></span>
                        <Numbers
                          value={
                            (totalesUc.AnualActual / totalesUc.AnualAnterior) *
                              100 -
                            100
                          }
                        />{" "}
                        <span>%</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <thead className="textFont">
                  <tr>
                    <th>utilidad / juego</th>
                    <th>mes actual</th>
                    <th>mes anterior</th>
                    <th>%</th>
                    <th>anual actual</th>
                    <th>anual anterior</th>
                    <th>%</th>
                  </tr>
                </thead>
                <tbody>
                  {dataUc !== undefined &&
                    dataUc.map((rec, i) => {
                      return (
                        <tr key={`comparativosjuegosUtilidades${i}`}>
                          <td>
                            <div className="celda textFont">{rec.Juego}</div>
                          </td>
                          <td>
                            <div className="celda dataFont">
                              <span className="dataSymbol">$</span>
                              <Numbers value={rec.MesActual} />
                            </div>
                          </td>
                          <td>
                            <div className="celda dataFont">
                              <span className="dataSymbol">$</span>
                              <Numbers value={rec.MesAnterior} />
                            </div>
                          </td>
                          <td>
                            <div className="celda dataFont porcentaje">
                              <TrafficLight value={rec.MesPorcentaje} />
                            </div>
                          </td>
                          <td>
                            <div className="celda dataFont">
                              <span className="dataSymbol">$</span>
                              <Numbers value={rec.AnualActual} />
                            </div>
                          </td>
                          <td>
                            <div className="celda dataFont">
                              <span className="dataSymbol">$</span>
                              <Numbers value={rec.AnualAnterior} />
                            </div>
                          </td>
                          <td>
                            <div className="celda dataFont porcentaje">
                              <TrafficLight value={rec.AnualPorcentaje} />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
