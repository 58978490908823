import img from "../../img/error403.png";

export default function Error403() {
  document.title = "Error 403 | Error de permisos";
  return (
    <>
      <img src={img} alt="Error 403 - faltan permisos"></img>
    </>
  );
}
