/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import {  Presupuesto } from "Models/models";
import { prespuesto } from "Functions/tableroApi";
import Numbers from "components/Numbers/Numbers";


interface ResourcesDetailsProps {
  concepto: string;
  date: string;
}

export default function ExpenseDetails(props: ResourcesDetailsProps) {
  const [datos, setdatos] = useState<Presupuesto.GastosDetalle[]>();

  useEffect(() => {
    prespuesto
      .getGastosDetalle(Number(props.date.replace("-", "").replace("-", "")))
      .then((detail) => {
        setdatos(detail);
      });
  }, [props.date]);

  return (
    <>
      {datos?.map((item, i) => {
        if (item.inciso === props.concepto)
          return (
            <React.Fragment key={`tabla${i}`}>
              <table className="tabla">
                <thead className="cardTitulo">
                  <th>Descripcion</th>
                  <th>Credito Actual</th>
                  <th>Devengado</th>
                </thead>
                <tbody>
                  <tr>
                    <td>{item.DESCRIPCION}</td>
                    <td>
                      <Numbers value={item.Devengado}></Numbers>
                    </td>
                    <td>
                      <Numbers value={item.CreditoActual}></Numbers>
                    </td>
                  </tr>
                </tbody>
              </table>
            </React.Fragment>
          );
      })}
    </>
  );
}
