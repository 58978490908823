import { useEffect, useState, useContext } from "react";
import { TragIAFAS } from "Functions/tableroApi";
import { dateCompProps, dataUtilidades } from "Models/models";
import { ParseYearMonthDay } from "Functions/functions";
import Numbers from "components/Numbers/Numbers";
import TrafficLight from "components/TrafficLight/TrafficLight";
import { GlobalStatesContext } from "Providers/context";
import Details from "components/Dashboard/TragIAFAS/Details/Details";
import Bills from "components/Dashboard/TragIAFAS/Bills/Bills";
import SalaInfo from "components/Dashboard/SalaInfo/SalaInfo";

export default function Daily(props: dateCompProps) {
  const { state, setContext } = useContext(GlobalStatesContext);
  const [data, setdata] = useState<dataUtilidades[]>();
  const [totales, settotales] = useState<dataUtilidades>({
    Nombre: "",
    Porcentaje1: 0,
    Porcentaje2: 0,
    PremioMayor: "",
    Premios: 0,
    Recaudacion: 0,
    Utilidad: 0,
    UtilidadNeta: 0,
    Acumulado: 0
  });

  useEffect(() => {
    setContext({ ...state, spinner: { loading: true } });
    const DateSplit = ParseYearMonthDay(props.date);
    TragIAFAS.getTragIAFASDiaria(
      Number(DateSplit?.day),
      Number(DateSplit?.month),
      Number(DateSplit?.year)
    )
      .then((data) => {
        setdata(data);
        let DataRec = 0;
        let DataPre = 0;
        let DataUti = 0;
        //let DataNet = 0;
        let DataNetAcc = 0;
        let DataPor1 = 0;
        let DataPor2 = 0;
        let Acum = 0;
        data.forEach((sub) => {
          if (sub.Recaudacion !== null) DataRec = DataRec + sub.Recaudacion;
          if (sub.Premios !== null) DataPre = DataPre + sub.Premios;
          if (sub.Utilidad !== null) DataUti = DataUti + sub.Utilidad;
          if (sub.NetaAcumulada ) DataNetAcc = DataNetAcc + sub.NetaAcumulada;
          if (sub.Porcentaje1 !== null) DataPor1 = DataPor1 + sub.Porcentaje1;
          if (sub.Porcentaje2 !== null) DataPor2 = DataPor2 + sub.Porcentaje2;
          if (sub.Acumulado ) Acum = Acum + sub.Acumulado;
        });
        settotales({
          ...totales,
          Nombre: "s",
          Recaudacion: DataRec,
          Premios: DataPre,
          Utilidad: DataUti,
          //UtilidadNeta: DataNet,
          NetaAcumulada: DataNetAcc,
          Porcentaje1: (DataUti / DataRec) * 100,
          Porcentaje2: (0 / DataRec) * 100,
          PremioMayor: "no",
          Acumulado: Acum
        });
      })
      .catch(() => {
        setContext({
          ...state,
          toast: {
            text: "Hubo un problema al conectar con el WS.",
            show: true,
          },
        });
        console.log("No esta corriendo la API");
      }).finally(() => {
        setContext({ ...state, spinner: { loading: false } });
      })

  }, [props.date]);


  const getIdCierre = async (sala: string | undefined) => {
    if (sala !== undefined) {
      const idCierre = (await TragIAFAS.getIdCierre(state.date, sala)).IdCierre;
      setContext({
        ...state, modal: {
          ...state.modal, show: true,
          body: <Details idCierre={idCierre} />,
          title: `Detalle sala tragamonedas IAFAS`,
          subtitle: ``,
          closeModal: () => setContext({ ...state, modal: { ...state.modal, show: false } })
        }
      })
    }
  }

  const getBilletero = async (sala: string | undefined) => {
    if (sala !== undefined) {
      const idCierre = (await TragIAFAS.getIdCierre(state.date, sala)).IdCierre;
      setContext({
        ...state, modal: {
          ...state.modal, show: true,
          body: <Bills idCierre={idCierre} />,
          title: `Detalle de billetes`,
          subtitle: ``,
          closeModal: () => setContext({ ...state, modal: { ...state.modal, show: false } })
        }
      })
    }
  }

  const getSalaInfo = async (sala: string | undefined) => {
    if (sala !== undefined) {
      const idSalas: number = (await TragIAFAS.getIdSala(sala)).idSalas;

      setContext({
        ...state, modal: {
          ...state.modal, show: true,
          body: <SalaInfo idSala={idSalas} />,
          title: `Sala Tragamonedas IAFAS`,
          subtitle: ``,
          closeModal: () => setContext({ ...state, modal: { ...state.modal, show: false } })
        }
      })
    }
  }

  return (
    <div className="tablaContainer">
      <div className="cardTitulo">
        <div className="icon "></div>TOTALES DIARIOS [
        {ParseYearMonthDay(props.date)?.day}/
        {ParseYearMonthDay(props.date)?.month}/
        {ParseYearMonthDay(props.date)?.year}]
      </div>
      <table className="tabla">
        <thead>
          <tr className="cardTotales">
            <th>
              <div className="celda dataFont smallTotal">totales</div>
            </th>
            <th>
              <div className="celda dataFont smallTotal">
                <span className="dataSymbol">$</span>
                <Numbers value={totales.Recaudacion} />
              </div>
            </th>
            <th>
              <div className="celda dataFont smallTotal">
                <span className="dataSymbol">$</span>
                <Numbers value={totales.Premios} />
              </div>
            </th>
            <th>
              <div className="celda dataFont smallTotal">
                <span className="dataSymbol">$</span>
                <Numbers value={totales.Utilidad} />
              </div>
            </th>
            <th>
              <div className="celda dataFont smallTotal">
                <span className="dataSymbol">$</span>
                {totales.Acumulado && <Numbers value={totales.Acumulado} />}
                
              </div>
            </th>
            <th>
              <div className="celda dataFont smallTotal porcentaje">
                <Numbers value={totales.Porcentaje1} />
                <span>%</span>
              </div>
            </th>
            <th>
              <div className="celda dataFont bigTotal">
                <span className="dataSymbol">$</span>
               {totales.NetaAcumulada &&  <Numbers total={true} value={totales.NetaAcumulada} />} 
              </div>
            </th>
            <th>
              <div className="celda dataFont smallTotal porcentaje">
                <Numbers value={totales.Porcentaje2} />
                <span>%</span>
              </div>
            </th>
          </tr>
        </thead>
        <thead className="textFont">
          <tr>
            <th>sala</th>
            <th>recaudación</th>
            <th>premios</th>
            <th>utilidad bruta</th>
            <th>ut. bruta acumulada</th>
            <th>%</th>
            <th>utilidad neta Acc</th>
            <th>%</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((trag, i) => {
            return (
              <tr key={`daily${i}`}>
                <td>
                  <div className="celda celdaKey">{trag.Nombre?.substring(13, trag.Nombre.length)}
                    <div style={{ display: "flex", flexDirection: "row", }}>
                      <span title='Detalle del cierre de jornada' onClick={() => getIdCierre(trag.Nombre)} className="btIcon btIconDetailSala iconDetalleJor sinEspacios" />
                      <span title='Detalle de billetes' onClick={() => getBilletero(trag.Nombre)} className="btIcon btIconDetailSala iconDetalleBill sinEspacios" />
                      <span title='Detalle de sala' onClick={() => getSalaInfo(trag.Nombre)} className="btIcon btIconDetailSala iconDetalleSala sinEspacios" />
                    </div>
                  </div>
                </td>
                <td>
                  <div className="celda dataFont">
                    <span className="dataSymbol">$</span>
                    <Numbers value={trag.Recaudacion} />
                  </div>
                </td>
                <td>
                  <div className="celda dataFont">
                    <span className="dataSymbol">$</span>
                    <Numbers value={trag.Premios} />
                  </div>
                </td>
                <td>
                  <div className="celda dataFont">
                    <span className="dataSymbol">$</span>
                    <Numbers value={trag.Utilidad} />
                  </div>
                </td>
                <td>
                  <div className="celda dataFont">
                    <span className="dataSymbol">$</span>
                    {trag.Acumulado && <Numbers value={trag.Acumulado} />}
                  </div>
                </td>
                <td className="celda dataFont porcentaje">
                  <TrafficLight value={trag.Porcentaje1} />
                </td>
                <td>
                <div className="celda dataFont">
                    <span className="dataSymbol">$</span>
                   {trag.NetaAcumulada && <Numbers value={trag.NetaAcumulada} />} 
                  </div>
                  
                </td>
                <td className="celda dataFont porcentaje">
                  <TrafficLight value={trag.Porcentaje2} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}