import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Graph} from "Models/models";
import { ComparativosUnidades } from "Models/models";


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options: ChartOptions = {
  maintainAspectRatio: false,
  responsive: true,

  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || "";

          if (label) {
            label += ": ";
          }
          if (context.parsed.y !== null) {
            label += new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(context.parsed.y);
          }
          return label;
        },
      },
    },
  },
  animations: {
    tension: {
      duration: 1000,
      easing: "linear",
      from: 0,
      to: 0.5,
      loop: false,
    },
  },
};

interface GraphComparativeprops {
  datos: ComparativosUnidades[];
}

export default function GraphComparative(props: GraphComparativeprops) {
  const [data, setdata] = useState<Graph.ChartData>();
  useEffect(() => {
    const label: string[] = [];
    const mensual: number[] = [];
    const anterior: number[] = [];

    props.datos.forEach((data) => {
      label.push(data.Juego.replace('TOTAL','').replace('Tragamonedas','Trag.').replace('Tombola', 'Tomb.').replace('Concesionadas', 'Conces.'));
      mensual.push(data.MesActual);
      anterior.push(data.MesAnterior);
    });
    const dataArr = {
      labels: label,
      datasets: [
        {
          label: "Mes actual",
          data: mensual,
          backgroundColor: [
            "rgba(142, 214, 146, 0.5)",
            "rgba(31, 209, 41, 0.5)",
          ],
          borderColor: ["rgba(142, 214, 146, 1)", "rgba(31, 209, 41, 1)"],
          borderWidth: 1,
        },
        {
          label: "Mes anterior",
          data: anterior,
          backgroundColor: ["rgba(30, 148, 36, 0.5)", "rgba(13, 101, 18, 0.5)"],
          borderColor: ["rgba(30, 148, 36, 1)", "rgba(13, 101, 18, 1)"],
          borderWidth: 1,
        },
      ],
    };
    setdata(dataArr);
  }, [props.datos]);

  return (
    <>
        <div id="graphContainerComp01">
          {data !== undefined && (
            <Bar id="graphContainerComp01" options={options} data={data} />
          )}
        </div>
    </>
  );
}
