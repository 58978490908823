import { useEffect, useState } from "react";
import { TragIAFAS, TragCon } from "Functions/tableroApi";
import { tragamonedasDetailsCierre } from "Models/models";
import Numbers from "components/Numbers/Numbers";


interface cierreProps {
    idCierre: number;
}

interface CierreDetalleI {
    "Ajuste Ruletas Varios": number;
    "Ajuste Tragamonedas Varios": number;
    "Fichaje Ruletas Concesionada": number;
    "Fichaje Tragamonedas Concesionada": number;
    "Premios Tragamonedas Concesionada": number;
    "Premios Ruletas Concesionada": number;
    "Utilidad Ruletas": number;
    "Utilidad Tragamonedas": number;
}

export default function Details(props: cierreProps) {
    const [cierreInfo, setcierreInfo] = useState<tragamonedasDetailsCierre.TragamonedasIAFASCierreInfo>()
    const [cierreDetalle, setcierreDetalle] = useState<CierreDetalleI>()


    useEffect(() => {
        TragIAFAS.getCierre(props.idCierre).then(
            cierreiInfo => {
                setcierreInfo(cierreiInfo);
            }
        )
        TragCon.getDetalleCierreC(props.idCierre).then(
            cierreDetalle => {
                const aaa = cierreDetalle.filter(
                    (a) => a.Monto !== 0
                )
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const objetosJSON: any = {};
                aaa.forEach((dato) => {
                    const concepto = dato.Concepto.replace('ACP ', '').replace('Caja ', '');
                    const monto = parseFloat(dato.Monto.toString());

                    if (!objetosJSON[concepto]) {
                        objetosJSON[concepto] = monto;
                    } else {
                        objetosJSON[concepto] += monto;
                    }
                });
                objetosJSON["Fichaje Tragamonedas Concesionada"] = objetosJSON["Fichaje Tragamonedas Concesionada"] !== undefined ? objetosJSON["Fichaje Tragamonedas Concesionada"] : 0;
                objetosJSON["Fichaje Ruletas Concesionada"] = objetosJSON["Fichaje Ruletas Concesionada"] !== undefined ? objetosJSON["Fichaje Ruletas Concesionada"] : 0;

                objetosJSON["Premios Tragamonedas Concesionada"] = objetosJSON["Premios Tragamonedas Concesionada"] !== undefined ? objetosJSON["Premios Tragamonedas Concesionada"] : 0;
                objetosJSON["Premios Ruletas Concesionada"] = objetosJSON["Premios Ruletas Concesionada"] !== undefined ? objetosJSON["Premios Ruletas Concesionada"] : 0;

                objetosJSON["Ajuste Tragamonedas Varios"] = objetosJSON["Ajuste Tragamonedas Varios"] !== undefined ? objetosJSON["Ajuste Tragamonedas Varios"] : 0;
                objetosJSON["Ajuste Ruletas Varios"] = objetosJSON["Ajuste Ruletas Varios"] !== undefined ? objetosJSON["Ajuste Ruletas Varios"] : 0;

                objetosJSON["Utilidad Tragamonedas"] = objetosJSON["Utilidad Tragamonedas"] !== undefined ? objetosJSON["Utilidad Tragamonedas"] : 0;
                objetosJSON["Utilidad Ruletas"] = objetosJSON["Utilidad Ruletas"] !== undefined ? objetosJSON["Utilidad Ruletas"] : 0;


                setcierreDetalle(objetosJSON);
            }
        )

    }, [props.idCierre])



    return (<>
        <div className="cardTitulo">
            <div className="icon "></div>Sala {cierreInfo?.Nombre}</div>
        <div className="btText plusRenglon">id cierre</div>
        <span className="dataFont plusRenglon">{cierreInfo?.IdPlanilla}</span><br></br>
        <div className="btText plusRenglon">fecha</div>
        <span className="dataFont plusRenglon">{cierreInfo?.Fecha}</span><br></br>
        <div className="btText plusRenglon">usuario</div>
        <span className="dataFont plusRenglon">{cierreInfo?.Usuario[0]} <strong>({cierreInfo?.Usuario[1]})</strong></span><br></br>

        <div className="cardTitulo" id="datosTitular">
            <div className="icon "></div>Detalle</div>


        <table className="tabla">
            <thead className="textFont">
                <tr>
                    <th>ingresos</th>
                    <th>tragemonedas</th>
                    <th>ruletas</th>
                    <th>total</th>
                </tr>

            </thead>
            <tbody>
                <tr>
                    <td>Fichajes</td>
                    <td><div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        {cierreDetalle !== undefined && <Numbers value={cierreDetalle?.["Fichaje Tragamonedas Concesionada"]} />}
                    </div></td>
                    <td><div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        {cierreDetalle !== undefined && <Numbers value={cierreDetalle?.["Fichaje Ruletas Concesionada"]} />}
                    </div></td>
                    <td><div className="celda dataFont"><span className="dataSymbol">$</span>{cierreDetalle !== undefined && <Numbers total={true} value={cierreDetalle?.["Fichaje Ruletas Concesionada"] + cierreDetalle?.["Fichaje Tragamonedas Concesionada"]} />}</div></td>
                </tr>
                <tr>
                    <td>Premios</td>
                    <td><div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        {cierreDetalle !== undefined && <Numbers value={cierreDetalle?.["Premios Tragamonedas Concesionada"]} />}
                    </div></td>
                    <td><div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        {cierreDetalle !== undefined && <Numbers value={cierreDetalle?.["Premios Ruletas Concesionada"]} />}
                    </div></td>
                    <td><div className="celda dataFont"><span className="dataSymbol">$</span>{cierreDetalle !== undefined && <Numbers total={true} value={cierreDetalle?.["Premios Tragamonedas Concesionada"] + cierreDetalle?.["Premios Ruletas Concesionada"]} />}</div></td>
                </tr>
                <tr>
                    <td>Ajustes</td>
                    <td><div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        {cierreDetalle !== undefined && <Numbers value={cierreDetalle?.["Ajuste Tragamonedas Varios"]} />}
                    </div></td>
                    <td><div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        {cierreDetalle !== undefined && <Numbers value={cierreDetalle?.["Ajuste Ruletas Varios"]} />}
                    </div></td>
                    <td><div className="celda dataFont"><span className="dataSymbol">$</span>{cierreDetalle !== undefined && <Numbers total={true} value={cierreDetalle?.["Ajuste Tragamonedas Varios"] + cierreDetalle?.["Ajuste Ruletas Varios"]} />}</div></td>
                </tr>
                <tr>
                    <td>Utilidad</td>
                    <td><div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        {cierreDetalle !== undefined && <Numbers value={cierreDetalle?.["Utilidad Tragamonedas"]} />}
                    </div></td>
                    <td><div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        {cierreDetalle !== undefined && <Numbers value={cierreDetalle?.["Utilidad Ruletas"]} />}
                    </div></td>
                    <td><div className="celda dataFont"><span className="dataSymbol">$</span>{cierreDetalle !== undefined && <Numbers total={true} value={cierreDetalle?.["Utilidad Tragamonedas"] + cierreDetalle?.["Utilidad Ruletas"]} />}</div></td>
                </tr>
            </tbody>
        </table>


    </>);
}