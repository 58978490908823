import { useEffect, useState, useContext } from "react";
import { Casinos } from "Functions/tableroApi";
import { dateCompProps, dataUtilidadesCasinos } from "Models/models";
import { ParseYearMonthDay } from "Functions/functions";
import Numbers from "components/Numbers/Numbers";
import TrafficLight from "components/TrafficLight/TrafficLight";
import { GlobalStatesContext } from "Providers/context";

export default function Daily(props: dateCompProps) {
  const { state, setContext } = useContext(GlobalStatesContext);
  const [data, setdata] = useState<dataUtilidadesCasinos[]>();
  const [totales, settotales] = useState<dataUtilidadesCasinos>({
    Recaudacion: 0,
    Utilidad: 0,
    CasinoNombre: '',
    Acumulado: 0
  });

  useEffect(() => {
    setContext({ ...state, spinner: { loading: true } });
    const DateSplit = ParseYearMonthDay(props.date);
    Casinos.getCasinosDiaria(
      Number(DateSplit?.day),
      Number(DateSplit?.month),
      Number(DateSplit?.year)
    )
      .then((data) => {
        // console.table(data);
        setdata(data);
        let DataRec = 0;
        let DataUti = 0;
        let DataAcc = 0;
        let DataRecAcc = 0;

        data.forEach((sub) => {
          if (sub.Recaudacion !== null) DataRec = DataRec + sub.Recaudacion;
          if (sub.Utilidad !== null) DataUti = DataUti + sub.Utilidad;
          if (sub.Acumulado !== undefined) DataAcc = DataAcc + sub.Acumulado;
          if (sub.RecAcumulado !== undefined) DataRecAcc = DataRecAcc + sub.RecAcumulado;
        });
        settotales({
          ...totales,
          Recaudacion: DataRec,
          Utilidad: DataUti,
          Acumulado: DataAcc,
          RecAcumulado: DataRecAcc
        });
      })
      .catch(() => {
        setContext({
          ...state,
          toast: {
            text: "Hubo un problema al conectar con el WS.",
            show: true,
          },
        });
        console.log("No esta corriendo la API");
      }).finally(() => {
        setContext({ ...state, spinner: { loading: false } });
      })

  }, [props.date]);

  return (
    <div className="tablaContainer">
      <div className="cardTitulo">
        <div className="icon "></div>TOTALES DIARIOS [
        {ParseYearMonthDay(props.date)?.day}/
        {ParseYearMonthDay(props.date)?.month}/
        {ParseYearMonthDay(props.date)?.year}]
      </div>
      <table className="tabla">
        <thead>
          <tr className="cardTotales">
            <th>
              <div className="celda dataFont smallTotal">total</div>
            </th>
            <th>
              <div className="celda dataFont smallTotal">
                <span className="dataSymbol">$</span>
                <Numbers value={totales.Recaudacion} />
              </div>
            </th>
            <th>
              <div className="celda dataFont smallTotal">
                <span className="dataSymbol">$</span>
                {totales.RecAcumulado && <Numbers total={true} value={totales.RecAcumulado} />}
              </div>
            </th>
            <th>
              <div className="celda dataFont smallTotal">
                <span className="dataSymbol">$</span>
                <Numbers total={true} value={totales.Utilidad} />
              </div>
            </th>
            <th>
              <div className="celda dataFont smallTotal">
                <span className="dataSymbol">$</span>
                {totales.Acumulado && <Numbers total={true} value={totales.Acumulado} />}
              </div>
            </th>
            <th>
              <div className="celda dataFont smallTotal porcentaje">
                <Numbers value={totales.Utilidad / totales.Recaudacion * 100} />
                <span>%</span>
              </div>
            </th>
          </tr>
        </thead>
        <thead className="textFont">
          <tr>
            <th>casino</th>
            <th>fichaje</th>
            <th>f. acumulado</th>
            <th>utilidad</th>
            <th>u. acumulada</th>
            {/** <th>ut. bru. acum.</th>*/}
            <th>%</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((cas, i) => {
            return (
              <tr key={`daily${i}`}>
                <td>
                  <div className="celda textFont">{cas.CasinoNombre}</div>
                </td>
                <td>
                  <div className="celda dataFont">
                    <span className="dataSymbol">$</span>
                    <Numbers value={cas.Recaudacion} />
                  </div>
                </td>

                <td>
                  <div className="celda dataFont">
                    <span className="dataSymbol">$</span>
                    {cas.RecAcumulado && <Numbers value={cas.RecAcumulado} />}
                  </div>
                </td>

                <td>
                  <div className="celda dataFont">
                    <span className="dataSymbol">$</span>
                    <Numbers value={cas.Utilidad} />
                  </div>
                </td>
                {
                  <td>
                    <div className="celda dataFont">
                      <span className="dataSymbol">$</span>
                      {cas.Acumulado && <Numbers value={cas.Acumulado} />}
                    </div>
                  </td>
                }
                <td className="celda dataFont porcentaje">
                  <TrafficLight
                    value={(cas.Utilidad / cas.Recaudacion) * 100}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
