import Resources from "components/Dashboard/Budget/Panel/Resources/Resources";
import ResourcesAccumulated from "components/Dashboard/Budget/Panel/Resources/ResourcesAccumulated";
import { Panelprops } from "Models/models";

export default function Panel1(props: Panelprops) {
  return (
    <>
      <Resources date={props.Fecha}></Resources>
      <ResourcesAccumulated date={props.Fecha1}></ResourcesAccumulated>
      <ResourcesAccumulated date={props.Fecha2}></ResourcesAccumulated>
    </>
  );
}
