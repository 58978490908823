import React, { useEffect, useState } from 'react';
import { getPremiosMayores } from "Functions/tableroApi";
import { PremiosMayores as PR } from "Models/models";
import Numbers from 'components/Numbers/Numbers';


type Props = {
    Fecha: string;
}



export default function PremiosMayores(props: Props) {
    const [premios, setpremios] = useState<PR[]>();
    useEffect(() => {
        const Fecha = props.Fecha.replace('-', '').replace('-', '');
        getPremiosMayores(Fecha).then(pr => {
            setpremios(pr);
        })
    }, [props.Fecha])

    return (
        <div style={{ width: '700px' }}>
            <table className='tabla'>
                <thead>
                    <tr className="textFont">
                        <th>juego</th>
                        <th>sorteo</th>
                        <th>fecha</th>
                        <th>agencia</th>
                        <th>localidad</th>
                        <th>importe</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        premios && premios.map((p, i) => {
                            return (<tr key={`talbePremiosMayores${i}`}>
                                <td><div className="celda dataFont">{p.Nombre_Juego}</div></td>
                                <td><div className="celda dataFont"><Numbers value={p.Nro_Sorteo} intenger /></div></td>
                                <td><div className="celda dataFont">{p.Fecha_Sorteo}</div></td>
                                <td><div className="celda dataFont"><Numbers value={p.Agencia} intenger /></div></td>
                                <td><div className="celda dataFont">{p.Localidad} ({p.CP})</div></td>
                                <td>
                                    <div className="celda dataFont">
                                        <span className="dataSymbol">$</span>
                                        <Numbers value={p.Importe} intenger total />
                                    </div>
                                </td>
                            </tr>);
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}