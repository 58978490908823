import { useContext } from "react";
import { GlobalStatesContext } from "Providers/context";
import Daily from "components/Dashboard/TragCon/Daily";
import MonthlyComparisons from "components/Dashboard/TragCon/MonthlyComparisons";
import MonthyComparasonsForYears from "components/Dashboard/TragCon/MonthyComparasonsForYears";
import AnnualAccumulated from "components/Dashboard/TragCon/AnnualAccumulated";
import GraphMonthy from "components/Dashboard/TragCon/GraphMonthy";
import GraphAnnual from "components/Dashboard/TragCon/GraphAnnual";

export default function TragIAFAS() {
  const { state } = useContext(GlobalStatesContext);
  return (
    <>
      <div className="containerContent">
        <div className="containerLeft reduced">
          <div className="tituloTablero">
            <div className="iconLarge"></div>Tragamonedas Concesionadas
          </div>

          <Daily date={state.date} />
        </div>
        <div className="containerRight expanded">
          <MonthlyComparisons date={state.date} />
          <MonthyComparasonsForYears date={state.date} />
          <AnnualAccumulated date={state.date} />
          <div className="containerCardGraphRight">
            <GraphMonthy date={state.date}/>
            <GraphAnnual date={state.date}/>
          </div>
        </div>
      </div>
    </>
  );
}
