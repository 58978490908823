import { useContext, useEffect, useState } from "react";
import { GlobalStatesContext } from "Providers/context";
import { menuOnOff, subMenuOnOff, MenuVerify, ExitApp } from "Functions/auth";
import { Comercial } from "Functions/tableroApi";
import { Link } from "react-router-dom";
import About from "components/About/About";
import Sorteo from "components/Sorteo/Sorteo";
import { onlyDate } from "Functions/functions";
import Spinner from "components/Spinner/Spinner";

export const Menu = (): JSX.Element => {
  const { state, setContext } = useContext(GlobalStatesContext);
  const [seeSorteo, setseeSorteo] = useState(false);
  const [cerradoUser, setcerradoUser] = useState(false);

  useEffect(() => {
    if (state.profiles !== undefined) {
      MenuVerify(state.profiles);
    }
  }, [state.profiles]);

  useEffect(() => {
    const interval = setInterval(() => {
      const today = new Date();
      const now = today.toLocaleString();

      Comercial.getSorteos(Number(onlyDate(today))).then((data) => {
        // console.log('seeSorteo: ', seeSorteo);
        // console.log('cerradoUser: ',cerradoUser);
        if (data.length === 0 && seeSorteo) {
          salirModal();
          setseeSorteo(false);
        } else {
          if (!cerradoUser) {
            data.forEach((sorteo) => {
              const horasorteo = now.slice(11, 16);

              if (horasorteo === sorteo.Hora) {
                verSorteo();
              }
            });
          }
        }
      });
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  function updateDate(data: React.FormEvent<HTMLInputElement>) {
    if (data.currentTarget.value !== "") {
      setContext({
        ...state,
        date: data.currentTarget.value,
      });
    }
  }

  const salirModal = () => {
    setContext({
      ...state,
      modal: {
        ...state.modal,
        show: false,
      },
    });
  };

  const salirModalSorteo = () => {
    setContext({
      ...state,
      modal: {
        ...state.modal,
        show: false,
      },
    });
    setcerradoUser(true);
    setseeSorteo(false);
  };

  const CloseApp = () => {
    setContext({
      ...state,
      modal: {
        ...state.modal,
        body: <p>¿Está Seguro que desea salir del Tablero?</p>,
        title: "Tablero de comandos",
        subtitle: "Salir",
        show: true,
        button: true,
        closeModal: ExitApp,
      },
    });
  };

  const SeeAbout = () => {
    setContext({
      ...state,
      modal: {
        ...state.modal,
        body: <About />,
        title: "Tablero de comandos",
        subtitle: "",
        show: true,
        button: true,
        closeModal: salirModal,
      },
    });
  };

  const verSorteo = () => {
    setcerradoUser(false);
    setseeSorteo(false);
    setContext({
      ...state,
      modal: {
        ...state.modal,
        body: <Sorteo />,
        title: "",
        subtitle: "Sorteo en vivo",
        show: true,
        button: true,
        closeModal: salirModalSorteo,
      },
    });
  };

  function toggleFullScreen() {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }

  return (
    <nav className="menuContainer">
      <ul className="menu XL" id="navMenu">
        <li className="logo" id="btMenuID" onClick={menuOnOff}>
          <a href="#">
            <span className="btIcon iconMenuShow"></span>
          </a>
        </li>
        <li className="bt menu_tablero hideTotal">
          <>
            <Link to={"/dashboard"}>
              <span className="btIcon iconMenuTablero"></span>
              <span className="btText btCenter">tablero</span>
              <span className="btIcon btVacio"></span>
            </Link>
          </>
        </li>
        <li
          className="bt btParentSubMenu menu_utilidades hideTotal"
          id="btUtilidades"
          onClick={() => subMenuOnOff("UtilidadesID")}
        >
          <a>
            <span className="btIcon iconMenuUtilidades"></span>
            <span className="btText btCenter">utilidades</span>
            <span
              className="btIcon iconArrowLeft"
              id="arrowUtilidadesID"
            ></span>
          </a>
          <ul className="subMenu subMenuOff" id="subMenuUtilidadesID">
            <li className="btSubMenu hideOpacidad hideTotal">
              <>
                <Link to={"/dashboard/utilidades/comercial"}>
                  <span className="btIcon iconSubMenuComercial"></span>
                  <span className="btTextSubMenu btLeft">comercial</span>
                </Link>
              </>
            </li>
            <li className="btSubMenu hideOpacidad hideTotal">
              <>
                <Link to={"/dashboard/utilidades/casinos"}>
                  <span className="btIcon iconSubMenuCasinos"></span>
                  <span className="btTextSubMenu btLeft">casinos</span>
                </Link>
              </>
            </li>
            <li className="btSubMenu hideOpacidad hideTotal">
              <>
                <Link to={"/dashboard/utilidades/tragiafas"}>
                  <span className="btIcon iconSubMenuTragamonedas"></span>
                  <span className="btTextSubMenu btLeft">tragamonedas</span>
                </Link>
              </>
            </li>
            <li className="btSubMenu hideOpacidad hideTotal">
              <>
                <Link to={"/dashboard/utilidades/tragcon"}>
                  <span className="btIcon iconSubMenuTragConces"></span>
                  <span className="btTextSubMenu btLeft">
                    trag. concesionadas
                  </span>
                </Link>
              </>
            </li>
             <li className="btSubMenu hideOpacidad hideTotal">
              <>
                <Link to={"/dashboard/maps"}>
                  <span className="btIcon iconSubMenuDensAgencias"></span>
                  <span className="btTextSubMenu btLeft">
                    mapa casinos y salas
                  </span>
                </Link>
              </>
            </li>
            <li className="btSubMenu hideOpacidad hideTotal">
              <>
                <Link to={"/dashboard/utilidades/online"}>
                  <span className="btIcon iconSubMenuOnLine"></span>
                  <span className="btTextSubMenu btLeft">juegos online</span>
                </Link>
              </>
            </li>
            <li className="btSubMenu hideOpacidad hideTotal">
              <>
                <Link to={"/dashboard/utilidades/comparativos"}>
                  <span className="btIcon iconSubMenuComparativo"></span>
                  <span className="btTextSubMenu btLeft">comparativos</span>
                </Link>
              </>
            </li>
            {
              <li className="btSubMenu hideOpacidad hideTotal hideSubmenu menu_exportar">
                <>
                  <Link to={"/dashboard/utilidades/exportar"}>
                    <span className="btIcon iconSubMenuExportar"></span>
                    <span className="btTextSubMenu btLeft">exportar</span>
                  </Link>
                </>
              </li>
            }
          </ul>
        </li>
        {/* 
        <li className="bt menu_contrataciones hideTotal">
          <>
            <Link to={"/dashboard/contrataciones"}>
              <span className="btIcon iconMenuContrataciones"></span>
              <span className="btText btCenter">contrataciones</span>
              <span className="btIcon btVacio"></span>
            </Link>
          </>
        </li>
       */}
        {/*
        <li className="bt menu_presupuesto hideTotal">
          <>
            <Link to={"/dashboard/presupuestos"}>
              <span className="btIcon iconMenuPresupuestos"></span>
              <span className="btText btCenter">presupuestos</span>
              <span className="btIcon btVacio"></span>
            </Link>
          </>
        </li>
      */}
     { /* -
        <li
          className="bt btParentSubMenu menu_presupuesto hideTotal"
          id="btPersupuesto"
          onClick={() => subMenuOnOff("PresupuestoID")}
        >
          <a>
            <span className="btIcon iconMenuPresupuestos"></span>
            <span className="btText btCenter">presupuesto</span>
            <span
              className="btIcon iconArrowLeft"
              id="arrowPresupuestoID"
            ></span>
          </a>
          <ul className="subMenu subMenuOff" id="subMenuPresupuestoID">
            <li className="btSubMenu hideOpacidad hideTotal menu_prespuestoVer hideSubmenu">
              <>
                <Link to={"/dashboard/presupuestos"}>
                  <span className="btIcon iconSubMenuPPotenciales"></span>
                  <span className="btTextSubMenu btLeft">
                    ver
                  </span>
                </Link>
              </>
            </li>
            <li className="btSubMenu hideOpacidad hideTotal menu_prespuestoCargar hideSubmenu">
              <>
                <Link to={"/dashboard/presupuestosupload"}>
                  <span className="btIcon iconSubMenuDensAgencias"></span>
                  <span className="btTextSubMenu btLeft">
                    cargar
                  </span>
                </Link>
              </>
            </li>
          </ul>
    </li>
    {/* -- */}
        <li
          className="bt btParentSubMenu menu_infocomercial hideTotal"
          id="btInfoComercial"
          onClick={() => subMenuOnOff("InfoComercialID")}
        >
          <a>
            <span className="btIcon iconMenuInfoComercial"></span>
            <span className="btText btCenter">info comercial</span>
            <span
              className="btIcon iconArrowLeft"
              id="arrowInfoComercialID"
            ></span>
          </a>
          <ul className="subMenu subMenuOff" id="subMenuInfoComercialID">
            <li className="btSubMenu hideOpacidad hideTotal menu_premiospotenciales hideSubmenu">
              <>
                <Link to={"/dashboard/infocomercial/premiospotenciales"}>
                  <span className="btIcon iconSubMenuPPotenciales"></span>
                  <span className="btTextSubMenu btLeft">
                    premios potenciales
                  </span>
                </Link>
              </>
            </li>
            <li className="btSubMenu hideOpacidad hideTotal menu_dencidaddeagencias hideSubmenu">
              <>
                <Link to={"/dashboard/infocomercial/densdeagencias"}>
                  <span className="btIcon iconSubMenuDensAgencias"></span>
                  <span className="btTextSubMenu btLeft">
                    dens. de agencias
                  </span>
                </Link>
              </>
            </li>
          </ul>
        </li>
        <li className="bt menu_play">
          <>
            <div onClick={verSorteo}>
              <span className="btIcon iconMenuPlay"></span>
              <span className="btText btCenter">sorteo</span>
              <span className="btIcon btVacio"></span>
            </div>
          </>
        </li>
        <li
          className="bt btParentSubMenu last"
          id="btUsuario"
          onClick={() => subMenuOnOff("UsuarioID")}
        >
          <a>
            <span className="btIcon iconMenuUsuario"></span>
            <span className="btText btCenter" title={`Usuario conectado: ${state.user}`}>usuario</span>
            <span className="btIcon iconArrowLeft" id="arrowUsuarioID"></span>
          </a>
          <ul className="subMenu subMenuOff" id="subMenuUsuarioID">

            <li className="btSubMenu hideOpacidad hideTotal menu_configuracion hideSubmenu">
              <>
                <Link to={"/dashboard/user/setting"}>
                  <span className="btIcon iconSubMenuConfig"></span>
                  <span className="btTextSubMenu btLeft">configuración</span>
                </Link>
              </>
            </li>

            <li className="btSubMenu hideOpacidad hideTotal">
              <>
                <Link onClick={toggleFullScreen} to={"#"}>
                  <span className="btIcon iconFullScreen"></span>
                  <span className="btTextSubMenu btLeft">full screen</span>
                </Link>
              </>
            </li>

            <li className="btSubMenu hideOpacidad hideTotal">
              <a href="#" onClick={SeeAbout}>
                <span className="btIcon iconSubMenuAbout"></span>
                <span className="btTextSubMenu btLeft">acerca de</span>
              </a>
            </li>
            <li className="btSubMenu hideOpacidad hideTotal">
              <a href="#" onClick={CloseApp}>
                <span className="btIcon iconSubMenuSalir"></span>
                <span className="btTextSubMenu btLeft">cerrar sesión</span>
              </a>
            </li>
          </ul>
        </li>
        <li className="btSubMenu">
          <Spinner />
        </li>
      </ul>
      <div className="menuFecha">
        fecha:
        <span className="dataFecha">
          <input
            className="dataFecha"
            type="date"
            onChange={updateDate}
            value={state.date}
          />
        </span>
      </div>

    </nav>
  );
};
