import { useEffect, useState } from "react";
import { OnLine } from "Functions/tableroApi";
import { dateCompProps, OnLine as OnLineModel } from "Models/models";
import { ParseYearMonthDay } from "Functions/functions";
import Numbers from "components/Numbers/Numbers";

export default function IO(props: dateCompProps) {
    const [dataRetiros, setdataRetiros] = useState<OnLineModel.IO>();
    const [dataRetiros2, setdataRetiros2] = useState<OnLineModel.retiros>();
    const [dataCargas, setdataCargas] = useState<OnLineModel.cargas>();

    useEffect(() => {
        const DateSplit = ParseYearMonthDay(props.date);
        OnLine.getdetallesIO(
            Number(DateSplit?.day),
            Number(DateSplit?.month),
            Number(DateSplit?.year)
        ).then((car) => {
            setdataRetiros(car[0]);
        });
        OnLine.getRetiros(
            Number(DateSplit?.day),
            Number(DateSplit?.month),
            Number(DateSplit?.year)
        ).then((ret) => {
            setdataRetiros2(ret);
        });
        OnLine.getCargas(
            Number(DateSplit?.day),
            Number(DateSplit?.month),
            Number(DateSplit?.year)
        ).then((car) => {
            setdataCargas(car);
        });
        // react-hooks/exhaustive-deps
    }, [props.date]);
    return (
        <>
            <div className="tablaContainerChica">
                <div className="cardTitulo">
                    <div className="icon "></div>Detalles Bplay [
                    {ParseYearMonthDay(props.date)?.day}/
                    {ParseYearMonthDay(props.date)?.month}/
                    {ParseYearMonthDay(props.date)?.year}]
                </div>
                <table className="tabla" key={`tablaRetiros`}>
                    <thead className="textFont">
                        <tr>
                            <th>BPlay</th>
                            <th>cantidad</th>
                            <th>total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>
                                <div className="celda textFont">Depositos</div>
                            </th>
                            <td>
                                <div className="celda dataFont smallTotal">

                                    {dataRetiros?.ingresosc !== undefined && (
                                        <Numbers value={dataRetiros?.ingresosc} intenger />
                                    )}
                                </div>
                            </td>
                            <td>
                                <div className="celda dataFont smallTotal">
                                    <span className="dataSymbol">$</span>
                                    {dataRetiros?.ingresosts !== undefined && (
                                        <Numbers value={dataRetiros?.ingresosts} />
                                    )}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <div className="celda textFont">Retiros</div>
                            </th>
                            <td>
                                <div className="celda dataFont smallTotal">

                                    {dataRetiros?.ingresosc !== undefined && (
                                        <Numbers value={dataRetiros?.egresosc} intenger />
                                    )}
                                </div>
                            </td>
                            <td>
                                <div className="celda dataFont smallTotal">
                                    <span className="dataSymbol">$</span>
                                    {dataRetiros?.ingresosts !== undefined && (
                                        <Numbers value={dataRetiros?.egresosts} />
                                    )}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <thead className="textFont">
                        <tr>
                            <th>Bgamming</th>
                            <th>Agencias</th>
                            <th>Mi Pago</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="celda textFont">Cargas</td>
                            <td>
                                {dataCargas?.totalAgencias && <div className="celda dataFont smallTotal">
                                    <span className="dataSymbol">$</span>

                                    <Numbers value={dataCargas?.totalAgencias} />

                                </div>}
                            </td>
                            <td>
                                {dataCargas?.totalMiPago && <div className="celda dataFont smallTotal">
                                    <span className="dataSymbol">$</span>

                                    <Numbers value={dataCargas?.totalMiPago} />

                                </div>}
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td className="celda textFont">Retiros</td>
                            <td colSpan={2}>
                                {dataRetiros2?.total && <div className="celda dataFont smallTotal">
                                    <span className="dataSymbol">$</span>

                                    <Numbers value={dataRetiros2?.total} />

                                </div>}
                            </td>
                        </tr>
                    </tbody>

                </table>
            </div>
        </>
    );
}
