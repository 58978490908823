import { useContext } from "react";
import { GlobalStatesContext } from "Providers/context";
import Daily from "components/Dashboard/Home/Daily";
import Accumulated from "components/Dashboard/Home/Accumulated";
import MonthlyProgressionGraph from "components/Dashboard/Home/MonthlyProgressionGraph";
import Contributions from "components/Dashboard/Home/Contributions";

export default function Home() {
  const { state } = useContext(GlobalStatesContext);

  

  return (
    <>
      <div className="containerLeft">
        <Daily date={state.date} />
        <Accumulated date={state.date} />
        <MonthlyProgressionGraph date={state.date} />
      </div>

      <div className="containerRight">
        <div className="graphContainer">
          <Contributions date={state.date} />
        </div>
      </div>
    </>
  );
}
