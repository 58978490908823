import { useEffect, useState, useContext } from "react";
import { DatosAgencia } from "Functions/tableroApi";
import { Agencia } from "Models/models";
import MailTo from "components/MailTo/MailTo";
import { GlobalStatesContext } from "Providers/context";

interface Agencyprops {
  Agencia: number;
}

export default function Agency(props: Agencyprops) {
  const [agencias, setagencias] = useState<Agencia>();
  const { state, setContext } = useContext(GlobalStatesContext);
  useEffect(() => {
    if (props.Agencia !== undefined) {
      setContext({ ...state, spinner: { loading: true } });
      DatosAgencia(props.Agencia).then((datosAgencia) => {
        setagencias(datosAgencia[0]);
      }).finally(() => {
        setContext({ ...state, spinner: { loading: false } });
      })
    }

  }, [props.Agencia]);

  return (
    <>
      <div className="cardTitulo">
        <div className="icon "></div>Datos de la Agencia</div>
      <div className="btText plusRenglon">Codigo Agencia</div>
      <span className="dataFont plusRenglon">{agencias?.id}</span><br></br>
      <div className="btText plusRenglon">Denominación</div>
      <span className="dataFont plusRenglon">{agencias?.nombre[0]}</span><br></br>
      <div className="btText plusRenglon">Localidad</div>
      <span className="dataFont plusRenglon">{agencias?.localidad}</span><br></br>
      <div className="btText plusRenglon">Dirección{" "}</div>
      {agencias?.numero !== null && <span className="dataFont plusRenglon">{agencias?.calle[0]} {agencias?.numero[0]}</span>}
      {agencias?.numero === null && <span className="dataFont plusRenglon">{agencias?.calle[0]} S/N</span>}<br></br>
      <div className="btText plusRenglon">Teléfono</div>
      <span className="dataFont plusRenglon">{agencias?.telefonos[0]}</span>
      <br></br>
      <div className="btText plusRenglon">Cantidad TG</div>
      <span className="dataFont plusRenglon">{agencias?.cant_maquinas_ticket_game_on}</span>
      
      <div className="cardTitulo" id="datosTitular">
        <div className="icon "></div>Datos del titular</div>
      <div className="btText plusRenglon textAlignLeft">Apellido y Nombre{" "}</div>
      <span className="dataFont plusRenglon">{agencias?.apellido}, {agencias?.nombre[1]}</span><br></br>
      <div className="btText plusRenglon textAlignLeft">DNI</div>
      <span className="dataFont plusRenglon">{agencias?.numero_documento}</span><br></br>
      <div className="btText plusRenglon textAlignLeft">e-mail{" "}</div>
      <span className="dataFont plusRenglon">
        {agencias?.email !== undefined && (
          <MailTo label={agencias?.email} mailto={agencias?.email} />
        )}
      </span>
    </>
  );
}
