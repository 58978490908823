import { useContext } from "react";
import { GlobalStatesContext } from "Providers/context";
import AgenciasMasTicketVendidos from "components/Dashboard/PotentialPrizes/AgenciasMasTicketVendidos";
import AgenciasMayorMontoApuestas from "components/Dashboard/PotentialPrizes/AgenciasMayorMontoApuestas";
import AgenciasMayorRiesgoPotencial from "components/Dashboard/PotentialPrizes/AgenciasMayorRiesgoPotencial";
import PotencialesMasAltos from "components/Dashboard/PotentialPrizes/PotencialesMasAltos";
import PotencialesPagados from "components/Dashboard/PotentialPrizes/PotencialesPagados";

export default function PotentialPrizes() {
  const { state } = useContext(GlobalStatesContext);

  return (
    <>
      <div className="containerLeft reduced">
        <div className="tituloTablero">
          <div className="iconLarge"></div>Premios Potenciales
        </div>
        <div className="divsHorizontales">
          <div className="card cardMedium" id="potentialFirst">
            <AgenciasMasTicketVendidos date={state.date} />
          </div>
          <div className="card cardMedium">
            <AgenciasMayorRiesgoPotencial date={state.date} />
          </div>
          <div className="card cardMedium">
            <PotencialesMasAltos date={state.date} />
          </div>
        </div>
        <div className="divsHorizontales">
          <div className="card cardMedium">
            <AgenciasMayorMontoApuestas date={state.date} />
          </div>
          <div className="card cardMedium">
            <PotencialesPagados date={state.date} />
          </div>
        </div>
      </div>
    </>
  );
}
