import React, { useEffect, useContext, useState } from 'react';
import { tragamonedasDetailsCierre } from "Models/models";
import { TragIAFAS } from "Functions/tableroApi";
import { GlobalStatesContext } from "Providers/context";
import Numbers from 'components/Numbers/Numbers';

type Props = {
    idSala: number;
}

const SalaInfo = (props: Props): JSX.Element => {
    const { state, setContext } = useContext(GlobalStatesContext);
    const [salaInfo, setsalaInfo] = useState<tragamonedasDetailsCierre.SalasInfo>()
    useEffect(() => {
        TragIAFAS.getInfoSala(Number(props.idSala), state.date).then(sala => {
            setsalaInfo(sala);
        }).catch(err => {
            setContext({
                ...state, toast: {
                    ...state.toast,
                    show: true,
                    text: 'Hubo un error a consultar la información de la Sala'
                }
            });
            console.error(err);
        })
    
    }, [props.idSala])

    return (
        <div style={{ display: 'flex' }}>
            <div>
                <div className="cardTitulo">
                    <div className="icon "></div>Sala {salaInfo?.Nombre}</div>
                <div className="btText plusRenglon">empresa</div>
                <span className='dataFont plusRenglon'>{salaInfo?.Empresa}</span>
                <br />
                <div className="btText plusRenglon">empresa online</div>
                <span className='dataFont plusRenglon'>{salaInfo?.EmpresaOnLine}</span>
                <br />
                <div className="btText plusRenglon">casino</div>
                <span className='dataFont plusRenglon'>{salaInfo?.NombreCasino}</span>
                <br />
                <div className="btText plusRenglon">localidad</div>
                <span className='dataFont plusRenglon'>{salaInfo?.Localidad}</span>
                <br />
                <div className="btText plusRenglon">dirección</div>
                <span className='dataFont plusRenglon'>{salaInfo?.Direccion}</span>
                <br />
                <div className="btText plusRenglon">teléfono</div>
                <span className='dataFont plusRenglon'>{salaInfo?.Telefono}</span>
                <br />
                <div className="btText plusRenglon">observaciones</div>
                <span className='dataFont plusRenglon'>{salaInfo?.Observaciones}</span>
                <br />
                <div className="btText plusRenglon">concesionada</div>
                <span className='dataFont plusRenglon'>{salaInfo?.FConcesionada}</span>
                <br />
            </div>
            <div>
                <div className="cardTitulo">
                    <div className="icon "></div>Cantidad de máquinas</div>
                <div className="btText plusRenglon">tragamonedas</div>
                <span className='dataFont plusRenglon'>{salaInfo?.Slot !== undefined && <Numbers value={salaInfo?.Slot} intenger={true}/>}</span>
                <br />
                <div className="btText plusRenglon">ruletas</div>
                <span className='dataFont plusRenglon'>{salaInfo?.Ruleta !== undefined && <Numbers value={salaInfo?.Ruleta} intenger={true}/>}</span>
                <br />
            </div>
        </div>

    )
}

export default SalaInfo