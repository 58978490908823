import { useState, useEffect, useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { userAuth } from "Functions/tableroApi";
import { GlobalStatesContext } from "Providers/context";
import { decodeToken } from "react-jwt";
import { checkRoute } from "Functions/auth";

interface jwtData {
  exp: number;
  data: string;
}
interface jwtDataUser {
  user: string;
  sistema: string;
  perfiles: unknown;
}

export const Auth = () => {
  const { state, setContext } = useContext(GlobalStatesContext);
  const location = useLocation();
  const [login, setLogin] = useState(true);
  const [checked, setchecked] = useState(true);

  useEffect(() => {
    if (state.token == null || state.token == '') {
      setLogin(false);
      window.location.replace("/login");
    } else {
      setLogin(true);

      const jwtData = decodeToken(state.token);

      if (typeof jwtData == "object") {
        const data = jwtData as jwtData;
        const jsondata = JSON.parse(data.data) as jwtDataUser;
        // setContext({ ...state, user: jsondata.user });
        userAuth(jsondata.user)
          .then((data) => {
            if (jsondata.user !== '') {
              setContext({ ...state, user: jsondata.user, profiles: data });
            }
            
          })
          .catch(() => {
            console.error("No esta corriendo la API");
            // window.location.replace("/login");
          });
      }
    }

  }, [state.token]);

  useEffect(() => {
    if (state.profiles !== undefined) {
      const checkedRoute = checkRoute(location.pathname, state.profiles);
      setchecked(checkedRoute);
      // console.log(checked);
    }
  }, [location.pathname, state.profiles]);

  return (
    <>
      {!login && <Navigate to="/login" />}
      {!checked && <Navigate to="/Error403" />}
    </>
  );
};
