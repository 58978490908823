import { useEffect, useContext, lazy } from "react";
// import Map from "components/Dashboard/AgencyDensity/Map";
import { GlobalStatesContext } from "Providers/context";

import MAP from "./casinosysalas.json";

const Map = lazy(() => import('./Map'));

export default function AgencyDensity() {
  const { state } = useContext(GlobalStatesContext);

  useEffect(() => {


  }, [state.date]);

  /*
  const color = (kind: number): CSSProperties => {
    let ret =  { color: 'black' };
    if (kind === 1) {
      ret = { color: 'red' };
    }
    return ret;
  }
*/
  return (
    <>
      <div className="containerLeft reduced">
        <div className="tituloTablero">
          <div className="iconLarge"></div>Mapa de Casinos y Salas
        </div>
        <div className="" >
          <div className="card cardVertical" id="tablaScrollLeft">
            <table className="tabla" >
              <thead className="textFont">
                <tr>
                  <th>Idcasino</th>
                  <th>idSala</th>
                  <th>casino / sala</th>
                  <th>dirección</th>
                </tr>
              </thead>
              <tbody>
                {MAP.features.map((r, i) => {
                  return (<tr key={i} >
                    <td>
                      {r.properties.kind === '1' && <p>{r.properties.casinoId}</p>}
                    </td>
                    <td>
                      {r.properties.salasId}
                    </td>
                    <td>
                      {r.properties.name}
                    </td>
                    <td>
                      {r.properties.description}
                    </td>
                  </tr>)
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="containerRight expandedMap map">
        <Map />
      </div>
    </>
  );
}
