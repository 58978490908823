/* eslint-disable array-callback-return */
import { useEffect, useState, useContext } from "react";
import { dateCompProps, Presupuesto } from "Models/models";
import { prespuesto } from "Functions/tableroApi";
import { ParseYearMonthDay } from "Functions/functions";
import Numbers from "components/Numbers/Numbers";
import { GlobalStatesContext } from "Providers/context";
import ResourcesDetails from "components/Dashboard/Budget/Panel/Resources/ResourcesDetails";

export default function Resources(props: dateCompProps) {
  const { state, setContext } = useContext(GlobalStatesContext);
  const [datos, setdatos] = useState<Presupuesto.RecaudadoAcum[]>();
  const [referencia, setreferencia] = useState<number>(0);

  let TotalPresupuestado = 0;
  let TotalPercibido = 0;
  let TotalARecaudar = 0;
  let TotalPorcentaje = 0;

  useEffect(() => {
    const mes = Number(props.date.toString().substring(4, 6));
    if (mes !== undefined) {
      setreferencia((1.0 / 12.0) * mes);
    }
  }, [props.date]);

  useEffect(() => {
    prespuesto
      .getRecaudadoAcum(Number(props.date.replace("-", "").replace("-", "")))
      .then((rec) => {
        setdatos(rec);
      });
  }, [props.date]);

  const seeDetails = (concepto: string, nombre: string) => {
    setContext({
      ...state,
      modal: {
        ...state.modal,
        body: (
          <ResourcesDetails
            concepto={concepto}
            date={props.date}
          ></ResourcesDetails>
        ),
        show: true,
        button: false,
        subtitle: nombre,
      },
    });
  };

  return (
    <>
      <div className="tablaContainerBudgetResources">
        <div className="cardTitulo">
          TABLA DE RECURSOS AL [
          {ParseYearMonthDay(props.date)?.day}/
          {ParseYearMonthDay(props.date)?.month}/
          {ParseYearMonthDay(props.date)?.year}]
        </div>
        <table className="tabla">
          <thead>
            <tr className="cardTotales">
              <th><div className="celda dataFont smallTotal">Total</div></th>
              <th>
                <span className="dataSymbol">$</span>
                <span className="Numbers"><Numbers value={TotalPresupuestado}></Numbers></span>
              </th>
              <th>
                <span className="dataSymbol">$</span>
                <span className="Numbers"><Numbers value={TotalPercibido}></Numbers></span>
              </th>
              <th>
                <span className="dataSymbol">$</span>
                <span className="Numbers"><Numbers value={TotalARecaudar}></Numbers></span>
              </th>
              <th>
                <span className="dataSymbol">$</span>
                <span className="Numbers">Dif.Ejec. vs Ref.</span>
              </th>
              <th>
                <span className="dataSymbol">$</span>
                <span className="Numbers">Dif.Ejec. vs Ref.$</span>
              </th>
              <th>
                <span className="dataSymbol">$</span>
                <span className="Numbers"><Numbers value={TotalPorcentaje}></Numbers></span>
              </th>
            </tr>
          </thead>
          <thead className="textFont">
            <tr>
              <th>Nombre</th>
              <th>Presupuestado</th>
              <th>Percibido</th>
              <th>A Recaudar</th>
              <th>Dif.Ejec. vs Ref.</th>
              <th>Dif.Ejec. vs Ref.$</th>
              <th>Porcentaje</th>
            </tr>
          </thead>
          <tbody>
            {datos?.map((item, i) => {
              const porc = (item.Recaudado / item.C_actual) * 100;
              const ref = referencia.toPrecision(1);
              const dif = (item.Recaudado / item.C_actual - Number(ref)) * 100;
              const dif1 = (dif * item.C_actual) / 100;
              // console.log(item.Recaudado,item.C_actual, ref );

              // Total
              TotalPresupuestado += item.C_actual;
              TotalPercibido += item.Recaudado;
              TotalARecaudar += item.ARecaudar;
              TotalPorcentaje =
                100 - (TotalARecaudar * 100) / TotalPresupuestado;

              if (item.C_actual > 0) {
                return (
                  <tr key={`collected${i}`}>
                    <td className="celda textFont wButton">
                      {item.concepto} {item.nombre}{" "}
                      <span><button className="buttonDetalle" onClick={() => seeDetails(item.concepto, item.nombre)}>detalle</button></span>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <span className="Numbers"><Numbers value={item.C_actual} /></span>
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <span className="Numbers"><Numbers value={item.Recaudado} /></span>
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <span className="Numbers"><Numbers value={item.ARecaudar} /></span>
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <span className="Numbers"><Numbers value={dif} /></span>
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <span className="Numbers"><Numbers value={dif1} /></span>
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <span className="Numbers"><Numbers value={porc} /></span>
                      </div>
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
