import { useEffect, useState, useContext } from "react";
import { TragCon } from "Functions/tableroApi";
import { dateCompProps, DailyConcesionadas } from "Models/models";
import { ParseYearMonthDay } from "Functions/functions";
import Numbers from "components/Numbers/Numbers";
import TrafficLight from "components/TrafficLight/TrafficLight";
import { GlobalStatesContext } from "Providers/context";
import Details from "components/Dashboard/TragCon/Details/Details";
import SalaInfo from "components/Dashboard/SalaInfo/SalaInfo";


export default function Daily(props: dateCompProps) {
  const { state, setContext } = useContext(GlobalStatesContext);
  const [data, setdata] = useState<DailyConcesionadas[]>();
  const [totales, settotales] = useState<DailyConcesionadas>({
    CanonAnual: 0,
    CanonDiario: 0,
    CanonMensual: 0,
    Confirmado: null,
    FichajeDiario: 0,
    IDSala: 1,
    MontoAsegurado: 0,
    NombreEmpresa: "",
    NombreSala: "",
    UtilidadDiaria: 0,
    UtilidadMensual: 0
  });

  /*
  const MontoAsegurado = (Monto: number, Cannon: number): number => {
    let Respuesta: number = 0;
    if (Monto > Cannon) {
      Respuesta = (Cannon / Monto) * 100;
    }
    if (Cannon >= Monto) {
      Respuesta = 100;
    }
    return Respuesta;
  };*/

  const getIdCierre = async (sala: number | undefined) => {
    if (sala !== undefined) {
      const idCierre = (await TragCon.getIdCierreC(state.date, Number(sala))).IdCierre;
      setContext({
        ...state, modal: {
          ...state.modal, show: true,
          body: <Details idCierre={idCierre} />,
          title: `Detalle sala tragamonedas Concesionadas`,
          subtitle: ``,
          closeModal: () => setContext({ ...state, modal: { ...state.modal, show: false } })
        }
      })
    }
  }

  const getSalaInfo = async (sala: number | undefined) => {
    if (sala !== undefined) {
      const idSalas: number = sala;

      setContext({
        ...state, modal: {
          ...state.modal, show: true,
          body: <SalaInfo idSala={idSalas} />,
          title: `Sala Tragamonedas Concesionada`,
          subtitle: ``,
          closeModal: () => setContext({ ...state, modal: { ...state.modal, show: false } })
        }
      })
    }
  }

  useEffect(() => {
    setContext({ ...state, spinner: { loading: true } });
    const DateSplit = ParseYearMonthDay(props.date);
    TragCon.getTragConDiaria(
      Number(DateSplit?.day),
      Number(DateSplit?.month),
      Number(DateSplit?.year)
    )
      .then((data) => {
        // console.table(data);
        setdata(data);
        // Dailys
        let DataRec = 0;
        let DataUti = 0;
        let DataCan = 0;
        // accumulated
        let DataCanM = 0;
        let DataCanA = 0;
        let DataMon = 0;
        let Acum = 0;
        data.forEach((sub) => {
          if (sub.FichajeDiario !== null) DataRec = DataRec + sub.FichajeDiario;
          if (sub.UtilidadDiaria !== null)
            DataUti = DataUti + sub.UtilidadDiaria;
          if (sub.CanonDiario !== null) DataCan = DataCan + sub.CanonDiario;
          if (sub.UtilidadMensual) Acum = Acum + sub.UtilidadMensual;
          if (sub.CanonMensual !== null) DataCanM = DataCanM + sub.CanonMensual;
          if (sub.CanonAnual !== null) DataCanA = DataCanA + sub.CanonAnual;
          if (sub.MontoAsegurado !== null)
            DataMon = DataMon + sub.MontoAsegurado;
        });
        settotales({
          ...totales,
          FichajeDiario: DataRec,
          CanonDiario: DataCan,
          UtilidadDiaria: DataUti,
          CanonMensual: DataCanM,
          CanonAnual: DataCanA,
          MontoAsegurado: DataMon,
          UtilidadMensual: Acum
        });
      })
      .catch(() => {
        setContext({
          ...state,
          toast: {
            text: "Hubo un problema al conectar con el WS.",
            show: true,
          },
        });
        console.log("No esta corriendo la API");
      }).finally(() => {
        setContext({ ...state, spinner: { loading: false } });
      })

  }, [props.date]);
  return (
    <>
      <div className="card cardMedium">
        <div className="tablaContainer">
          <div className="cardTitulo">
            <div className="icon "></div>TOTALES DIARIOS [
            {ParseYearMonthDay(props.date)?.day}/
            {ParseYearMonthDay(props.date)?.month}/
            {ParseYearMonthDay(props.date)?.year}]
          </div>
          <table className="tabla" id="tabTragCon01">
            <thead>
              <tr className="cardTotales">
                <th colSpan={2}>
                  <div className="celda dataFont smallTotal">total</div>
                </th>
                <th>
                  <div className="celda dataFont smallTotal">
                    <span className="dataSymbol">$</span>
                    <Numbers value={totales.FichajeDiario} />
                  </div>
                </th>
                <th>
                  <div className="celda dataFont smallTotal">
                    <span className="dataSymbol">$</span>
                    <Numbers value={totales.UtilidadDiaria} />
                  </div>
                </th>
                {/* Aqui abajo va la utilidad bruta acumulada */}
                <th>
                  <div className="celda dataFont smallTotal">
                    <span className="dataSymbol">$</span>
                    {totales.UtilidadMensual && <Numbers value={totales.UtilidadMensual} />}
                    
                  </div>
                </th>
                {/* Aqui arriba va la utilidad bruta acumulada */}
                <th>
                  <div className="celda dataFont bigTotal">
                    <span className="dataSymbol">$</span>
                    <Numbers total={true} value={totales.CanonDiario} />
                  </div>
                </th>
                <th>
                  <div className="celda dataFont smallTotal porcentaje">
                    <Numbers
                      value={
                        (totales.CanonDiario / totales.FichajeDiario) * 100
                      }
                    />
                    <span>%</span>
                  </div>
                </th>
              </tr>
            </thead>
            <thead className="textFont">
              <tr>
                <th colSpan={2}>unidad de negocios</th>
                <th>recaudación</th>
                <th>utilidad</th>
                <th>ut. bruta acum.</th>
                <th>canon</th>
                <th>%</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((con, i) => {
                return (
                  <tr key={`ConcecionadasDaily${i}`}>
                    <td>
                      <div className="celda celdaKey">{con.NombreSala}
                        <div style={{ display: "flex", flexDirection: "row", }}>
                          {con.FichajeDiario > 0 && <span title='Detalle del cierre de jornada' onClick={() => getIdCierre(con.IDSala)} className="btIcon btIconDetailSala iconDetalleJor sinEspacios" />}
                          <span title='Detalle de sala' onClick={() => getSalaInfo(con.IDSala)} className="btIcon btIconDetailSala iconDetalleSala sinEspacios" />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="celda textFont">{con.NombreEmpresa}</div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={con.FichajeDiario} />
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={con.UtilidadDiaria} />
                      </div>
                    </td>
                    {/* Aqui abajo va la utilidad bruta acumulada */}
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        {con.UtilidadMensual && <Numbers value={con.UtilidadMensual} />}
                      </div>
                    </td>
                    {/* Aqui arriba va la utilidad bruta acumulada */}
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={con.CanonDiario} />
                      </div>
                    </td>
                    <td className="celda dataFont porcentaje">
                      <TrafficLight
                        value={(con.CanonDiario / con.FichajeDiario) * 100}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div >
      <div className="card cardMedium" id="tabTragCon02">
        <div className="tablaContainer">
          <div className="cardTitulo">
            <div className="icon "></div>TOTALES ACUMULADOS [
            {ParseYearMonthDay(props.date)?.day}/
            {ParseYearMonthDay(props.date)?.month}/
            {ParseYearMonthDay(props.date)?.year}]
          </div>
          <table className="tabla">
            <thead>
              <tr className="cardTotales">
                <th colSpan={2}>
                  <div className="celda dataFont smallTotal"></div>
                </th>
                <th>
                  <div className="celda dataFont smallTotal">
                    <span className="dataSymbol">$</span>{" "}
                    <Numbers value={totales.CanonMensual} />
                  </div>
                </th>
                <th>
                  <div className="celda dataFont smallTotal">
                    <span className="dataSymbol">$</span>{" "}
                    <Numbers value={totales.CanonAnual} />
                  </div>
                </th>
                <th>
                  <div className="celda dataFont bigTotal">
                    <span className="dataSymbol">$</span>{" "}
                    <Numbers total={true} value={totales.MontoAsegurado} />
                  </div>
                </th>
              </tr>
            </thead>
            <thead className="textFont">
              <tr>
                <th colSpan={2}>unidad de negocios</th>
                <th>Cannon Mensual</th>
                <th>Cannon Anual</th>
                <th>Monto Asegurado</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((con, i) => {
                return (
                  <tr key={`ConcecionadasDaily${i}`}>
                    <td>
                      <div className="celda textFont">{con.NombreSala}</div>
                    </td>
                    <td>
                      <div className="celda textFont">{con.NombreEmpresa}</div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={con.CanonMensual} />
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={con.CanonAnual} />
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={con.MontoAsegurado} />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
