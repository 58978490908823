/* eslint-disable array-callback-return */
import { useEffect, useState, useContext } from "react";
import { dateCompProps, Presupuesto } from "Models/models";
import { prespuesto } from "Functions/tableroApi";
import { ParseYearMonthDay } from "Functions/functions";
import Numbers from "components/Numbers/Numbers";
import { GlobalStatesContext } from "Providers/context";
import ExpenseDetails from "components/Dashboard/Budget/Panel/Expenses/ExpenseDetails";

export default function Expense(props: dateCompProps) {
  const { state, setContext } = useContext(GlobalStatesContext);
  const [datos, setdatos] = useState<Presupuesto.Gastos[]>();
  const [referencia, setreferencia] = useState<number>(0);
  const [referenciaPersonal, setreferenciaPersonal] = useState<number>(0);

  let TotalPresupuestado = 0;
  let TotalEjecutado = 0;
  let TotalCompromiso = 0;
  let TotalReservado = 0;
  let TotalDisponible = 0;
  let Ejecucion = 0;

  useEffect(() => {
    const mes = Number(props.date.toString().substring(4, 6));
    if (mes !== undefined) {
      setreferencia((1.0 / 12.0) * mes);
      setreferenciaPersonal((1.0 / 13.0) * mes);
    }
  }, [props.date]);

  useEffect(() => {
    prespuesto
      .getGastos(Number(props.date.replace("-", "").replace("-", "")))
      .then((rec) => {
        setdatos(rec);
        // console.table(rec);
      });
  }, [props.date]);

  const seeDetails = (concepto: string, nombre: string) => {
    setContext({
      ...state,
      modal: {
        ...state.modal,
        body: <ExpenseDetails concepto={concepto} date={props.date}></ExpenseDetails>,
        show: true,
        button: false,
        subtitle: nombre,
      },
    });
  };

  return (
    <>
      <div className="tablaContainerBudgetExpense">
        <div className="cardTitulo">
          TABLA GASTOS [
          {ParseYearMonthDay(props.date)?.day}/
          {ParseYearMonthDay(props.date)?.month}/
          {ParseYearMonthDay(props.date)?.year}]
        </div>
        <table className="tabla">
          <thead>
            <tr className="cardTotales">
              <th><div className="celda dataFont smallTotal">Total</div></th>
              <th>
                <span className="dataSymbol">$</span>
                <span className="Numbers"><Numbers value={TotalPresupuestado}></Numbers></span>
              </th>
              <th>
                <span className="dataSymbol">$</span>
                <span className="Numbers"><Numbers value={TotalEjecutado}></Numbers></span>
              </th>
              <th>
                <span className="dataSymbol">$</span>
                <span className="Numbers"><Numbers value={TotalCompromiso}></Numbers></span>
              </th>
              <th>
                <span className="dataSymbol">$</span>
                <span className="Numbers"><Numbers value={TotalReservado}></Numbers></span>
              </th>
              <th>
                <span className="dataSymbol">$</span>
                <span className="Numbers"><Numbers value={TotalDisponible}></Numbers></span>
              </th>
              <th>
                <span className="dataSymbol">$</span>
                <span className="Numbers">Dif.Ejec. vs Ref.</span>
              </th>
              <th>
                <span className="dataSymbol">$</span>
                <span className="Numbers">Dif.Ejec. vs Ref. $</span>
              </th>
              <th>
                <span className="dataSymbol">$</span>
                <span className="Numbers"><Numbers value={Ejecucion}></Numbers></span>
              </th>
            </tr>
          </thead>
          <thead className="textFont">
            <tr>
              <th>Nombre</th>
              <th>Presupuestado</th>
              <th>Ejecutado</th>
              <th>Compromiso</th>
              <th>Reservado</th>
              <th>Disponible</th>
              <th>Dif.Ejec. vs Ref.</th>
              <th>Dif.Ejec. vs Ref. $</th>
              <th>ejecución</th>
            </tr>
          </thead>
          <tbody>
            {datos?.map((item, i) => {
              const porc = (item.Devengado * 100) / item.CreditoActual;
              const refP = referenciaPersonal.toPrecision(1);
              let ejec = item.Devengado / item.CreditoActual - Number(refP);
              ejec = ejec * -1 * item.CreditoActual;

              const ref = referencia.toPrecision(1);
              const dif2 =
                (item.Devengado / item.CreditoActual - Number(ref)) * -100;

              // Totales
              TotalPresupuestado += item.CreditoActual;
              TotalEjecutado += item.Devengado;
              TotalCompromiso += item.Compromiso;
              TotalReservado += item.Reservado;
              TotalDisponible += item.Disponible;
              Ejecucion = (TotalEjecutado * 100) / TotalPresupuestado;
              return (
                <tr key={`collected${i}`}>
                  <td className="celda textFont wButton">
                    {item.inciso} {item.nombre}{" "}
                    <span><button className="buttonDetalle" onClick={() => seeDetails(item.inciso, item.nombre)}>detalle</button></span>
                  </td>
                  <td>
                    <div className="celda dataFont">
                      <span className="dataSymbol">$</span>
                      <span className="Numbers"><Numbers value={item.CreditoActual}></Numbers></span>
                    </div>
                  </td>
                  <td>
                    <div className="celda dataFont">
                      <span className="dataSymbol">$</span>
                      <span className="Numbers"><Numbers value={item.Devengado}></Numbers></span>
                    </div>
                  </td>
                  <td>
                    <div className="celda dataFont">
                      <span className="dataSymbol">$</span>
                      <span className="Numbers"><Numbers value={item.Compromiso}></Numbers></span>
                    </div>
                  </td>
                  <td>
                    <div className="celda dataFont">
                      <span className="dataSymbol">$</span>
                      <span className="Numbers"><Numbers value={item.Reservado}></Numbers></span>
                    </div>
                  </td>
                  <td>
                    <div className="celda dataFont">
                      <span className="dataSymbol">$</span>
                      <span className="Numbers"><Numbers value={item.Disponible}></Numbers>ss</span>
                    </div>
                  </td>
                  <td>
                    <div className="celda dataFont">
                      <span className="dataSymbol">$</span>
                      <span className="Numbers"><Numbers value={dif2}></Numbers></span>
                    </div>
                  </td>
                  <td>
                    <div className="celda dataFont">
                      <span className="dataSymbol">$</span>
                      <span className="Numbers"><Numbers value={ejec}></Numbers></span>
                    </div>
                  </td>
                  <td>
                    <div className="celda dataFont">
                      <span className="dataSymbol">$</span>
                      <span className="Numbers"><Numbers value={porc}></Numbers></span>
                    </div>
                  </td>
                </tr>
              );
            })}

          </tbody>
        </table>
      </div>
    </>
  );
}
