import React, { useContext,useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalStatesContext } from "Providers/context";

export function TCToast(){
    // const notify = () => toast("Wow so easy!");
    const {state,setContext} = useContext(GlobalStatesContext)
    useEffect(() => {
      if (state.toast.show) {
        toast(state.toast.text);
        setContext({...state,toast: {...state.toast,show: false}});
      }

    }, [state.toast.show])
    
    return (
      <div>
        <ToastContainer />
      </div>
    );
  }
