/* eslint-disable @typescript-eslint/no-explicit-any */
import api, { config } from "Functions/axios";
import {
  Security,
  PricipalData,
  getTotalUtilidadesConsecionadasType,
  getTragamonedasDiariaType,
  getCasinosDiariaType,
  AccumulatedDetailed,
  ContributionsData,
  banckAccounts,
  ParteDiario,
  dataUtilidades,
  dataUtilidadesComparativos,
  DailyConcesionadas,
  CantidadMaquinasData,
  dataUtilidadesCasinos,
  ComparativosUnidades,
  DensidadAgencias,
  DensidadPorHabitantes,
  Potenciales,
  Parciales,
  Agencia,
  flujoCaja,
  flujoCajaDetallado,
  Presupuesto,
  Extracto,
  ContributionsDataDetails,
  OnLine as OnLineModel,
  GitVersion,
  Sorteos,
  tragamonedasDetailsCierre,
  PremiosMayores,
} from "Models/models";
import { zfill } from "Functions/functions";
import { CONFIG } from "Functions/config";

export const login = (login: Security.LoginType) => {
  const promise = new Promise<Security.LoginWSRespose>((resolve, reject) => {
    api
      .post(`/login`, login)
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

export const userAuth = (Usuario: string) => {
  const promise = new Promise<Security.PerfilesResponse[]>(
    (resolve, reject) => {
      api
        .get(`/permisosUsuario?Usuario=${Usuario}`, config())
        .then((data) => {
          // console.log(data);
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
  return promise;
};

/**
 *
 * @param Usuario
 * @param Funcion
 * @param Perfil
 * @description Modifies the role of the user in a profile.
 * @returns
 */
export const modFunction = (
  Usuario: string,
  Funcion: number,
  Perfil: number,
  userMod: string
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promise = new Promise<any[]>((resolve, reject) => {
    api
      .get(
        `/modificarfuncion?Usuario=${Usuario}&Funcion=${Funcion}&Perfil=${Perfil}&UserMod=${userMod}`,
        config()
      )
      .then((data) => {
        // console.log(data);
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

export const usersAdd = () => {
  const promise = new Promise<string[]>((resolve, reject) => {
    api
      .get(`/users`, config())
      .then((data) => {
        const users: string[] = [];
        for (const item in data.data) {
          users.push(data.data[item]);
        }
        resolve(users);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

/**
 *
 * @param FechaD
 * @param FechaH
 * @description Updates data´s details of all Bussines Unit
 * @returns
 */
export const ActualizarDetalladaRango = (FechaD: string, FechaH: string) => {
  const promise = new Promise<PricipalData[]>((resolve, reject) => {
    api
      .get(
        `/ActualizarDetalladaRango?FechaD=${FechaD}&FechaH=${FechaH}`,
        config()
      )
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

export const ActualizarDatos = () => {
  const promise = new Promise<PricipalData[]>((resolve, reject) => {
    api
      .get(`/ActualizarDatos`, config())
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return Day´s info Total
 * @returns
 */
export const getPrincipal = (Dia: number, Mes: number, Anio: number) => {
  const promise = new Promise<PricipalData[]>((resolve, reject) => {
    api
      .get(`/DiariosTabla?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}`, config())
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

export const getTotalUtilidadesConsecionadas = (
  Dia: number,
  Mes: number,
  Anio: number
) => {
  const promise = new Promise<getTotalUtilidadesConsecionadasType[]>(
    (resolve, reject) => {
      api
        .get(
          `/Tragamonedas/Concesionadas/TotalUtilidadesConsecionadas?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}`,
          config()
        )
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
  return promise;
};

export const getTragamonedasIafasDiaria = (
  Dia: number,
  Mes: number,
  Anio: number,
  Detallado: number
) => {
  const promise = new Promise<getTragamonedasDiariaType[]>(
    (resolve, reject) => {
      api
        .get(
          `/Tragamonedas/Iafas/Diaria?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}&Detallado=${Detallado}`,
          config()
        )
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
  return promise;
};

export const getCasinoDiaria = (
  Dia: number,
  Mes: number,
  Anio: number,
  Detallado: number
) => {
  const promise = new Promise<getCasinosDiariaType>((resolve, reject) => {
    api
      .get(
        `/Casinos/Diaria?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}&Detallado=${Detallado}`,
        config()
      )
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

export const getDiariaDepartamentos = (
  Dia: number,
  Mes: number,
  Anio: number,
  Departamento: string,
  Localidad: number
) => {
  const promise = new Promise<dataUtilidades[]>((resolve, reject) => {
    api
      .get(
        `/comercial/DiariaDepartamentos?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}&Departamento=${Departamento}&Localidad=${Localidad}`,
        config()
      )
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return Day´s info Total
 * @returns
 */
export const getPrincipalAcumulados = (
  Dia: number,
  Mes: number,
  Anio: number
) => {
  const promise = new Promise<PricipalData[]>((resolve, reject) => {
    api
      .get(`/AcumuladosMensuales?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}`, config())
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return Day´s info Total detailed
 * @returns
 */
export const getPrincipalAcumuladosDetallados = (
  Dia: number,
  Mes: number,
  Anio: number
) => {
  const promise = new Promise<AccumulatedDetailed[]>((resolve, reject) => {
    api
      .get(
        `/AcumuladosMensualesDetallados?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}`,
        config()
      )
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

export const getAcumuladosMensualesTombola = (
  Dia: number,
  Mes: number,
  Anio: number
) => {
  const promise = new Promise<AccumulatedDetailed[]>((resolve, reject) => {
    api
      .get(
        `/AcumuladosMensualesTombola?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}`,
        config()
      )
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

export const DatosAgencia = (idAgencia: number) => {
  const promise = new Promise<Agencia[]>((resolve, reject) => {
    api
      .get(`/Comercial/DatosAgencia?idAgencia=${idAgencia}`, config())
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return
 * @returns
 */
export const getAportes = (Anio: number) => {
  const promise = new Promise<ContributionsData[]>((resolve, reject) => {
    api
      .get(`/calculoAportesAnual?Anio=${Anio}`, config())
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return
 * @returns
 */
export const getAportesDetallado = (Anio: number, Mes: number) => {
  const promise = new Promise<ContributionsDataDetails[]>((resolve, reject) => {
    api
      .get(`/calculoAportes?Anio=${Anio}&Mes=${Mes}`, config())
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return
 * @returns
 */
export const getEstadoCuentas = (Dia: number, Mes: number, Anio: number) => {
  const promise = new Promise<banckAccounts[]>((resolve, reject) => {
    api
      .get(`/estadoCuentas?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}`, config())
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

/**
 *
 * @param fecha
 * @param detallado
 * @desc Return
 * @returns
 */
export const getEstadoFlujoCaja = (fecha: number, detallado: number) => {
  const promise = new Promise<flujoCaja[]>((resolve, reject) => {
    api
      .get(`/estadoFlujoCaja?fecha=${fecha}&detallado=${detallado}`, config())
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

/**
 *
 * @param fecha
 * @param detallado
 * @desc Return
 * @returns
 */
export const getEstadoFlujoCajaDetallado = (
  fecha: number,
  detallado: number
) => {
  const promise = new Promise<flujoCajaDetallado[]>((resolve, reject) => {
    api
      .get(`/estadoFlujoCaja?fecha=${fecha}&detallado=${detallado}`, config())
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

/**
 *
 * @param fecha
 * @param detallado
 * @param grupo
 * @desc Return
 * @returns
 */
export const getParteDiario = (
  fecha: number,
  detallado: number,
  grupo: number
) => {
  const promise = new Promise<ParteDiario[]>((resolve, reject) => {
    api
      .get(
        `/parteDiario?fecha=${fecha}&detallado=${detallado}&grupo=${grupo}`,
        config()
      )
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return
 * @returns
 */
const getTragIAFASDiaria = (Dia: number, Mes: number, Anio: number) => {
  const promise = new Promise<dataUtilidades[]>((resolve, reject) => {
    api
      .get(
        `/Tragamonedas/iafas/diaria?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}`,
        config()
      )
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

/**
 * Tragamonedas IAFAS
 */

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return
 * @returns
 */
const getComparativoMensual = (Dia: number, Mes: number, Anio: number) => {
  const promise = new Promise<dataUtilidadesComparativos[]>(
    (resolve, reject) => {
      api
        .get(
          `/Tragamonedas/iafas/ComparativoMensual?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}`,
          config()
        )
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
  return promise;
};

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return
 * @returns
 */
const getComparativaMesAnterior = (
  Dia: number,
  Mes: number,
  Anio: number,
  Completo: number
) => {
  const promise = new Promise<dataUtilidadesComparativos[]>(
    (resolve, reject) => {
      api
        .get(
          `/Tragamonedas/iafas/ComparativaMesAnterior?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}&Completo=${Completo}`,
          config()
        )
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
  return promise;
};

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return
 * @returns
 */
const getAcumuladoAnual = (Dia: number, Mes: number, Anio: number) => {
  const promise = new Promise<dataUtilidadesComparativos[]>(
    (resolve, reject) => {
      api
        .get(
          `/Tragamonedas/iafas/AcumuladoAnual?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}`,
          config()
        )
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
  return promise;
};

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return
 * @returns
 */
const getCantidadMaquinas = () => {
  const promise = new Promise<CantidadMaquinasData>((resolve, reject) => {
    api
      .get(`/Tragamonedas/iafas/CantidadMaquinas`, config())
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

interface idCierre {
  IdCierre: number;
}
interface idSalas {
  idSalas: number;
}

const getIdCierre = (fecha: string, sala: string) => {
  const promise = new Promise<idCierre>((resolve, reject) => {
    api
      .get(
        `/Tragamonedas/iafas/getIdCierre?fecha=${fecha}&sala=${sala}`,
        config()
      )
      .then((data) => {
        resolve(data.data[0]);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

const getIdSala = (sala: string) => {
  const promise = new Promise<idSalas>((resolve, reject) => {
    api
      .get(`/Tragamonedas/iafas/getIdSala?sala=${sala}`, config())
      .then((data) => {
        resolve(data.data[0]);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

const getCierre = (idCierre: number) => {
  const promise =
    new Promise<tragamonedasDetailsCierre.TragamonedasIAFASCierreInfo>(
      (resolve, reject) => {
        api
          .get(`/Tragamonedas/iafas/getCierre?idCierre=${idCierre}`, config())
          .then((data) => {
            resolve(data.data[0]);
          })
          .catch((err) => {
            reject(err);
          });
      }
    );
  return promise;
};

const getDetalleCierre = (idCierre: number) => {
  const promise =
    new Promise<tragamonedasDetailsCierre.TragamonedasIAFASCierre>(
      (resolve, reject) => {
        api
          .get(
            `/Tragamonedas/iafas/getDetalleCierre?idCierre=${idCierre}`,
            config()
          )
          .then((data) => {
            resolve(data.data[0]);
          })
          .catch((err) => {
            reject(err);
          });
      }
    );
  return promise;
};

const getTurnosCierre = (idCierre: number) => {
  const promise = new Promise<
    tragamonedasDetailsCierre.TragamonedasIAFASCierreTurno[]
  >((resolve, reject) => {
    api
      .get(`/Tragamonedas/iafas/getTurnosCierre?idCierre=${idCierre}`, config())
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

const getTurnoDetalle = (idTurno: number) => {
  const promise = new Promise<tragamonedasDetailsCierre.DetalleTurno[]>(
    (resolve, reject) => {
      api
        .get(`/Tragamonedas/iafas/getTurnoDetalle?idTurno=${idTurno}`, config())
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
  return promise;
};

const getBilletero = (idCierre: number) => {
  const promise = new Promise<tragamonedasDetailsCierre.Billetero[]>(
    (resolve, reject) => {
      api
        .get(`/Tragamonedas/iafas/getBilletero?idCierre=${idCierre}`, config())
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
  return promise;
};

const getBilleteroR = (idCierre: number) => {
  const promise = new Promise<tragamonedasDetailsCierre.Billetero[]>(
    (resolve, reject) => {
      api
        .get(`/Tragamonedas/iafas/getBilleteroR?idCierre=${idCierre}`, config())
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
  return promise;
};

/**
 *
 * @param idSala
 * @param fecha
 * @description Return Sala´s info
 * @returns
 */
const getInfoSala = (idSala: number, fecha: string) => {
  const promise = new Promise<tragamonedasDetailsCierre.SalasInfo>(
    (resolve, reject) => {
      api
        .get(
          `/Tragamonedas/iafas/getInfoSala?idSala=${idSala}&fecha=${fecha}`,
          config()
        )
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
  return promise;
};

export const TragIAFAS = {
  getTragIAFASDiaria,
  getComparativoMensual,
  getComparativaMesAnterior,
  getAcumuladoAnual,
  getCantidadMaquinas,
  getIdCierre,
  getCierre,
  getDetalleCierre,
  getTurnosCierre,
  getTurnoDetalle,
  getBilletero,
  getBilleteroR,
  getInfoSala,
  getIdSala,
};

/**
 * Tragamonedas Concesionadas
 */

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return
 * @returns
 */
const getTragConDiaria = (Dia: number, Mes: number, Anio: number) => {
  const promise = new Promise<DailyConcesionadas[]>((resolve, reject) => {
    api
      .get(
        `/Tragamonedas/Concesionadas/diaria?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}`,
        config()
      )
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return
 * @returns
 */
const getTragConMensual = (Dia: number, Mes: number, Anio: number) => {
  const promise = new Promise<dataUtilidadesComparativos[]>(
    (resolve, reject) => {
      api
        .get(
          `/Tragamonedas/Concesionadas/ComparativoMensual?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}`,
          config()
        )
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
  return promise;
};

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return
 * @returns
 */
const getTragConComparativaMesAnterior = (
  Dia: number,
  Mes: number,
  Anio: number,
  Completo: number
) => {
  const promise = new Promise<dataUtilidadesComparativos[]>(
    (resolve, reject) => {
      api
        .get(
          `/Tragamonedas/Concesionadas/ComparativaMesAnterior?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}&Completo=${Completo}`,
          config()
        )
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
  return promise;
};

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return
 * @returns
 */
const getTragConAcumuladoAnual = (Dia: number, Mes: number, Anio: number) => {
  const promise = new Promise<dataUtilidadesComparativos[]>(
    (resolve, reject) => {
      api
        .get(
          `/Tragamonedas/Concesionadas/AcumuladoAnual?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}`,
          config()
        )
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
  return promise;
};

const getIdCierreC = (fecha: string, sala: number) => {
  const promise = new Promise<idCierre>((resolve, reject) => {
    api
      .get(
        `/Tragamonedas/concesionadas/getIdCierre?fecha=${fecha}&sala=${sala}`,
        config()
      )
      .then((data) => {
        resolve(data.data[0]);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

const getDetalleCierreC = (idCierre: number) => {
  const promise = new Promise<
    tragamonedasDetailsCierre.TragamonedasConCierreTurno[]
  >((resolve, reject) => {
    api
      .get(
        `/Tragamonedas/concesionadas/getDetalleCierre?idCierre=${idCierre}`,
        config()
      )
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

export const TragCon = {
  getTragConDiaria,
  getTragConMensual,
  getTragConComparativaMesAnterior,
  getTragConAcumuladoAnual,
  getIdCierreC,
  getDetalleCierreC,
};

/**
 * Tragamonedas Casinos
 */

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return
 * @returns
 */
const getCasinosDiaria = (Dia: number, Mes: number, Anio: number) => {
  const promise = new Promise<dataUtilidadesCasinos[]>((resolve, reject) => {
    api
      .get(`/Casinos/diaria?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}`, config())
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return
 * @returns
 */
const getCasinosMensual = (Dia: number, Mes: number, Anio: number) => {
  const promise = new Promise<dataUtilidadesComparativos[]>(
    (resolve, reject) => {
      api
        .get(
          `/Casinos/ComparativoMensual?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}`,
          config()
        )
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
  return promise;
};

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return
 * @returns
 */
const getCasinosComparativaMesAnterior = (
  Dia: number,
  Mes: number,
  Anio: number,
  Completo: number
) => {
  const promise = new Promise<dataUtilidadesComparativos[]>(
    (resolve, reject) => {
      api
        .get(
          `/Casinos/ComparativaMesAnterior?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}&Completo=${Completo}`,
          config()
        )
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
  return promise;
};

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return
 * @returns
 */
const getCasinosAcumuladoAnual = (Dia: number, Mes: number, Anio: number) => {
  const promise = new Promise<dataUtilidadesComparativos[]>(
    (resolve, reject) => {
      api
        .get(
          `/Casinos/AcumuladoAnual?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}`,
          config()
        )
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
  return promise;
};

export const Casinos = {
  getCasinosDiaria,
  getCasinosMensual,
  getCasinosComparativaMesAnterior,
  getCasinosAcumuladoAnual,
};

/**
 * Tragamonedas Comercial
 */

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return
 * @returns
 */
const getComercialDiaria = (Dia: number, Mes: number, Anio: number) => {
  const promise = new Promise<dataUtilidades[]>((resolve, reject) => {
    api
      .get(`/Comercial/diaria?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}`, config())
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return
 * @returns
 */
const getComercialMensual = (Dia: number, Mes: number, Anio: number) => {
  const promise = new Promise<dataUtilidadesComparativos[]>(
    (resolve, reject) => {
      api
        .get(
          `/Comercial/ComparativoMensual?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}`,
          config()
        )
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
  return promise;
};

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return
 * @returns
 */
const getComercialComparativaMesAnterior = (
  Dia: number,
  Mes: number,
  Anio: number,
  Completo: number
) => {
  const promise = new Promise<dataUtilidadesComparativos[]>(
    (resolve, reject) => {
      api
        .get(
          `/Comercial/ComparativaMesAnterior?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}&Completo=${Completo}`,
          config()
        )
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
  return promise;
};

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return
 * @returns
 */
const getComercialAcumuladoAnual = (Dia: number, Mes: number, Anio: number) => {
  const promise = new Promise<dataUtilidadesComparativos[]>(
    (resolve, reject) => {
      api
        .get(
          `/Comercial/AcumuladoAnual?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}`,
          config()
        )
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
  return promise;
};

/**
 *
 * @param fecha
 * @desc Return
 * @returns
 */
const getSorteos = (fecha: number) => {
  const promise = new Promise<Sorteos[]>((resolve, reject) => {
    api
      .get(`/Comercial/Sorteos?fecha=${fecha}`, config())
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

export const Comercial = {
  getComercialDiaria,
  getComercialMensual,
  getComercialComparativaMesAnterior,
  getComercialAcumuladoAnual,
  getSorteos,
  getDiariaDepartamentos
};

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return
 * @returns
 */
export const getcomparativojuegos = (
  Dia: number,
  Mes: number,
  Anio: number
) => {
  const promise = new Promise<ComparativosUnidades[]>((resolve, reject) => {
    api
      .get(`/comparativojuegos?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}`, config())
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return
 * @returns
 */
export const getcomparativojuegosUtilidadNetaOnline = (
  Dia: number,
  Mes: number,
  Anio: number
) => {
  const promise = new Promise<ComparativosUnidades[]>((resolve, reject) => {
    api
      .get(
        `/comparativojuegosUtilidadNetaOnline?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}`,
        config()
      )
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

export const getcdensidadAgenciaCiudad = () => {
  const promise = new Promise<DensidadAgencias[]>((resolve, reject) => {
    api
      .get(`/densidadAgenciaCiudad`, config())
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

export const getDensidadPorHabitantes = (
  Dia: number,
  Mes: number,
  Anio: number
) => {
  const promise = new Promise<DensidadPorHabitantes[]>((resolve, reject) => {
    api
      .get(
        `/DensidadPorHabitantes?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}`,
        config()
      )
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return
 * @returns
 */
const getPotencialesMasAltos = (Dia: number, Mes: number, Anio: number) => {
  const promise = new Promise<Potenciales.PotencialesMasAltos[]>(
    (resolve, reject) => {
      api
        .get(
          `/Potenciales/PotencialesMasAltos?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}`,
          config()
        )
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
  return promise;
};

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return
 * @returns
 */
const getPotencialesPagados = (Dia: number, Mes: number, Anio: number) => {
  const promise = new Promise<Potenciales.PotencialesPagados[]>(
    (resolve, reject) => {
      api
        .get(
          `/Potenciales/PotencialesPagados?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}`,
          config()
        )
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
  return promise;
};

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return
 * @returns
 */
const getAgenciasMasTicketVendidos = (
  Dia: number,
  Mes: number,
  Anio: number
) => {
  const promise = new Promise<Potenciales.AgenciasMasTicketVendidos[]>(
    (resolve, reject) => {
      api
        .get(
          `/Potenciales/AgenciasMasTicketVendidos?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}`,
          config()
        )
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
  return promise;
};

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return
 * @returns
 */
const getAgenciasMayorMontoApuestas = (
  Dia: number,
  Mes: number,
  Anio: number
) => {
  const promise = new Promise<Potenciales.AgenciasMayorMontoApuestas[]>(
    (resolve, reject) => {
      api
        .get(
          `/Potenciales/AgenciasMayorMontoApuestas?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}`,
          config()
        )
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
  return promise;
};

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return
 * @returns
 */
const getAgenciasMayorRiesgoPotencial = (
  Dia: number,
  Mes: number,
  Anio: number
) => {
  const promise = new Promise<Potenciales.AgenciasMayorRiesgoPotencial[]>(
    (resolve, reject) => {
      api
        .get(
          `/Potenciales/AgenciasMayorRiesgoPotencial?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}`,
          config()
        )
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
  return promise;
};

export const PotencialesAPI = {
  getPotencialesMasAltos,
  getPotencialesPagados,
  getAgenciasMasTicketVendidos,
  getAgenciasMayorMontoApuestas,
  getAgenciasMayorRiesgoPotencial,
};

/**
 *
 * @param FechaDesde
 * @param FechaHasta
 * @desc Return
 * @returns
 */
const getParcialesCasinos = (FechaDesde: string, FechaHasta: string) => {
  const promise = new Promise<Parciales.Casinos[]>((resolve, reject) => {
    api
      .get(
        `/Parciales/Casinos?FechaDesde=${FechaDesde}&FechaHasta=${FechaHasta}`,
        config()
      )
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

/**
 *
 * @param FechaDesde
 * @param FechaHasta
 * @desc Return
 * @returns
 */
const getParcialesComercial = (FechaDesde: string, FechaHasta: string) => {
  const promise = new Promise<Parciales.Comercial[]>((resolve, reject) => {
    api
      .get(
        `/Parciales/Comercial?FechaDesde=${FechaDesde}&FechaHasta=${FechaHasta}`,
        config()
      )
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

/**
 *
 * @param FechaDesde
 * @param FechaHasta
 * @desc Return
 * @returns
 */
const getParcialesTragamonedasC = (FechaDesde: string, FechaHasta: string) => {
  const promise = new Promise<Parciales.TragamonedasC[]>((resolve, reject) => {
    api
      .get(
        `/Parciales/TragamonedasC?FechaDesde=${FechaDesde}&FechaHasta=${FechaHasta}`,
        config()
      )
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

/**
 *
 * @param FechaDesde
 * @param FechaHasta
 * @desc Return
 * @returns
 */
const getParcialesTragamonedasI = (FechaDesde: string, FechaHasta: string) => {
  const promise = new Promise<Parciales.TragamonedasI[]>((resolve, reject) => {
    api
      .get(
        `/Parciales/TragamonedasI?FechaDesde=${FechaDesde}&FechaHasta=${FechaHasta}`,
        config()
      )
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

/**
 *
 * @param FechaDesde
 * @param FechaHasta
 * @desc Return
 * @returns
 */
const getParcialesOnline = (FechaDesde: string, FechaHasta: string) => {
  const promise = new Promise<Parciales.OnLine[]>((resolve, reject) => {
    api
      .get(
        `/Parciales/online?FechaDesde=${FechaDesde}&FechaHasta=${FechaHasta}`,
        config()
      )
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

export const ParcialesApi = {
  getParcialesCasinos,
  getParcialesComercial,
  getParcialesTragamonedasI,
  getParcialesTragamonedasC,
  getParcialesOnline,
};

/**
 *
 * @param Fecha
 * @desc Return
 * @returns
 */
const getRecaudado = (Fecha: number) => {
  const promise = new Promise<Presupuesto.Recaudado[]>((resolve, reject) => {
    api
      .get(`/presupuesto/getRecaudado?Fecha=${Fecha}`, config())
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

/**
 *
 * @param Fecha
 * @desc Return
 * @returns
 */
const getRecaudadoAcum = (Fecha: number) => {
  const promise = new Promise<Presupuesto.RecaudadoAcum[]>(
    (resolve, reject) => {
      api
        .get(`/presupuesto/getRecaudadoAcum?Fecha=${Fecha}`, config())
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
  return promise;
};

/**
 *
 * @param Fecha
 * @desc Return
 * @returns
 */
const getGastos = (Fecha: number) => {
  const promise = new Promise<Presupuesto.Gastos[]>((resolve, reject) => {
    api
      .get(`/presupuesto/getGastos?Fecha=${Fecha}`, config())
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

/**
 *
 * @param Fecha
 * @desc Return
 * @returns
 */
const getGastosDetalle = (Fecha: number) => {
  const promise = new Promise<Presupuesto.GastosDetalle[]>(
    (resolve, reject) => {
      api
        .get(`/presupuesto/getGastosDetalle?Fecha=${Fecha}`, config())
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
  return promise;
};

/**
 *
 * @param Fecha
 * @desc Return
 * @returns
 */
const getRecursosDetalle = (Fecha: number) => {
  let aux = Fecha.toString().replace("-", "").replace("-", "");
  aux = aux.substring(0, aux.length - 2);
  Fecha = Number(aux + "01");
  const promise = new Promise<Presupuesto.RecursosDetalle[]>(
    (resolve, reject) => {
      api
        .get(`/presupuesto/getRecursosDetalle?Fecha=${Fecha}`, config())
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
  return promise;
};

const UploadBudget = (formdata: FormData) => {
  const promise = new Promise<any>((resolve, reject) => {
    api
      .post(`/presupuesto/upload`, formdata, config())
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

/**
 *
 * @param Fecha : string
 * @param Tipo : number
 * @returns Extratos
 */
export const verExtractos = (Fecha: number, Tipo: string) => {
  const promise = new Promise<Extracto[]>((resolve, reject) => {
    api
      .get(`/verExtractos?Fecha=${Fecha}&Tipo=${Tipo}`, config())
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

export const prespuesto = {
  getRecaudado,
  getRecaudadoAcum,
  getGastos,
  getGastosDetalle,
  getRecursosDetalle,
  UploadBudget,
};

/**ONLINE */
export const getOnLineDiaria = (Dia: number, Mes: number, Anio: number) => {
  const promise = new Promise<PricipalData[]>((resolve, reject) => {
    api
      .get(`/OnLine/Diaria?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}`, config())
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

export const getAcumuladoBplay = (Dia: number, Mes: number, Anio: number) => {
  const promise = new Promise<PricipalData[]>((resolve, reject) => {
    api
      .get(`/OnLine/AcumuladoBplay?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}`, config())
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

export const getDiariaBplay = (Dia: number, Mes: number, Anio: number) => {
  const promise = new Promise<PricipalData[]>((resolve, reject) => {
    api
      .get(`/OnLine/diariaBplay?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}`, config())
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return
 * @returns
 */
const getOnLineMensual = (Dia: number, Mes: number, Anio: number) => {
  const promise = new Promise<dataUtilidadesComparativos[]>(
    (resolve, reject) => {
      api
        .get(
          `/OnLine/ComparativoMensual?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}`,
          config()
        )
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
  return promise;
};

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return
 * @returns
 */
const getOnLineComparativaMesAnterior = (
  Dia: number,
  Mes: number,
  Anio: number,
  Completo: number
) => {
  const promise = new Promise<dataUtilidadesComparativos[]>(
    (resolve, reject) => {
      api
        .get(
          `/OnLine/ComparativaMesAnterior?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}&Completo=${Completo}`,
          config()
        )
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
  return promise;
};

/**
 *
 * @param Dia
 * @param Mes
 * @param Anio
 * @desc Return
 * @returns
 */
const getOnLineAcumuladoAnual = (Dia: number, Mes: number, Anio: number) => {
  const promise = new Promise<dataUtilidadesComparativos[]>(
    (resolve, reject) => {
      api
        .get(
          `/OnLine/AcumuladoAnual?Anio=${Anio}&Mes=${Mes}&Dia=${Dia}`,
          config()
        )
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
  return promise;
};

const getCargas = (Dia: number, Mes: number, Anio: number) => {
  const promise = new Promise<OnLineModel.cargas>((resolve, reject) => {
    api
      .get(
        `/OnLine/cargas?FechaISO=${Anio}-${zfill(Mes, 2)}-${zfill(Dia, 2)}`,
        config()
      )
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

const getRetiros = (Dia: number, Mes: number, Anio: number) => {
  const promise = new Promise<OnLineModel.retiros>((resolve, reject) => {
    api
      .get(
        `/OnLine/retiros?FechaISO=${Anio}-${zfill(Mes, 2)}-${zfill(Dia, 2)}`,
        config()
      )
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};


const getdetallesIO = (Dia: number, Mes: number, Anio: number) => {
  const promise = new Promise<OnLineModel.IO[]>((resolve, reject) => {
    api
      .get(
        `/online/detallesIO?FechaISO=${Anio}${zfill(Mes, 2)}${zfill(Dia, 2)}`,
        config()
      )
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};
export const OnLine = {
  getOnLineDiaria,
  getOnLineMensual,
  getOnLineComparativaMesAnterior,
  getOnLineAcumuladoAnual,
  getCargas,
  getRetiros,
  getdetallesIO,
  getDiariaBplay, 
  getAcumuladoBplay
};

export const getVersion = () => {
  const promise = new Promise<GitVersion>((resolve, reject) => {
    api
      .get(`${CONFIG.gitservice}`)
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

/**
 * TESTs of WS
 */
const TableroAPI = () => {
  const promise = new Promise<any>((resolve, reject) => {
    api
      .get(`/test`, config())
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

const LogsAPI = () => {
  const promise = new Promise<any>((resolve, reject) => {
    api
      .get(`https://serviceslogs.iafas.gov.ar/test`, config())
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

const loginAPI = () => {
  const promise = new Promise<any>((resolve, reject) => {
    api
      .get(
        `https://servlogin.iafas.gov.ar/consultLastLogOn?user=aramirez`,
        config()
      )
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

export const Test = {
  TableroAPI,
  LogsAPI,
  loginAPI,
};

export const getPremiosMayores = (Fecha: string) => {
  const promise = new Promise<PremiosMayores[]>((resolve, reject) => {
    api
      .get(`/PremiosMayoresPorFecha?Fecha=${Fecha}`, config())
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

interface goolgeRC {
  success: boolean;
}

export const verify = (token: string) => {
  const promise = new Promise<goolgeRC>((resolve, reject) => {
    api
      .post(`/verify-recaptcha`, {
        token: token,
      })
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};
