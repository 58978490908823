import { useEffect, useState, useContext } from "react";
import { prespuesto } from "Functions/tableroApi";
import { dateCompProps, Presupuesto } from "Models/models";
import Numbers from "components/Numbers/Numbers";
import { GlobalStatesContext } from "Providers/context";
import ResourcesDetails from "components/Dashboard/Budget/Panel/Resources/ResourcesDetails";

export default function ResourcesAccumulated(props: dateCompProps) {
  const { state, setContext } = useContext(GlobalStatesContext);
  const [data, setdata] = useState<Presupuesto.RecaudadoAcum[]>();
  useEffect(() => {
    prespuesto
      .getRecaudadoAcum(Number(props.date.replace("-", "").replace("-", "")))
      .then((detail) => {
        setdata(detail);
      });
  }, [props.date]);

  const seeDetails = (concepto: string, nombre: string) => {
    setContext({
      ...state,
      modal: {
        ...state.modal,
        body: (
          <ResourcesDetails
            concepto={concepto}
            date={props.date}
          ></ResourcesDetails>
        ),
        show: true,
        button: false,
        subtitle: nombre,
      },
    });
  };

  return (
    <>
      <div className="tablaContainerBudgetResourcesAc">
        <div className="cardTitulo">Titulo tentativo</div>
        <table className="tabla">
          <thead>
            <tr className="cardTotales">
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <thead>
            <tr className="textFont">
              <th>*</th>
              <th>ejecutado</th>
              <th>%</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, i) => {
              const porc = (item.Recaudado / item.C_actual) * 100;
              return (
                <tr key={`acumuladoanterior${i}`}>
                  <td className="celda textFont wButton">
                    {item.concepto}
                    <button className="buttonDetalle" onClick={() => seeDetails(item.concepto, item.nombre)}>
                      detalle
                    </button>
                  </td>
                  <td>
                    <div className="celda dataFont">
                      <span className="dataSymbol">$</span>
                      <span className="Numbers"><Numbers value={item.Recaudado}></Numbers></span>
                    </div>
                  </td>
                  <td>
                    <div className="celda dataFont">
                      <span className="Numbers"><Numbers value={porc}></Numbers></span>
                      <span className="dataSymbol">%</span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
