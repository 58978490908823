import { useContext } from "react";
import { GlobalStatesContext } from "Providers/context";
import Daily from "components/Dashboard/TragIAFAS/Daily";
import MonthlyComparisons from "components/Dashboard/TragIAFAS/MonthlyComparisons";
import MonthyComparasonsForYears from "components/Dashboard/TragIAFAS/MonthyComparasonsForYears";
import AnnualAccumulated from "components/Dashboard/TragIAFAS/AnnualAccumulated";
import GraphMonthy from "components/Dashboard/TragIAFAS/GraphMonthy";
import GraphAnnual from "components/Dashboard/TragIAFAS/GraphAnnual";

export default function TragIAFAS() {
  const { state } = useContext(GlobalStatesContext);

  return (
    <>
      <div className="containerLeft reduced">
        <div className="tituloTablero">
          <div className="iconLarge"></div>Tragamonedas
        </div>
        <div className="card cardLarge">
          <Daily date={state.date} />
        </div>

       
      </div>
      <div className="containerRight expanded">
        <MonthlyComparisons date={state.date} />

        <MonthyComparasonsForYears date={state.date} />
        <AnnualAccumulated date={state.date} />
        <div className="containerCardGraphRight">
          <GraphMonthy date={state.date} />
          <GraphAnnual date={state.date} />
        </div>
      </div>
    </>
  );
}
