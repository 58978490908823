import Expense from "components/Dashboard/Budget/Panel/Expenses/Expense";
import ExpenseAccumulated from "components/Dashboard/Budget/Panel/Expenses/ExpenseAccumulated";

import { Panelprops } from "Models/models";



export default function Panel2(props: Panelprops) {
        return(<>
        <Expense date={props.Fecha} />
        <ExpenseAccumulated date={props.Fecha1}/>
        <ExpenseAccumulated date={props.Fecha2}/>
        </>);
}