import { useEffect, useState } from "react";
import { TragIAFAS } from "Functions/tableroApi";
import { tragamonedasDetailsCierre } from "Models/models";
import Numbers from "components/Numbers/Numbers";

interface cierreProps {
    idCierre: number;
}

export default function Details(props: cierreProps) {
    const [cierreInfo, setcierreInfo] = useState<tragamonedasDetailsCierre.TragamonedasIAFASCierreInfo>()
    const [cierreDetalle, setcierreDetalle] = useState<tragamonedasDetailsCierre.TragamonedasIAFASCierre>()
    const [cierreTurnos, setcierreTurnos] = useState<tragamonedasDetailsCierre.TragamonedasIAFASCierreTurno[]>()
    const [turnoDetalle, setturnoDetalle] = useState<number>(0)
    const [tuurnoDetalle, settuurnoDetalle] = useState<tragamonedasDetailsCierre.DetalleTurno[]>()

    useEffect(() => {
        TragIAFAS.getCierre(props.idCierre).then(
            cierreiInfo => {
                setcierreInfo(cierreiInfo);
            }
        )
        TragIAFAS.getDetalleCierre(props.idCierre).then(
            cierreDetalle => {
                setcierreDetalle(cierreDetalle);
            }
        )
        TragIAFAS.getTurnosCierre(props.idCierre).then(
            turnos => {
                setcierreTurnos(turnos);
            }
        )
    }, [props.idCierre])

    useEffect(() => {
        if (turnoDetalle !== 0) {
            TragIAFAS.getTurnoDetalle(turnoDetalle).then(turno => {
                settuurnoDetalle(turno);
            })
        }
    }, [turnoDetalle])


    const irTurno = (idTurno: number) => {
        setturnoDetalle(idTurno);
    }

    return (<>
        <div className="cardTitulo">
            <div className="icon "></div>Sala {cierreInfo?.Nombre}</div>
        <div className="btText plusRenglon">id cierre</div>
        <span className="dataFont plusRenglon">{cierreInfo?.IdPlanilla}</span><br></br>
        <div className="btText plusRenglon">fecha</div>
        <span className="dataFont plusRenglon">{cierreInfo?.Fecha}</span><br></br>
        <div className="btText plusRenglon">usuario</div>
        <span className="dataFont plusRenglon">{cierreInfo?.Usuario[0]} <strong>({cierreInfo?.Usuario[1]})</strong></span><br></br>

        <div className="cardTitulo" id="datosTitular">
            <div className="icon "></div>Detalle</div>


        <table className="tabla">
            <thead className="textFont">
                <tr>
                    <th>ingresos</th>
                    <th>tragemonedas</th>
                    <th>ruletas</th>
                    <th>total</th>
                </tr>

            </thead>
            <tbody>
                <tr className="cardTotales">
                    <th>
                        fichaje
                    </th>
                    <td>
                        {cierreDetalle && <div className="celda dataFont">
                            <span className="dataSymbol">$</span>
                            <Numbers value={cierreDetalle?.Venta} />
                        </div>}

                    </td>
                    <td>
                        {cierreDetalle && <div className="celda dataFont">
                            <span className="dataSymbol">$</span><Numbers value={cierreDetalle?.Creditos} /></div>}
                    </td>
                    <td>
                        {cierreDetalle && <div className="celda dataFont">
                            <span className="dataSymbol">$</span><Numbers value={cierreDetalle?.Creditos + cierreDetalle?.Venta} /></div>}
                    </td>
                </tr>

            </tbody>
            <thead className="textFont">
                <tr>
                    <th>egresos</th>
                    <th>tragemonedas</th>
                    <th>ruletas</th>
                    <th>total</th>
                </tr>

            </thead>
            <tbody>
                <tr>
                    <th>
                        premios
                    </th>
                    <td>
                        {cierreDetalle && <div className="celda dataFont">
                            <span className="dataSymbol">$</span><Numbers value={cierreDetalle?.Compra} /></div>}

                    </td>
                    <td>
                        {cierreDetalle && <div className="celda dataFont">
                            <span className="dataSymbol">$</span><Numbers value={cierreDetalle?.Debitos} /></div>}
                    </td>
                    <td>
                        {cierreDetalle && <div className="celda dataFont">
                            <span className="dataSymbol">$</span><Numbers value={cierreDetalle?.Compra + cierreDetalle?.Debitos} /></div>}

                    </td>
                </tr>
                <tr>
                    <th>
                        pagos manuales
                    </th>
                    <td>
                        {cierreDetalle && <div className="celda dataFont">
                            <span className="dataSymbol">$</span><Numbers value={cierreDetalle?.PagosManuales + cierreDetalle?.ajustePM} /></div>}

                    </td>
                    <td>
                        {cierreDetalle && <div className="celda dataFont">
                            <span className="dataSymbol">$</span><Numbers value={cierreDetalle?.PMRuleta + cierreDetalle?.ajustePMRuleta} /></div>}
                    </td>
                    <td>
                        {cierreDetalle && <div className="celda dataFont">
                            <span className="dataSymbol">$</span><Numbers value={cierreDetalle?.PMRuleta + cierreDetalle?.ajustePMRuleta + cierreDetalle?.PagosManuales + cierreDetalle?.ajustePM} /></div>}
                    </td>
                </tr>
                <tr>
                    <th>
                        subtotal egresos
                    </th>
                    <td>
                        {cierreDetalle && <div className="celda dataFont">
                            <span className="dataSymbol">$</span><Numbers value={cierreDetalle?.PagosManuales + cierreDetalle?.ajustePM + cierreDetalle?.Compra} /></div>}
                    </td>
                    <td>
                        {cierreDetalle && <div className="celda dataFont">
                            <span className="dataSymbol">$</span><Numbers value={cierreDetalle?.PMRuleta + cierreDetalle?.ajustePMRuleta + cierreDetalle?.Debitos} /></div>}
                    </td>
                    <td>
                        {cierreDetalle && <div className="celda dataFont">
                            <span className="dataSymbol">$</span><Numbers value={cierreDetalle?.PMRuleta + cierreDetalle?.ajustePMRuleta + cierreDetalle?.Debitos + cierreDetalle?.PagosManuales + cierreDetalle?.ajustePM + cierreDetalle?.Compra} /></div>}
                    </td>
                </tr>
            </tbody>
            <thead className="textFont">
                <tr>
                    <th>total</th>
                    <th>tragemonedas</th>
                    <th>ruletas</th>
                    <th>total</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>
                        utilidad
                    </th>
                    <td>
                        {cierreDetalle && <div className="celda dataFont">
                            <span className="dataSymbol">$</span><Numbers value={cierreDetalle?.Venta - cierreDetalle?.PagosManuales - cierreDetalle?.ajustePM - cierreDetalle?.Compra} /></div>}
                    </td>
                    <td>
                        {cierreDetalle && <div className="celda dataFont">
                            <span className="dataSymbol">$</span><Numbers value={cierreDetalle?.Creditos - cierreDetalle?.PMRuleta - cierreDetalle?.ajustePMRuleta - cierreDetalle?.Debitos} /></div>}
                    </td>
                    <td>
                        {cierreDetalle && <div className="celda dataFont">
                            <span className="dataSymbol">$</span><Numbers total={true} value={cierreDetalle?.Creditos - cierreDetalle?.PMRuleta - cierreDetalle?.ajustePMRuleta - cierreDetalle?.Debitos + cierreDetalle?.Venta - cierreDetalle?.PagosManuales - cierreDetalle?.ajustePM - cierreDetalle?.Compra} /></div>}
                    </td>
                </tr>
            </tbody>
        </table>

        {turnoDetalle === 0 && <><div className="cardTitulo" id="datosTitular">
            <div className="icon "></div>Turnos de la jornada</div>

            <table className="tabla">
                <thead className="textFont">
                    <tr>
                        <th>Cajero</th>
                        <th>fecha inicio</th>
                        <th>fecha final</th>
                        <th>sobrante</th>
                    </tr>
                </thead>
                <tbody>
                    {cierreTurnos?.map((turno, i) => {
                        return <tr key={`turno${i}`} onClick={() => irTurno(turno.idTurno)} style={{ cursor: "pointer" }}>
                            <td><div className="celda dataFont">{turno.Cajero}</div><div><Numbers value={turno.idTurno} intenger={true} /> | {turno.Caja}</div></td>
                            <td><div className="celda dataFont">{turno.Inicio}</div></td>
                            <td><div className="celda dataFont">{turno.Final}</div></td>
                            <td><div className="celda dataFont"><span className="dataSymbol">$</span><Numbers value={turno.Saldo} /></div></td>
                        </tr>
                    })}
                </tbody>
            </table></>}
        {turnoDetalle > 0 && <div><div className="cardTitulo" id="datosTitular">
            <div className="icon "></div>Detalle del turno #{turnoDetalle} <span className="btIcon iconArrowLeft" id="arrowUsuarioID" onClick={() => setturnoDetalle(0)}></span></div>
            <div className="tablaContainer" style={{ overflow: 'scroll' }}>
                <table className="tabla">
                    <thead className="textFont" >
                        <tr>
                            <th>Descripción</th>
                            <th>nro Comp</th>
                            <th>MontoMov</th>
                            <th>momento</th>
                        </tr>
                    </thead>
                    <tbody >
                        {tuurnoDetalle?.map((turnoDetalle, i) => {
                            return <tr key={`turnoDetalle${i}`}>
                                <td><div className="celda dataFont">{turnoDetalle.Descripcion}</div></td>
                                <td><div className="celda dataFont">{turnoDetalle.nroComprobante}</div></td>
                                <td><div className="celda dataFont"><span className="dataSymbol">$</span><Numbers value={turnoDetalle.MontoMov} /></div></td>
                                <td><div className="celda dataFont">{turnoDetalle.momento}</div></td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>}
    </>);
}