import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Auth } from "components/Auth/Auth";
import { Menu } from "components/Dashboard/Menu/Menu";
import Spinner from "components/Spinner/Spinner";

const Dashboard = (): JSX.Element => {
  return (
    <>
      <Auth />

      <div className="containerGral">
        <Menu />
        <div className="containerContent">
          <Suspense fallback={<Spinner />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
