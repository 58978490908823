import { useEffect, useState } from "react";
import { PotencialesAPI } from "Functions/tableroApi";
import { Potenciales, dateCompProps } from "Models/models";
import { ParseYearMonthDay } from "Functions/functions";
import Numbers from "components/Numbers/Numbers";

export default function PotencialesMasAltos(props: dateCompProps) {
  const [PotencialesMasAltos, setPotencialesMasAltos] =
    useState<Potenciales.PotencialesMasAltos[]>();

  useEffect(() => {
    const DateSplit = ParseYearMonthDay(props.date);
    PotencialesAPI.getPotencialesMasAltos(
      Number(DateSplit?.day),
      Number(DateSplit?.month),
      Number(DateSplit?.year)
    ).then((data) => {
      setPotencialesMasAltos(data);
    });
  }, [props.date]);

  return (
    <>
      <div className="tablaContainerComercialXS" id="potentialTablaThird">
        <div className="cardTitulo">
          <div className="icon "></div>Premios Potenciales mas Altos [
          {ParseYearMonthDay(props.date)?.day}/
          {ParseYearMonthDay(props.date)?.month}/
          {ParseYearMonthDay(props.date)?.year}]
        </div>
        <table className="tabla" id="potenciales">
          <thead className="textFont">
            <tr>
              <th>cantidad</th>
              <th>nro</th>
              <th>cifras</th>
              <th>monto</th>
              <th>premioPotencial</th>
              <th>loteria</th>
            </tr>
          </thead>
          <tbody>
            {PotencialesMasAltos?.map((item, i) => {
              return (
                <tr key={`agenciasmasticketsvendidos${i}`}>
                  <td>
                    <div className="celda celdaCenter textFont">
                      <Numbers value={item.Cantidad} intenger={true} />
                    </div>
                  </td>

                  <td>
                    <div className="celda celdaCenter dataFont">{item.NroApostado}</div>
                  </td>
                  <td>
                    <div className="celda celdaCenter textFont">{item.Cifras}</div>
                  </td>
                  <td>
                    <div className="celda dataFont">
                      <span className="dataSymbol">$</span>
                      <Numbers value={item.MontoApostado} />
                    </div>
                  </td>
                  <td>
                    <div className="celda dataFont">
                      <span className="dataSymbol">$</span>
                      <Numbers value={item.PremioPotencial} />
                    </div>
                  </td>

                  <td>
                    <div className="celda celdaCenter textFont">{item.Loterias}</div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
