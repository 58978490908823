import { useEffect, useState, useContext, lazy } from "react";
// import Map from "components/Dashboard/AgencyDensity/Map";
import { getDensidadPorHabitantes } from "Functions/tableroApi";
import Agency from "components/Dashboard/AgencyDensity/Agency";
import { DensidadPorHabitantes } from "Models/models";
import { GlobalStatesContext } from "Providers/context";
import { ParseYearMonthDay } from "Functions/functions";
import Numbers from "components/Numbers/Numbers";

const Map = lazy(() => import('components/Dashboard/AgencyDensity/Map'));

export default function AgencyDensity() {
    const [data, setdata] = useState<DensidadPorHabitantes[]>();
    const [idAgencia, setidAgencia] = useState<number>();
    const { state, setContext } = useContext(GlobalStatesContext);
    const [sorting, setSorting] = useState({ column: null, direction: true });

    useEffect(() => {
        const DateSplit = ParseYearMonthDay(state.date);
        setContext({ ...state, spinner: { loading: true } });
        getDensidadPorHabitantes(
            Number(DateSplit?.day),
            Number(DateSplit?.month),
            Number(DateSplit?.year)
        )
            .then((dens) => {
                setdata(dens);
                // console.table(dens);
            })
            .catch(() => {
                console.error("Fallo de la API");
            }).finally(() => {
                setContext({ ...state, spinner: { loading: false } });
            })

    }, [state.date]);

    useEffect(() => {
        if (idAgencia !== undefined) {
            setContext({
                ...state,
                modal: {
                    ...state.modal,
                    body: <Agency Agencia={Number(idAgencia)} />,
                    show: true,
                    title: "Información de Agencias",
                    subtitle: "",
                    closeModal: closeModal,
                },
            });
        }

    }, [idAgencia]);

    const closeModal = () => {
        setContext({
            ...state,
            modal: {
                ...state.modal,
                show: false,
            },
        });
    };

    const handleSort = (column: any) => {
        setSorting(prev => ({
            column,
            direction: prev.column === column && prev.direction === true ? false : true
        }));
    };

    useEffect(() => {
        if (sorting.column !== null) {

            const mydata = sortByColumn(sorting.column, sorting.direction);
            setdata(mydata);
        }
    }, [sorting])



    function sortByColumn(column: keyof DensidadPorHabitantes, ascending: boolean = true): DensidadPorHabitantes[] | undefined {
        if (data) {
            return data.slice().sort((a, b) => {
                const valueA = a[column];
                const valueB = b[column];
                if (valueA === valueB) return 0;
                if (ascending) {
                    return valueA < valueB ? -1 : 1;
                } else {
                    return valueA > valueB ? -1 : 1;
                }
            });
        }

    }
    return (
        <>
            <div className="containerLeft reduced">
                <div className="tituloTablero">
                    <div className="iconLarge"></div>Densidad de Agencias
                </div>
                <div className="">
                    <div className="card cardVertical" id="tablaScrollLeft">
                        <table className="tabla">
                            <thead className="textFont">
                                <tr>
                                    <th onClick={() => handleSort('Ciudad')}>ciudad</th>
                                    <th onClick={() => handleSort('CantidadAgencias')}>agencias</th>
                                    <th onClick={() => handleSort('Habitantes')}>habitantes</th>
                                    <th onClick={() => handleSort('HabitantesPorAgencias')}>HAB X AG</th>
                                    <th onClick={() => handleSort('Recaudacion')}>recaudación</th>
                                    <th onClick={() => handleSort('RecaudacionPorHabitantes')}>rec. x hab.</th>
                                    <th onClick={() => handleSort('Comision')}>comisión</th>
                                    <th onClick={() => handleSort('Cada2MilHab')}>cada mil hab.</th>
                                    <th onClick={() => handleSort('TotalTicketGameOn')}>TG</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((row, i) => {
                                    return (
                                        <tr key={i}>
                                            <td className="cReduced dataFont">{row.Ciudad}</td>
                                            <td className="cReduced dataFont">
                                                <Numbers
                                                    value={row.CantidadAgencias}
                                                    intenger={true}
                                                ></Numbers>
                                            </td>
                                            <td className="cReduced dataFont">
                                                <Numbers
                                                    value={row.Habitantes}
                                                    intenger={true}
                                                ></Numbers>
                                            </td>
                                            <td className="cReduced dataFont">
                                                <Numbers
                                                    value={row.HabitantesPorAgencias}
                                                    intenger={true}
                                                ></Numbers>
                                            </td>
                                            <td className="cReduced dataFont">
                                                <Numbers value={row.Recaudacion}></Numbers>
                                            </td>
                                            <td className="cReduced dataFont">
                                                <Numbers value={row.RecaudacionPorHabitantes}></Numbers>
                                            </td>
                                            <td className="cReduced dataFont">
                                                <Numbers value={row.Comision}></Numbers>
                                            </td>
                                            <td className="cReduced dataFont">
                                                <Numbers
                                                    value={row.Cada2MilHab}
                                                    intenger={true}
                                                ></Numbers>
                                            </td>
                                            <td className="cReduced dataFont"> <Numbers
                                                value={row.TotalTicketGameOn}
                                                intenger={true}
                                            ></Numbers></td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="containerRight expandedMap map">
                <Map setId={setidAgencia} />
            </div>
        </>
    );
}
