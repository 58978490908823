import { CONFIG } from "Functions/config";
const { urlVideoSorteo } = CONFIG;

export default function Sorteo(): JSX.Element {
  return (
    <>
      <iframe
        width={640}
        height={390}
        src={urlVideoSorteo}
        title="Sorteo en vivo"
        allow='autoplay; encrypted-media'
      ></iframe>
    </>
  );
}
