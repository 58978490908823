import React from "react";
import { Link } from "react-router-dom";
import { MailToInterface } from "Models/models";

const ButtonMailto = ({ mailto, label }: MailToInterface) => {
  return (
    <Link className="dataFont"
      to="#"
      onClick={(e) => {
        window.location.href = "mailto:" + mailto;
        e.preventDefault();
      }}
    >
      {label}
    </Link>
  );
};

export default ButtonMailto;
