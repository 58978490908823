import { useContext, useState } from "react";
import { GlobalStatesContext } from "Providers/context";
import Daily from "components/Dashboard/Comercial/Daily";
import DailyForDepartament from "components/Dashboard/Comercial/DailyForDepartament";
import MonthlyComparisons from "components/Dashboard/Comercial/MonthlyComparisons";
import MonthyComparasonsForYears from "components/Dashboard/Comercial/MonthyComparasonsForYears";
import AnnualAccumulated from "components/Dashboard/Comercial/AnnualAccumulated";
import GraphMonthy from "components/Dashboard/Comercial/GraphMonthy";
import GraphAnnual from "components/Dashboard/Comercial/GraphAnnual";
import GraphProgressTombola from "components/Dashboard/Comercial/GraphProgressTombola";

export default function Comercial() {
  const { state } = useContext(GlobalStatesContext);
  const [source, setsource] = useState(true);

  return (
    <>
      <div className="containerLeft reduced">
        <div className="tituloTablero">
          <div className="iconLarge"></div>Comercial
        </div>
        <div  id="cardXDep" className={source?'card cardLargeComercial': 'card cardVertical'}>   <div className="cardTitulo">
          <div className="inline">
            <div className="cardTitulo">
              <div className="icon "></div>
            </div>
            <div title="Listado dividido por juegos"
              className={
                source ? "bt btText switchActive" : "bt btText switchInactive"
              }
              onClick={() => setsource(!source)}
            >
              juegos
            </div>
            <div title="Listado dividido por departamentos"
              className={
                source ? "bt btText switchInactive" : "bt btText switchActive"
              }
              onClick={() => setsource(!source)}
            >
              departamentos
            </div>
          </div>
        </div>{source && <Daily date={state.date} />}
          {!source && <DailyForDepartament date={state.date} />}


        </div>
        {source && <div id="cardComercialPie">
          <GraphProgressTombola date={state.date} />
        </div>}

      </div>
      <div className="containerRight expandedXS">
        <MonthlyComparisons date={state.date} />

        <MonthyComparasonsForYears date={state.date} />
        <AnnualAccumulated date={state.date} />
        <div className="containerCardGraphRight">
          <GraphMonthy date={state.date} />
          <GraphAnnual date={state.date} />
        </div>
      </div>
    </>
  );
}
