/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { getPrincipalAcumuladosDetallados } from "Functions/tableroApi";
import { dateCompProps, Graph } from "Models/models";
import { ParseYearMonthDay } from "Functions/functions";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options: ChartOptions = {
  maintainAspectRatio: true,
  responsive: true,

  plugins: {
    legend: {
      position: "left" as const,
      labels: {
        boxWidth: 16,
        boxHeight: 16,
      },
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || "";

          if (label) {
            label += ": ";
          }
          if (context.parsed.y !== null) {
            label += new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(context.parsed.y);
          }
          return label;
        },
      },
    },
  },
  animations: {
    tension: {
      duration: 1000,
      easing: "linear",
      from: 0,
      to: 0.5,
      loop: false,
    },
  },
};

const labels = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];

const businessUnit: string[] = [];
const businessUnitDates: string[] = [];

export default function MonthlyProgressionGraph(props: dateCompProps) {
  const [data, setdata] = useState<Graph.ChartData>();
  const [source, setsource] = useState(false);
  useEffect(() => {
    const DateSplit = ParseYearMonthDay(props.date);
    if (DateSplit !== undefined) {
      getPrincipalAcumuladosDetallados(
        DateSplit.day,
        DateSplit.month,
        DateSplit.year
      )
        .then((accDetailed) => {
          const accDetailedAux = accDetailed;
          const UtilidadesNetaCasinos: any[] = [];
          const UtilidadesNetaComercial: any[] = [];
          const UtilidadesNetaTI: any[] = [];
          const UtilidadesNetaTC: any[] = [];
          const UtilidadesNetaONLINE: any[] = [];

          accDetailed.forEach((acc) => {
            if (!businessUnit.includes(acc.Nombre)) {
              businessUnit.push(acc.Nombre);
            }
          });

          accDetailed.forEach((acc) => {
            if (!businessUnitDates.includes(acc.Fecha)) {
              businessUnitDates.push(acc.Fecha);
            }
          });

          accDetailedAux.forEach((utl) => {
            /**
             * Recaudacion o Utilidad
             */
            if (!source) {
              if (utl.Nombre === "TOTAL Casinos") {
                UtilidadesNetaCasinos.push(utl.Utilidad);
                // console.log(utl.Nombre);
              }
              if (utl.Nombre === "TOTAL Comercial") {
                UtilidadesNetaComercial.push(utl.Utilidad);
                // console.log(utl.Fecha);
              }
              if (utl.Nombre === "TOTAL Tragamonedas IAFAS") {
                UtilidadesNetaTI.push(utl.Utilidad);
                // console.log(utl.Nombre);
              }
              if (utl.Nombre === "TOTAL Tragamonedas Concesionadas") {
                UtilidadesNetaTC.push(utl.Utilidad);
              }
              if (utl.Nombre === "TOTAL ONLINE") {
                UtilidadesNetaONLINE.push(utl.Utilidad);
              }
            } else {
              if (utl.Nombre === "TOTAL Casinos") {
                UtilidadesNetaCasinos.push(utl.Recaudacion);
                // console.log(utl.Nombre);
              }
              if (utl.Nombre === "TOTAL Comercial") {
                UtilidadesNetaComercial.push(utl.Recaudacion);
                // console.log(utl.Nombre);
              }
              if (utl.Nombre === "TOTAL Tragamonedas IAFAS") {
                UtilidadesNetaTI.push(utl.Recaudacion);
                // console.log(utl.Nombre);
              }
              if (utl.Nombre === "TOTAL Tragamonedas Concesionadas") {
                UtilidadesNetaTC.push(utl.Recaudacion);
              }
              if (utl.Nombre === "TOTAL ONLINE") {
                UtilidadesNetaONLINE.push(utl.Recaudacion);
              }
            }
          });

          // console.table(UtilidadesNetaComercial);

          const dataArr = {
            labels: labels,
            datasets: [
              {
                label: "Casinos",
                borderColor: "rgb(166, 22, 0)",
                backgroundColor: "rgba(166, 22, 0, 0.5)",
                data: UtilidadesNetaCasinos,
              },
              {
                label: "Comercial",
                borderColor: "rgb(30, 148, 36)",
                backgroundColor: "rgba(30, 148, 36, 0.5)",
                data: UtilidadesNetaComercial,
              },
              {
                label: "Trag. IAFAS",
                borderColor: "rgb(255, 153, 0)",
                backgroundColor: "rgba(255, 153, 0, 0.5)",
                data: UtilidadesNetaTI,
              },
              {
                label: "Trag. Concesionadas",
                borderColor: "rgb(207, 0, 149)",
                backgroundColor: "rgb(207, 0, 149,0.5)",
                data: UtilidadesNetaTC,
              },
              {
                label: "OnLine",
                borderColor: "rgb(21, 143, 245)",
                backgroundColor: "rgb(21, 143, 245,0.5)",
                data: UtilidadesNetaONLINE,
              },
            ],
          };
          setdata(dataArr);
        })
        .catch(() => {
          console.error("No esta corriendo la API");
          // window.location.replace("/login");
        });
    }
  }, [props.date, source]);

  return (
    <>
      <div className="card">
        <div className="cardTitulo">
          <div className="inline">
            <div className="cardTitulo">
              <div className="icon "></div>PROGRESIÓN MENSUAL ACUMULADA [
              {ParseYearMonthDay(props.date)?.day}/
              {ParseYearMonthDay(props.date)?.month}/
              {ParseYearMonthDay(props.date)?.year}]
            </div>
            <div
              className={
                source ? "bt btText switchActive" : "bt btText switchInactive"
              }
              onClick={() => setsource(!source)}
            >
              {" "}
              recaudación
            </div>
            <div
              className={
                source ? "bt btText switchInactive" : "bt btText switchActive"
              }
              onClick={() => setsource(!source)}
            >
              utilidades
            </div>
          </div>
        </div>
        <div id="graphContainer">
          {data !== undefined && (
            <Line id="graphContainer" options={options} data={data} />
          )}
        </div>
      </div>
    </>
  );
}
