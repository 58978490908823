import { useEffect, useState, useContext } from "react";
import { OnLine } from "Functions/tableroApi";
import { PricipalData, dateCompProps } from "Models/models";
import { ParseYearMonthDay } from "Functions/functions";
import { GlobalStatesContext } from "Providers/context";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import Numbers from "components/Numbers/Numbers";


ChartJS.register(ArcElement, Tooltip, Legend);

export default function Cash(props: dateCompProps) {
  const { state, setContext } = useContext(GlobalStatesContext);
  const [data2, setdata2] = useState<PricipalData[]>();


  useEffect(() => {
    const DateSplit = ParseYearMonthDay(props.date);
    OnLine.getAcumuladoBplay(
      Number(DateSplit?.day),
      Number(DateSplit?.month),
      Number(DateSplit?.year)
    )
      .then((carga) => {
        setdata2(carga);
      })
      .catch(() => {
        setContext({
          ...state,
          toast: {
            text: "Hubo un problema al conectar con el WS.",
            show: true,
          },
        });
        console.log("No esta corriendo la API");
      });
  }, [props.date]);

  return (
    <div className="tablaContainerChica">
      <div className="cardTitulo">
        <div className="icon "></div>Acumulados ONLINE [
        {ParseYearMonthDay(props.date)?.day}/
        {ParseYearMonthDay(props.date)?.month}/
        {ParseYearMonthDay(props.date)?.year}]
      </div>
      <table className="tabla" key={`tablaRetiros`}>
        <thead className="textFont">
          <tr>
            <th>Juego</th>
            <th>recaudacion</th>
            <th>utilidad</th>
            <th>cannon</th>
          </tr>
        </thead>
        <tbody>

          {data2 && data2.map((u, i) => {
            return (
              <tr key={`tablaAccOnline${i}`}>
                <th>
                  <div className="celda textFont">{u.Nombre}</div>
                </th>
                <td>
                  <div className="celda dataFont smallTotal">
                    <span className="dataSymbol">$</span>
                    {u.Recaudacion !== undefined && (
                      <Numbers value={u.Recaudacion} />
                    )}
                  </div>
                </td>
                <td>
                  <div className="celda dataFont smallTotal">
                    <span className="dataSymbol">$</span>
                    {u.Utilidad !== undefined && (
                      <Numbers value={u.Utilidad} />
                    )}
                  </div>
                </td>
                <td>
                  <div className="celda dataFont smallTotal">
                    <span className="dataSymbol">$</span>
                    {u.UtilidadNeta !== undefined && (
                      <Numbers value={u.UtilidadNeta} />
                    )}
                  </div>
                </td>
              </tr>
            );
          })}


        </tbody>

      </table>
    </div>
  );
}