import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { getAcumuladosMensualesTombola } from "Functions/tableroApi";
import { dateCompProps, Graph} from "Models/models";
import { ParseYearMonthDay } from "Functions/functions";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options: ChartOptions = {
  maintainAspectRatio: true,
  responsive: true,

  plugins: {
    legend: {
      position: "left" as const,
      labels: {
        boxWidth: 16,
        boxHeight: 16,
      },
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || "";

          if (label) {
            label += ": ";
          }
          if (context.parsed.y !== null) {
            label += new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(context.parsed.y);
          }
          return label;
        },
      },
    },
  },
  animations: {
    tension: {
      duration: 1000,
      easing: "linear",
      from: 0,
      to: 0.5,
      loop: false,
    },
  },
};

const labels = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];

export default function MonthlyProgressionGraph(props: dateCompProps) {
  const [data, setdata] = useState<Graph.ChartData>();
  const [source, setsource] = useState(true);
  const [TombolaONLINE, setTombolaONLINE] = useState(false);
  useEffect(() => {
    const DateSplit = ParseYearMonthDay(props.date);
    if (DateSplit !== undefined) {
      getAcumuladosMensualesTombola(
        DateSplit.day,
        DateSplit.month,
        DateSplit.year
      )
        .then((tomb) => {
          // console.table(tomb);
          const Recaudaciones_Primero: number[] = [];
          const Recaudaciones_Previa: number[] = [];
          const Recaudaciones_Matutina: number[] = [];
          const Recaudaciones_Nocturna: number[] = [];
          const Recaudaciones_Vespertina: number[] = [];
          const Recaudaciones_ONLINE: number[] = [];

          tomb.forEach((acc) => {
            switch (acc.Nombre) {
              case "El Primero":
                source
                  ? Recaudaciones_Primero.push(acc.Recaudacion)
                  : Recaudaciones_Primero.push(acc.Utilidad);
                break;
              case "La Previa":
                source
                  ? Recaudaciones_Previa.push(acc.Recaudacion)
                  : Recaudaciones_Previa.push(acc.Utilidad);
                break;
              case "Tómbola Matutina":
                source
                  ? Recaudaciones_Matutina.push(acc.Recaudacion)
                  : Recaudaciones_Matutina.push(acc.Utilidad);
                break;
              case "Tómbola Nocturna":
                source
                  ? Recaudaciones_Nocturna.push(acc.Recaudacion)
                  : Recaudaciones_Nocturna.push(acc.Utilidad);
                break;
              case "Tómbola Vespertina":
                source
                  ? Recaudaciones_Vespertina.push(acc.Recaudacion)
                  : Recaudaciones_Vespertina.push(acc.Utilidad);
                break;
              case "Tómbola ONLINE":
                setTombolaONLINE(true);
                source
                  ? Recaudaciones_ONLINE.push(acc.Recaudacion)
                  : Recaudaciones_ONLINE.push(acc.Utilidad);
                break;
            }
          });

          const ONLINE = {
            label: "Tómbola ONLINE",
            borderColor: "rgb(107, 0, 149)",
            backgroundColor: "rgb(107, 0, 149,0.5)",
            data: Recaudaciones_ONLINE,
          };

          const dataArr = {
            labels: labels,
            datasets: [
              {
                label: "El Primero",
                borderColor: "rgb(166, 22, 0)",
                backgroundColor: "rgba(166, 22, 0, 0.5)",
                data: Recaudaciones_Primero,
              },
              {
                label: "La Previa",
                borderColor: "rgb(30, 148, 36)",
                backgroundColor: "rgba(30, 148, 36, 0.5)",
                data: Recaudaciones_Previa,
              },
              {
                label: "Tómbola Matutina",
                borderColor: "rgb(255, 153, 0)",
                backgroundColor: "rgba(255, 153, 0, 0.5)",
                data: Recaudaciones_Matutina,
              },
              {
                label: "Tómbola Nocturna",
                borderColor: "rgb(207, 0, 149)",
                backgroundColor: "rgb(207, 0, 149,0.5)",
                data: Recaudaciones_Nocturna,
              },
              {
                label: "Tómbola Vespertina",
                borderColor: "rgb(107, 0, 149)",
                backgroundColor: "rgb(107, 0, 149,0.5)",
                data: Recaudaciones_Vespertina,
              }
            ],
          };
          TombolaONLINE ? dataArr.datasets.push(ONLINE) : console.log('no hay online');
          setdata(dataArr);
        })
        .catch(() => {
          console.error("No esta corriendo la API");
          // window.location.replace("/login");
        });
    }
  }, [TombolaONLINE, props.date, source]);

  return (
    <>
      <div className="cardComercialPie">
        <div className="cardTitulo">
          <div className="inline">
            <div className="cardTitulo">
              <div className="icon "></div>PROGRESIÓN MENSUAL TOMBOLAS [
              {ParseYearMonthDay(props.date)?.day}/
              {ParseYearMonthDay(props.date)?.month}/
              {ParseYearMonthDay(props.date)?.year}]
            </div>
            <div
              className={
                source ? "bt btText switchActive" : "bt btText switchInactive"
              }
              onClick={() => setsource(!source)}
            >
              {" "}
              recaudación
            </div>
            <div
              className={
                source ? "bt btText switchInactive" : "bt btText switchActive"
              }
              onClick={() => setsource(!source)}
            >
              utilidades
            </div>
          </div>
        </div>
        <div id="graphContainerCom2">
          {data !== undefined && (
            <Line id="graphContainerCom2" options={options} data={data} />
          )}
        </div>
      </div>
    </>
  );
}
