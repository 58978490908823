import React, { useEffect, useState, useContext } from "react";
import { Comercial } from "Functions/tableroApi";
import { dateCompProps, dataUtilidades } from "Models/models";
import { ParseYearMonthDay } from "Functions/functions";
import Numbers from "components/Numbers/Numbers";
import { GlobalStatesContext } from "Providers/context";

interface withLocalidad extends dataUtilidades {
  localidades?: dataUtilidades[];
}

export default function DailyForDepartament(props: dateCompProps) {
  const { state, setContext } = useContext(GlobalStatesContext);
  const [data, setdata] = useState<withLocalidad[]>();
  const [totales, settotales] = useState<dataUtilidades>({
    Departamento: "",
    Premios: 0,
    Recaudacion: 0,
    Utilidad: 0,
    Comision: 0,
    Captacion: 0,
    UtilidadNeta: 0,
    Porcentaje1: 0,
    Porcentaje2: 0,
    PremioMayor: 'no'
  });
  const [Localidad, setLocalidad] = useState<string>('nada');

  useEffect(() => {
    setContext({ ...state, spinner: { loading: true } });
    const DateSplit = ParseYearMonthDay(props.date);
    Comercial.getDiariaDepartamentos(
      Number(DateSplit?.day),
      Number(DateSplit?.month),
      Number(DateSplit?.year)
      , 'nada'
      , 0
    )
      .then((data) => {
        setdata(data);
        let DataRec = 0;
        let DataUti = 0;
        let DataCom = 0;
        let DataPrem = 0;
        let DataCap = 0;
        let DataUtlB = 0;

        data.forEach((sub) => {
          if (sub.Recaudacion !== null) DataRec = DataRec + sub.Recaudacion;
          if (sub.Premios !== null) DataPrem = DataPrem + sub.Premios;
          if (sub.Comision !== undefined) DataCom = DataCom + sub.Comision;
          if (sub.Utilidad !== null) DataUti = DataUti + sub.Utilidad;
          if (sub.Captacion !== undefined) DataCap = DataCap + sub.Captacion;
          if (sub.UtilidadNeta !== undefined) DataUtlB = DataUtlB + sub.UtilidadNeta;
        });
        settotales({
          ...totales,
          Departamento: "",
          Premios: DataPrem,
          Recaudacion: DataRec,
          Utilidad: DataUti,
          Comision: DataCom,
          Captacion: DataCap,
          UtilidadNeta: DataUtlB,
          Porcentaje1: 0,
          Porcentaje2: 0,
          PremioMayor: 'no'
        });

      })
      .catch(() => {
        setContext({
          ...state,
          toast: {
            text: "Hubo un problema al conectar con el WS.",
            show: true,
          },
        });
        console.log("No esta corriendo la API");
      }).finally(() => {
        setContext({ ...state, spinner: { loading: false } });
      })


  }, [props.date]);

  const VerDepartamento = (paramDepartamento: string, id: string) => {

    if (Localidad !== paramDepartamento) {
      setLocalidad(paramDepartamento);
      llenarLocalidades(id, paramDepartamento);
    } else {
      const tempData: withLocalidad[] = [];

      data?.forEach(row => {
        if (row.Departamento === paramDepartamento) {
          row.localidades = undefined;
        }
        tempData.push(row);

      });
      setdata(tempData);
    }

    /* ACA BORRABA LOS AGREGADOS
    const agregauuuu = document.querySelectorAll(`.agregado${id}`);
    agregauuuu.forEach((add) => {
      add.remove();
    });
    */
    girarArrows(paramDepartamento);
  }

  const girarArrows = (id: string) => {
    const arrowActiva = document.getElementById("arrow" + id);
    if (arrowActiva?.classList.contains("iconArrowLeft") === true) {
      arrowActiva.classList.remove("iconArrowLeft");
      arrowActiva.classList.add("iconArrowDown");

    } else {
      arrowActiva?.classList.remove("iconArrowDown");
      arrowActiva?.classList.add("iconArrowLeft");
      setLocalidad('nada');
    }


  }

  const llenarLocalidades = async (id: string, paramLocal: string) => {
    const tr = document.getElementById(`daily${id}`);
    if (tr) {
      const DateSplit = ParseYearMonthDay(props.date);
      try {
        const locs = await Comercial.getDiariaDepartamentos(
          Number(DateSplit?.day),
          Number(DateSplit?.month),
          Number(DateSplit?.year),
          paramLocal,
          1
        );
    


        const miDepto = locs[0].Departamento;
        const tempData: withLocalidad[] = [];

        // console.log(miDepto)

        data?.forEach(row => {
          if (row.Departamento === miDepto) {
            row.localidades = locs;
          }
          tempData.push(row);

        });
        setdata(tempData);

      } catch (error) {
        console.log("Error al obtener los datos:", error);
      }
    }
  }

  return (
    <>
      <div className="cardTitulo">
        <div className="icon "></div>TOTALES DIARIOS POR DEPARTAMENTOS [
        {ParseYearMonthDay(props.date)?.day}/
        {ParseYearMonthDay(props.date)?.month}/
        {ParseYearMonthDay(props.date)?.year}]
      </div>
      <div className="tablaContainerScroll tableScroll">
        <table className="tabla">
          <thead className="headNoScroll">
            <tr className="cardTotales">
              <th>
                <div className="cReduced textFont">TOTAL</div>
              </th>
              <th>
                <div className="cReduced dataFont smallTotal">
                  <span className="dataSymbol">$</span>
                  <Numbers value={totales.Recaudacion} />
                </div>
              </th>
              <th >
                <div className="cReduced dataFont smallTotal">
                  <span className="dataSymbol">$</span>
                  <Numbers value={totales.Premios} />
                </div>
              </th>
              <th>
                <div className="cReduced dataFont smallTotal">
                  <span className="dataSymbol">$</span>
                  {totales.Comision && <Numbers value={totales.Comision} />}
                </div>
              </th>
              <th>
                <div className="cReduced dataFont smallTotal">
                  <span className="dataSymbol">$</span>
                  <Numbers value={totales.Utilidad} />
                </div>
              </th>
              <th>
                <div className="cReduced dataFont smallTotal">
                  <span className="dataSymbol">$</span>
                  {totales.Captacion && <Numbers value={totales.Captacion} />}
                </div>
              </th>

              <th colSpan={2}>
                <div className="cReduced dataFont bigTotal">
                  <span className="dataSymbol">$</span>
                  <Numbers total={true} value={totales.UtilidadNeta} />
                </div>
              </th>
            </tr>
          </thead>
          <thead className="textFont headNoScroll">
            <tr>
              <th>departamento</th>
              <th>recaudación</th>
              <th>premios</th>
              <th>comsión</th>
              <th>utilidad bruta</th>
              <th>captación</th>
              <th>utilidad neta</th>
              {/**<th>%</th>**/}
            </tr>
          </thead>
          <tbody className="tableScroll">
            {data?.map((com, i) => {
              return (
                <React.Fragment key={`daily${i}`}>
                  <tr id={`daily${i}`}>
                    <td>
                      <div className="cReduced textFont" > <span
                        id={`arrow${com.Departamento ? com.Departamento : 'nada'}`}
                        className={`btIcon iconArrowLeft`}
                        onClick={() => VerDepartamento(com.Departamento ? com.Departamento : 'nada', i.toString())}
                      ></span>  {com.Departamento}</div>
                    </td>
                    <td>
                      <div className="cReduced dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={com.Recaudacion} />
                      </div>
                    </td>
                    <td>
                      <div className="cReduced dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={com.Premios} />
                      </div>
                    </td>
                    <td>
                      <div className="cReduced dataFont">
                        <span className="dataSymbol">$</span>
                        {com.Comision && <Numbers value={com.Comision} />}
                      </div>
                    </td>
                    <td>
                      <div className="cReduced dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={com.Utilidad} />
                      </div>
                    </td>
                    <td>
                      <div className="cReduced dataFont">
                        <span className="dataSymbol">$</span>
                        {com.Captacion && <Numbers value={com.Captacion} />}
                      </div>
                    </td>
                    <td>
                      <div className="cReduced dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={com.UtilidadNeta} />
                      </div>
                    </td>
                  </tr>
                  {com.localidades?.map((loc, index) => {
                    return (<tr key={`agregado${index}`} className={`agregado${i} subItemTable`}>
                      <td>
                        <div className='celda cReduced textFont'>{loc.Localidad}</div>
                      </td>
                      <td>
                        <div className="celda cReduced dataFont">
                          <span className="dataSymbol">$</span>
                          <Numbers value={loc.Recaudacion} />
                        </div>
                      </td>
                      <td>
                        <div className="celda cReduced dataFont">
                          <span className="dataSymbol">$</span>
                          <Numbers value={loc.Premios} />
                        </div>
                      </td>
                      <td>
                        <div className="celda cReduced dataFont">
                          <span className="dataSymbol">$</span>
                          {loc.Comision && <Numbers value={loc.Comision} />}
                        </div>
                      </td>
                      <td>
                        <div className="celda cReduced dataFont">
                          <span className="dataSymbol">$</span>
                          {loc.Utilidad && <Numbers value={loc.Utilidad} />}
                        </div>
                      </td>
                      <td>
                        <div className="celda cReduced dataFont">
                          <span className="dataSymbol">$</span>
                          {loc.Captacion && <Numbers value={loc.Captacion} />}
                        </div>
                      </td>
                      <td>
                        <div className="celda cReduced dataFont">
                          <span className="dataSymbol">$</span>
                          {loc.UtilidadNeta && <Numbers value={loc.UtilidadNeta} />}
                        </div>
                      </td>
                    </tr>);
                  })}
                </React.Fragment>);
            })}
          </tbody>
        </table>
      </div>

    </>
  );
}
