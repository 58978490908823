import CountUp from "react-countup";

interface NumbersProps {
  value: number;
  intenger?: boolean;
  total?: boolean;
}

const Max = (val: number): number => {
  return Math.abs(val) > 1000000000 ? val / 1000000 : val;
};

const isBig = (val: number): string => {
  return Math.abs(val) > 1000000000 ? "M" : "";
};

export default function Numbers(props: NumbersProps) {
  const Negative = (numb: number): string => {
    let clases = "";

    if (numb < 0) {
      clases = "Numbers negativeNumbers";
    } else {
      clases = "Numbers";
    }

    if (props.total === true) {
      clases = clases + " totalFinal";
    }
    return clases;
  };

  const isIntenger = (val: boolean | undefined): number => {
    // return val ? 0 : 2;
    if (val) {
      return 0;
    } else {
      if (Math.abs(props.value) > 1000000) {
        if (Math.abs(props.value) > 1000000000) {
          return 3;
        } else {
          return 0;
        }
      } else {
        return 2;
      }
    }
  };

  return (
    <>
      <CountUp
        className={Negative(props.value)}
        end={Max(props.value)}
        decimals={isIntenger(props.intenger)}
        decimal=","
        separator="."
        duration={1}
      />
      {isBig(props.value)}
    </>
  );
}
