import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Comercial } from "Functions/tableroApi";
import { dateCompProps, Graph} from "Models/models";
import { ParseYearMonthDay } from "Functions/functions";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options: ChartOptions = {
  maintainAspectRatio: true,
  responsive: true,

  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || "";

          if (label) {
            label += ": ";
          }
          if (context.parsed.y !== null) {
            label += new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(context.parsed.y);
          }
          return label;
        },
      },
    },
  },
  animations: {
    tension: {
      duration: 1000,
      easing: "linear",
      from: 0,
      to: 0.5,
      loop: false,
    },
  },
};

export default function GraphAnnual(props: dateCompProps) {
  const [data, setdata] = useState<Graph.ChartData>();
  const [years, setyears] = useState<number[]>([1990, 1991]);


  useEffect(() => {
    const DateSplit = ParseYearMonthDay(props.date);
    if (DateSplit !== undefined) {
      Comercial.getComercialAcumuladoAnual(
        DateSplit.day,
        DateSplit.month,
        DateSplit.year
      )
        .then((accDetailed) => {
          const etiquetasRight = [
            `Rec. ${accDetailed[1].Anio}`,
            `Rec. ${accDetailed[0].Anio}`,
            `Uti. ${accDetailed[1].Anio}`,
            `Uti. ${accDetailed[0].Anio}`,
          ];

          setyears([Number(accDetailed[1].Anio), Number(accDetailed[0].Anio)]);

          const dataArr = {
            labels: etiquetasRight,
            datasets: [
              {
                label: "Mensuales por año",
                data: [
                  accDetailed[1].Recaudacion,
                  accDetailed[0].Recaudacion,
                  accDetailed[1].Utilidad,
                  accDetailed[0].Utilidad,
                ],
                backgroundColor: [
                  "rgba(142, 214, 146, 0.5)",
                  "rgba(31, 209, 41, 0.5)",
                  "rgba(30, 148, 36, 0.5)",
                  "rgba(13, 101, 18, 0.5)",
                ],
                borderColor: [
                  "rgba(142, 214, 146, 1)",
                  "rgba(31, 209, 41, 1)",
                  "rgba(30, 148, 36, 1)",
                  "rgba(13, 101, 18, 1)",
                ],
                borderWidth: 1,
              },
            ],
          };
          setdata(dataArr);
          // console.log(dataArr);
        })
        .catch(() => {
          console.error("Error en la API");
        });
    }
  }, [props.date]);

  return (
    <>
      <div className="card cardGraphRight">
        <div className="inline">
          <div className="cardTitulo">
            <div className="icon "></div>Anual {years[1]} vs {years[0]}
          </div>
        </div>
        <div id="graphContainerRightTrag01">
          {data !== undefined && (
            <Bar
              id="graphContainerRightTrag01"
              options={options}
              data={data}
              height="240px"
              width="358px"
            />
          )}
        </div>
      </div>
    </>
  );
}
