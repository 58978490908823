import axios from "axios";
import { CONFIG } from "Functions/config";
import { getCookie } from "Functions/functions";

const { tableroWS } = CONFIG;
const api = axios.create({
  baseURL: tableroWS,
});

const getToken = () => {
  // const token = localStorage.getItem("token");
  const token =  getCookie('token');
  // console.log(token)
  return token;
};

const getAuthHeaders = () => {
  const token = getToken();
  const AuthStr = `Bearer ${token}`;
  const headers = {
    Authorization: AuthStr,
  };
  return headers;
};

/*
export const Token = (myToken?: string) => {
  const headers = getAuthHeaders();
  return {
    headers,
  };
};*/

/*export const config = {
  headers: getAuthHeaders(),
};
*/

export const config = () => {
  return {
    headers: getAuthHeaders(),
  };
}


export default api;