import { useContext } from "react";
import { GlobalStatesContext } from "Providers/context";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement("#yourAppElement");

export function TCModal() {
  const { state, setContext } = useContext(GlobalStatesContext);

  function afterOpenModal() {
    if (state.modal.afterOpen !== undefined) {
      state.modal.afterOpen();
    }
  }

  function closeModal() {
    setContext({ ...state, modal: { ...state.modal, show: false } });
  }

  function closeModalFunc() {
    if (state.modal.closeModal !== undefined) {
      state.modal.closeModal();
    }
  }

  return (
    <div className="">
      <Modal
        isOpen={state.modal.show}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Tablero de Comandos"
        ariaHideApp={false}
        id="modalStyle"
      >
        <div id="modalClose" onClick={closeModal}>x</div>
        <h2 className="tituloModal">{state.modal.title}</h2>
        <h4 className="subtituloModal">{state.modal.subtitle}</h4>

        {/* Ver esto, agrego clase para poder poner los extractos en horizontal, corroborar que no tenga conflictos en otro modal */}
        {/*className="comercialModalOrden"*/}
        <div>{state.modal.body}</div>
        {state.modal.button && (
          <button className="bt btText" id="btModal" onClick={closeModalFunc}>
            Aceptar
          </button>
        )}
      </Modal>
    </div>
  );
}
