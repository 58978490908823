import React, { ChangeEvent } from 'react';

interface UploadFormProps {
  onFileSelect: (file: File) => void;
}

const UploadForm: React.FC<UploadFormProps> = ({ onFileSelect }) => {
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;
    if (fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      onFileSelect(file);
    }
  };

  return (
    <form>
      <input type="file" onChange={handleFileChange} />
    </form>
  );
};

export default UploadForm;