import { lazy, useState, useContext } from "react";
import { UpdateData } from "Models/models";
import { GlobalStatesContext } from "Providers/context";


const PartialComercial = lazy(() => import('components/Dashboard/Partial/PartialComercial'));
const PartialCasinos = lazy(() => import('components/Dashboard/Partial/PartialCasinos'));
const PartialtragamonedasI = lazy(() => import('components/Dashboard/Partial/PartialtragamonedasI'));
const PartialtragamonedasC = lazy(() => import('components/Dashboard/Partial/PartialtragamonedasC'));
const PartialOnLine = lazy(() => import('components/Dashboard/Partial/PartialOnLine'));


export default function Partial() {
  const { state } = useContext(GlobalStatesContext);
  const [updateData, setupdateData] = useState<UpdateData>({
    fromDate: state.date,
    untilDate: state.date
  })

  const OnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setupdateData({
      ...updateData,
      [event.target.name]: event.target.value,
    })
  }

  return (
    <>
      <div className="containerLeftFull">
        <div className="tituloTablero">
          <div className="iconLarge"></div>Exportar Utilidades - Unidades de Negocio
        </div>
        <div className="contenedorPartial">
          <div className="filaPartial">
            <div className="itemPartial">
              <div className="SettingsSearchContainer">
                <div className="PartialColumm">
                  <div className="dataFechaSetting">fecha desde:
                    <span><input className="dataFecha" type="date" value={updateData.fromDate} name="fromDate" onChange={OnChange} /></span>
                  </div>
                </div>
                <div className="PartialColumm">
                  <div className="dataFechaSetting">fecha hasta:
                    <span><input className="dataFecha" type="date" value={updateData.untilDate} name="untilDate" onChange={OnChange} /></span>
                  </div>
                </div>

              </div>
            </div>
            <div className="itemPartial">
              <PartialComercial UpdateData={updateData} />
              <PartialCasinos UpdateData={updateData} />
              <PartialtragamonedasI UpdateData={updateData}/>
              <PartialtragamonedasC UpdateData={updateData}/>
              <PartialOnLine UpdateData={updateData}/>
            </div>
          </div>
    
        </div>

      </div>
    </>
  );
}
