import { TCDateType } from "Models/models";
import { decodeToken } from "react-jwt";

/**
 * @desc Get date split in yyyy/mm/dd
 * @param date string
 * @returns TCDateType
 */
export const ParseYearMonthDay = (date: string): TCDateType | undefined => {
  const dateSplited = date.split("-");
  if (dateSplited !== undefined) {
    const dateReturn: TCDateType = {
      day: Number(dateSplited[2]),
      month: Number(dateSplited[1]),
      year: Number(dateSplited[0]),
    };
    return dateReturn;
  } else {
    return undefined;
  }
};

/**
 *
 * @param number
 * @param width
 * @returns Numbers with x left 0
 */
export const zfill = (number: number, width: number): string => {
  const numberOutput = Math.abs(number); /* Valor absoluto del número */
  const length = number.toString().length; /* Largo del número */
  const zero = "0"; /* String de cero */

  if (width <= length) {
    if (number < 0) {
      return "-" + numberOutput.toString();
    } else {
      return numberOutput.toString();
    }
  } else {
    if (number < 0) {
      return "-" + zero.repeat(width - length) + numberOutput.toString();
    } else {
      return zero.repeat(width - length) + numberOutput.toString();
    }
  }
};

/**
 *
 * @param paramDate type Date
 * @returns string with Argetinian´s Dates
 */
export const jsDates = (paramDate: Date | undefined): string => {
  if (paramDate !== undefined) {
    const date = new Date(paramDate.toString());
    //return date.getFullYear()+'-' + (date.getMonth()+1) + '-'+date.getDate();
    return (
      zfill(date.getDate(), 2) +
      "/" +
      zfill(date.getMonth() + 1, 2) +
      "/" +
      zfill(date.getFullYear(), 4) +
      " | " +
      zfill(date.getHours(), 2) +
      ":" +
      zfill(date.getMinutes(), 2) +
      ":" +
      zfill(date.getSeconds(), 2)
    );
  } else {
    return "";
  }
};

export const onlyDate = (paramDate: Date | undefined): string => {
  if (paramDate !== undefined) {
    const date = new Date(paramDate.toString());
    //return date.getFullYear()+'-' + (date.getMonth()+1) + '-'+date.getDate();
    return (
      zfill(date.getFullYear(), 4) +
      zfill(date.getMonth() + 1, 2) +
      zfill(date.getDate(), 2)
    );
  } else {
    return "";
  }
};

/**
 *
 * @param dato1 : number
 * @param dato2  : number
 * @description Return % of dif between two numbers
 * @returns
 */
export const difPorc = (dato1: number, dato2: number): number => {
  const diferencia = dato1 - dato2;

  const porcentaje = (diferencia / dato2) * 100;

  return porcentaje;
};


export function getCookie(cookieName: string) {
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return ''; // Devuelve null si la cookie no se encuentra
}



interface jwtData {
  exp: number;
  data: string;
}
interface jwtDataUser {
  user: string;
  sistema: string;
  perfiles: unknown;
}

export function getUser(token: string) {
  const jwtData = decodeToken(token);
  if (jwtData === null) {
    console.log('aaa')
  } else {
    if (typeof jwtData == "object") {
      const data = jwtData as jwtData;
      return JSON.parse(data.data) as jwtDataUser;
    }
  }


}


// Función para borrar una cookie por su nombre
export function deleteCookie(cookieName: string) {
  // Establece la cookie con una fecha de expiración en el pasado
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export function createSecureCookie(cookieValue: string) {
  const cookieName = 'token';
  const expirationDays = 365;
  const d = new Date();
  d.setTime(d.getTime() + (expirationDays * 24 * 60 * 60 * 1000)); // Calcula la fecha de expiración

  const expires = "expires=" + d.toUTCString();
  const secureFlag = "Secure"; // Establece la bandera Secure para cookies seguras
  // const httpOnlyFlag = "HttpOnly"; // Establece la bandera HttpOnly para mayor seguridad
  const httpOnlyFlag = ""; // Establece la bandera HttpOnly para mayor seguridad
  document.cookie = `${cookieName}=${cookieValue}; ${expires}; path=/; ${secureFlag}; ${httpOnlyFlag};SameSite=Strict;`;

}

export function createSecureCookieHttp(cookieValue: string) {
  const cookieName = cookieValue;
  const expirationDays = 365;
  const d = new Date();
  d.setTime(d.getTime() + (expirationDays * 24 * 60 * 60 * 1000)); // Calcula la fecha de expiración

  const expires = "expires=" + d.toUTCString();
  const secureFlag = "Secure"; // Establece la bandera Secure para cookies seguras
  //const httpOnlyFlag = "HttpOnly"; // Establece la bandera HttpOnly para mayor seguridad
  const httpOnlyFlag = ""; // Establece la bandera HttpOnly para mayor seguridad
  // let httpOnlyFlag;

  //document.cookie = `${cookieName}=${cookieValue}; ${expires}; path=/; ${secureFlag}; ${httpOnlyFlag};`;
  document.cookie = `tablero=${cookieName}; ${expires}; path=/; ${secureFlag}; ${httpOnlyFlag};SameSite=Strict;`;
}