import CryptoJS from "crypto-js";
import { Security } from "Models/models";
import { CONFIG } from "./config";

/**
 *
 * @param toCrip: string
 * @desc This function encripts from a string
 * @returns
 */
const TripleDes = (toCrip: string) => {
  const key = CONFIG.secret || "";
  const cipher = CryptoJS.TripleDES.encrypt(
    toCrip,
    CryptoJS.enc.Utf8.parse(key),
    {
      mode: CryptoJS.mode.ECB,
    }
  );
  return cipher.toString();
};

/**
 *
 * @param user : string
 * @param pass : string
 * @desc This func encripts that object using TripleDes() and return a LoginType Object
 * @returns
 */
export const encriptar = (user: string, pass: string): Security.LoginType => {
  const Encriptado: Security.LoginType = {
    user: TripleDes(user),
    pass: TripleDes(pass),
    OU: TripleDes(CONFIG.OU || ""),
  };
  return Encriptado;
};
