import { useEffect, useState } from "react";
import { dateCompProps, flujoCajaDetallado } from "Models/models";
import { ParseYearMonthDay } from "Functions/functions";
import { getEstadoFlujoCajaDetallado } from "Functions/tableroApi";
import Numbers from "components/Numbers/Numbers";

export default function CashFlow(props: dateCompProps) {
  const [datos, setdatos] = useState<flujoCajaDetallado[]>();
  useEffect(() => {
    getEstadoFlujoCajaDetallado(
      Number(props.date.replace("-", "").replace("-", "")),
      1
    ).then((fc) => {
      setdatos(fc);
    });
  }, [props.date]);

  return (
    <div className="tablaContainer" id="tablaModal">
      <div className="cardTitulo cabeceraFija">
        <div className="icon "></div>Fecha de movimientos [
        {ParseYearMonthDay(props.date)?.day}/
        {ParseYearMonthDay(props.date)?.month}/
        {ParseYearMonthDay(props.date)?.year}]
      </div>
      <table className="tabla">
        <thead className="textFont cabeceraFija">
          <tr>
            <th>concepto</th>
            <th>Importe</th>
          </tr>
        </thead>
        <tbody>
          {datos?.map((item, i) => {
            return (
              <tr key={`flujodecaja${i}`}>
                <td>
                  <div className="celda textFont">{item.Concepto}</div>
                </td>
                <td>
                  <div className="celda dataFont">
                    <span className="dataSymbol">$</span>
                    <Numbers value={item.Importe} />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
