import React, { useState } from 'react';
import UploadForm from './UploadFile';
import { prespuesto } from "Functions/tableroApi";

const App: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileSelect = (file: File) => {
    setSelectedFile(file);
  };

  const handleUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);

      prespuesto.UploadBudget(formData).then( aa => {
        console.log(aa)
      })
    }
  };

  return (
    <div>
      <UploadForm onFileSelect={handleFileSelect} />
      <button onClick={handleUpload}>Upload</button>
    </div>
  );
};

export default App;