import { useEffect, useContext, useState } from "react";
import { GlobalStatesContext } from "Providers/context";
import { verExtractos } from "Functions/tableroApi";
import { Extracto } from "Models/models";

interface propsExtractos {
  Tipo: string;
}

export default function Daily(props: propsExtractos) {
  const { state } = useContext(GlobalStatesContext);
  const [Extractos, setExtractos] = useState<Extracto[]>();
  //const [cantidadLoterias, setCantidadLoterias] = useState<{cantidad: number, loteria: string}>({cantidad:1, loteria: ""});
  const [cantidadLoterias, setCantidadLoterias] = useState<string[]>();
  useEffect(() => {
    const fechaiso = Number(state.date.replace("-", "").replace("-", ""));

    verExtractos(Number(fechaiso), props.Tipo).then((resp) => {
      setExtractos(resp);

      //console.log("resp",resp);

      let nombre = resp[0].Nombre;
      const contadorLoterias: string[] = [];
      contadorLoterias.push(nombre);

      resp.forEach((item) => {
        if (nombre !== item.Nombre) {
          nombre = item.Nombre;
          contadorLoterias.push(nombre);
        }
      });
      setCantidadLoterias(contadorLoterias);
    });
  }, [props, state.date]);

  return (
    <>
      {cantidadLoterias &&
        cantidadLoterias.length > 0 &&
        filtrarExtractos(cantidadLoterias)}
    </>
  );

  function filtrarExtractos(cantidadLoterias: string[]) {
    const html: JSX.Element[] = [];

    cantidadLoterias?.map((item, i) => {
      html.push(
        <div className="comercialExtractos">
          <table className="tabla" id={`tabla${i}`}>
            <caption className="textFont">{item}</caption>
            <thead>
              <tr>
                <th>ubicacion</th>
                <th>numero</th>
              </tr>
            </thead>
            <tbody>{filtrarColumns(item)}</tbody>
          </table>
        </div>
      );
      return 1;
    });

    return html;
  }

  function filtrarColumns(item: string) {
    const html: JSX.Element[] = [];
    Extractos?.filter((Extracto) => {
      if (Extracto.Nombre === item)
        html.push(
          <tr>
            <td>
              <div className="celda dataFont">{Extracto.Ubicacion}</div>
            </td>
            <td>
              <div className="celda dataFont">{`${Extracto.valor.toString().padStart(4,'0')}`}</div>
            </td>
          </tr>
        );
        return 0;
    });

    return html;
  }
}
