/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import { Presupuesto } from "Models/models";
import { prespuesto } from "Functions/tableroApi";
import Numbers from "components/Numbers/Numbers";

interface ResourcesDetailsProps {
  concepto: string;
  date: string;
}

export default function ResourcesDetails(props: ResourcesDetailsProps) {
  const [datos, setdatos] = useState<Presupuesto.RecursosDetalle[]>();

  useEffect(() => {
    prespuesto
      .getRecursosDetalle(Number(props.date.replace("-", "").replace("-", "")))
      .then((detail) => {
        setdatos(detail);
      });
  }, [props.date]);

  return (
    <>
      {datos?.map((item, i) => {
        if (item.concepto === props.concepto)
          return (
            <>
              <table className="tabla">
                <thead className="cardTitulo">
                  <th>Descripcion</th>
                  <th>Presupuestado</th>
                  <th>Devengado</th>
                  <th>%</th>
                </thead>
                <tbody>
                  <tr key={`tablerecursos${i}`}>
                    <td>{item.descripcion}</td>
                    <td>
                      <Numbers value={item.presup}></Numbers>
                    </td>
                    <td>
                      <Numbers value={item.recaudado}></Numbers>
                    </td>
                    <td>
                      <Numbers value={(item.recaudado * 100 ) / item.presup} intenger={true}></Numbers>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          );
      })}
    </>
  );
}
