import { Fragment, useEffect, useState } from "react";
import {
  dateCompProps,
  ContributionsData,
  ContributionsDataDetails,
} from "Models/models";
import { getAportes, getAportesDetallado } from "Functions/tableroApi";
import { ParseYearMonthDay } from "Functions/functions";
import Numbers from "components/Numbers/Numbers";

export default function ContributionsDetails(
  props: dateCompProps
): JSX.Element {
  const [contributions, setcontributions] = useState<ContributionsData[]>();
  const [contributionsDetalis, setcontributionsDetalis] =
    useState<ContributionsDataDetails[]>();
  const [totalesT, settotalesT] = useState<ContributionsData>({
    A1: 0,
    A2: 0,
    A3: 0,
    A4: 0,
    A5: 0,
    Orden: 0,
    Total: "",
  });

  useEffect(() => {
    const Fecha = ParseYearMonthDay(props.date);
    if (Fecha?.year !== undefined) {
      getAportes(Fecha.year).then((data) => {
        setcontributions(data);

        if (data !== undefined) {
          data.map((dat) => {
            settotalesT({
              A1: dat.A1,
              A2: dat.A2,
              A3: dat.A3,
              A4: dat.A4,
              A5: dat.A5,
              Orden: 0,
              Total: "",
            });
            return 0;
            // console.log(dat);
          });
        }
      });
      getAportesDetallado(Fecha.year, Fecha.month).then((details) => {
        setcontributionsDetalis(details);
        // setsizeD(details.length);
      });
    }
  }, [props.date]);

  return (
    <>
      <table className="tabla fixContributions">
        {contributionsDetalis?.length !== 0 && (
          <>
            <thead>
              <tr className="cardTotales" key={"totalesmenusales"}>
                <th>
                  <div className="celda dataFont smallTotal">
                    detalle mensual [{ParseYearMonthDay(props.date)?.month}/
                    {ParseYearMonthDay(props.date)?.year}]
                  </div>
                </th>
              </tr>
            </thead>
            <thead className="textFont">
              <tr>
                <th>destino</th>
                <th>Importe</th>
              </tr>
            </thead>
          </>
        )}

        <tbody>
          {contributionsDetalis?.map((item, i) => {
            return (
              <tr key={`detaliscontrib${i}`}>
                <td>
                  <div className="celda dataFont ">{item.Destino}</div>
                </td>
                <td>
                  <div className="celda dataFont ">
                    <span className="dataSymbol">$</span>
                    <Numbers value={item.Importe}></Numbers>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <table className="tabla">
        <thead>
          <tr className="cardTotales" key={"totales"}>
            <th>
              <div className="celda dataFont smallTotal">total anualizado</div>
            </th>
            <th>
              <div className="celda dataFont smallTotal">
                <span className="dataSymbol">$</span>
                <Numbers value={totalesT?.A1} />
              </div>
            </th>
            <th>
              <div className="celda dataFont smallTotal">
                <span className="dataSymbol">$</span>
                <Numbers value={totalesT?.A2} />
              </div>
            </th>
            <th>
              <div className="celda dataFont smallTotal">
                <span className="dataSymbol">$</span>
                <Numbers value={totalesT?.A3} />
              </div>
            </th>
            <th>
              <div className="celda dataFont smallTotal">
                <span className="dataSymbol">$</span>
                <Numbers value={totalesT?.A4} />
              </div>
            </th>
            <th colSpan={2}>
              <div className="celda dataFont bigTotal">
                <span className="dataSymbol">$</span>
                <Numbers value={totalesT?.A5} />
              </div>
            </th>
          </tr>
        </thead>
        <thead className="textFont">
          <tr key={"etiquetas"}>
            {contributions && (
              <>
                <th>{contributions[0].Total}</th>
                <th>{contributions[0].A1}</th>
                <th>{contributions[0].A2}</th>
                <th>{contributions[0].A3}</th>
                <th>{contributions[0].A4}</th>
                <th>{contributions[0].A5}</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {contributions?.map((item, i) => {
            return (
              <Fragment key={`conrib${i}`}>
                {item.Total !== "Destino" && item.Total !== "Total" && (
                  <tr>
                    <td>
                      <span className="celda dataFont">{item.Total}</span>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={item.A1}></Numbers>
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={item.A2}></Numbers>
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={item.A3}></Numbers>
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={item.A4}></Numbers>
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={item.A5}></Numbers>
                      </div>
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
