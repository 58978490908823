import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Casinos } from "Functions/tableroApi";
import { dateCompProps, Graph } from "Models/models";
import { ParseYearMonthDay } from "Functions/functions";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options: ChartOptions<'bar'> = {
  maintainAspectRatio: true,
  responsive: true,

  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || "";

          if (label) {
            label += ": ";
          }
          if (context.parsed.y !== null) {
            label += new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(context.parsed.y);
          }
          return label;
        },
      },
    },
  },
  animations: {
    tension: {
      duration: 1000,
      easing: "linear",
      from: 0,
      to: 0.5,
      loop: false,
    },
  },
};

export default function GraphCasino(props: dateCompProps) {
  const [data, setdata] = useState<Graph.ChartData>();
  const [source, setsource] = useState(true);
  const [daily, setdaily] = useState(true);

  useEffect(() => {
    const DateSplit = ParseYearMonthDay(props.date);
    if (DateSplit !== undefined) {
      Casinos.getCasinosDiaria(
        DateSplit.day,
        DateSplit.month,
        DateSplit.year
      ).then((cas) => {
        const Etiquetas: string[] = [];
        const Utilidades: number[] = [];
        const Recaudacion: number[] = [];
        const UtilAcum: number[] = [];
        const RecAcum: number[] = [];
        cas.forEach((casData) => {
          Etiquetas.push(casData.CasinoNombre);
          Utilidades.push(casData.Utilidad);
          Recaudacion.push(casData.Recaudacion);
          if (casData.Acumulado) {
            UtilAcum.push(casData.Acumulado);
          }
          if (casData.RecAcumulado) {
            UtilAcum.push(casData.RecAcumulado);
          }
        });

        let SourceData: number[];
        if (source) {
          if (daily) {
            SourceData = Recaudacion;
          } else {
            SourceData = RecAcum;
          }
        } else {
          if (daily) {
            SourceData = Utilidades;
          } else {
            SourceData = UtilAcum;
          }
        }
        const dataArr = {
          labels: Etiquetas,
          datasets: [
            {
              label: "Mensuales por año",
              data: SourceData,
              backgroundColor: [
                "rgba(255, 199, 0, 0.5)",
                "rgba(255, 231, 102, 0.5)",
                "rgba(142, 214, 146, 0.5)",
                "rgba(31, 209, 41, 0.5)",
                "rgba(30, 148, 36, 0.5)",
                "rgba(13, 101, 18, 0.5)",
                "rgba(0, 87, 168, 0.5)",
                "rgba(21, 143, 255, 0.5)",
                "rgba(160, 210, 255, 0.5)",
              ],
              borderColor: [
                "rgba(255, 199, 0, 1)",
                "rgba(255, 231, 102, 1)",
                "rgba(142, 214, 146, 1)",
                "rgba(31, 209, 41, 1)",
                "rgba(30, 148, 36, 1)",
                "rgba(13, 101, 18, 1)",
                "rgba(0, 87, 168, 1)",
                "rgba(21, 143, 255, 1)",
                "rgba(160, 210, 255, 1)",
              ],
              borderWidth: 1,
            },
          ],
        };
        setdata(dataArr);
      });
    }
  }, [props.date, source, daily]);

  return (
    <>
      <div className="inline">
        <div className="cardTitulo">
          <div className="icon "></div>

          <div id='switches'>
            <div id='switchLeft'>
              <div className={
                daily ? "bt btText switchActive" : "bt btText switchInactive"
              }
                onClick={() => setdaily(!daily)}
              >
                {" "}
                diaria
              </div>
              <div>
                <div
                  className={
                    daily ? "bt btText switchInactive" : "bt btText switchActive"
                  }
                  onClick={() => setdaily(!daily)}
                >
                  {" "}
                  mensual
                </div>

              </div>
            </div>
            <div id='switchRight'>
              <div
                className={
                  source ? "bt btText switchActive" : "bt btText switchInactive"
                }
                onClick={() => setsource(!source)}
              >
                {" "}
                recaudación
              </div>
              <div
                className={
                  source ? "bt btText switchInactive" : "bt btText switchActive"
                }
                onClick={() => setsource(!source)}
              >
                utilidades
              </div>
            </div>
          </div>

        </div>


      </div>
      <div id="graphContainerCas2">
        {data !== undefined && (
          <Bar id="graphContainerCas2" options={options} data={data} />
        )}
      </div>
    </>
  );
}
