import { useEffect, useState } from "react";
import { getPrincipalAcumulados } from "Functions/tableroApi";
import { ParseYearMonthDay } from "Functions/functions";
import { PricipalData, dateCompProps } from "Models/models";
import Numbers from "components/Numbers/Numbers";
import TrafficLight from "components/TrafficLight/TrafficLight";

export default function Accumulated(props: dateCompProps) {
  const [dataPrinpipalTotal, setdataPrinpipalTotal] = useState<PricipalData>({
    Comision: 0,
    Nombre: "",
    ORDEN: 0,
    Premios: 0,
    Recaudacion: 0,
    Utilidad: 0,
    UtilidadNeta: 0,
  });
  const [dataAcumm, setdataAcumm] = useState<PricipalData[]>();

  useEffect(() => {
    const DateSplit = ParseYearMonthDay(props.date);
    if (DateSplit !== undefined) {
      getPrincipalAcumulados(
        DateSplit.day,
        DateSplit.month,
        DateSplit.year
      ).then((acum) => {
        setdataAcumm(acum);
        let DataRec = 0;
        let DataCom = 0;
        let DataPre = 0;
        let DataUti = 0;
        let DataNet = 0;
        acum.forEach((sub) => {
          if (sub.Recaudacion !== null) DataRec = DataRec + sub.Recaudacion;
          if (sub.Comision !== null) DataCom = DataCom + sub.Comision;
          if (sub.Premios !== null) DataPre = DataPre + sub.Premios;
          if (sub.Utilidad !== null) DataUti = DataUti + sub.Utilidad;
          if (sub.UtilidadNeta !== null) DataNet = DataNet + sub.UtilidadNeta;
        });
        setdataPrinpipalTotal({
          ...dataPrinpipalTotal,
          Recaudacion: DataRec,
          Comision: DataCom,
          Premios: DataPre,
          Utilidad: DataUti,
          UtilidadNeta: DataNet,
        });
      }).catch(() => {
        console.error('No esta corriendo la API');
        // window.location.replace("/login");
      });
    }
  // react-hooks/exhaustive-deps
  }, [props.date]);

  return (
    <>
      <div className="card">
        <div className="tablaContainer">
          <div className="cardTitulo">
            <div className="icon "></div>TOTALES ACUMULADOS [
            {ParseYearMonthDay(props.date)?.day}/
            {ParseYearMonthDay(props.date)?.month}/
            {ParseYearMonthDay(props.date)?.year}]
          </div>
          <table className="tabla">
            <thead>
              <tr className="cardTotales">
                <th>
                  <div className="celda dataFont smallTotal"></div>
                </th>
                <th>
                  <div className="celda dataFont smallTotal">
                    <span className="dataSymbol">$</span>
                    <Numbers value={dataPrinpipalTotal.Recaudacion} />
                  </div>
                </th>
                <th>
                  <div className="celda dataFont smallTotal">
                    <span className="dataSymbol">$</span>
                    <Numbers value={dataPrinpipalTotal.Comision} />
                  </div>
                </th>
                <th>
                  <div className="celda dataFont smallTotal">
                    <span className="dataSymbol">$</span>
                    <Numbers value={dataPrinpipalTotal.Premios} />
                  </div>
                </th>
                <th>
                  <div className="celda dataFont smallTotal">
                    <span className="dataSymbol">$</span>
                    <Numbers value={dataPrinpipalTotal.Utilidad} />
                  </div>
                </th>
                <th colSpan={2}>
                  <div className="celda dataFont bigTotal">
                    <span className="dataSymbol">$</span>
                    <Numbers total={true} value={dataPrinpipalTotal.UtilidadNeta} />
                  </div>
                </th>
              </tr>
            </thead>
            <thead className="textFont">
              <tr>
                <th>unidad</th>
                <th>recaudación</th>
                <th>comisión</th>
                <th>premios</th>
                <th>utilidad bruta</th>
                <th>utilidad neta</th>
                <th>%</th>
              </tr>
            </thead>
            <tbody>
              {dataAcumm !== undefined &&
                dataAcumm.map((data, i) => {
                  const porc = (data.Utilidad / data.Recaudacion) * 100;
                  if (dataAcumm !== undefined) {
                    if (data.Nombre === "TOTAL Casinos") {
                      data.UtilidadNeta = data.Utilidad;
                    }
                  }

                  return (
                    <tr key={i}>
                      <td>
                        <div className="celda textFont">
                          <div
                            className={
                              "icon icon" +
                              data.Nombre.split(" ")
                                .join("")
                                .split("TOTAL")
                                .join("")
                            }
                          ></div>
                          {data.Nombre}
                        </div>
                      </td>
                      <td>
                        <div className="celda dataFont">
                          <span className="dataSymbol">$</span>
                          <Numbers value={data.Recaudacion} />
                        </div>
                      </td>
                      <td>
                        <div className="celda dataFont">
                          <span className="dataSymbol">$</span>
                          <Numbers value={data.Comision} />
                        </div>
                      </td>
                      <td>
                        <div className="celda dataFont">
                          <span className="dataSymbol">$</span>
                          <Numbers value={data.Premios} />
                        </div>
                      </td>
                      <td>
                        <div className="celda dataFont">
                          <span className="dataSymbol">$</span>

                          <Numbers value={data.Utilidad} />
                        </div>
                      </td>
                      <td>
                        <div className="celda dataFont">
                          <span className="dataSymbol">$</span>

                          <Numbers value={data.UtilidadNeta} />
                        </div>
                      </td>
                      <td className="celda dataFont porcentaje">
                        <TrafficLight value={porc} />
                      </td>
                    </tr>
                  );
                })}
              {dataAcumm === undefined && (
                <>
                  <tr key="PrincipalWithoutData1">
                    <td>
                      <div className="celda textFont skeleton-loader"></div>
                    </td>
                    <td>
                      <div className="celda textFont skeleton-loader"></div>
                    </td>
                    <td>
                      <div className="celda textFont skeleton-loader"></div>
                    </td>
                    <td>
                      <div className="celda textFont skeleton-loader"></div>
                    </td>
                    <td>
                      <div className="celda textFont skeleton-loader"></div>
                    </td>
                    <td>
                      <div className="celda textFont skeleton-loader"></div>
                    </td>
                    <td>
                      <div className="celda textFont skeleton-loader"></div>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
