import { createContext, Dispatch, SetStateAction, useState, useContext } from "react";
import { Security } from "Models/models";
import { getCookie, getUser } from "Functions/functions";

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(yesterday.getDate() - 1);

type Props = {
  children: React.ReactNode;
};

interface TSpinner {
  loading: boolean;
}

export type StateTC = {
  user: string;
  token: string | null;
  toast: StateToast;
  modal: TCModal;
  spinner: TSpinner;
  profiles?: Security.PerfilesResponse[];
  date: string;
};

export type StateToast = {
  text: string;
  show: boolean;
};

export interface TCModal {
  body: JSX.Element;
  subtitle: string;
  title: string;
  show: boolean;
  button: boolean;
  afterOpen?: () => void;
  closeModal?: () => void;
}

export type Context = {
  state: StateTC;
  setContext: Dispatch<SetStateAction<StateTC>>;
};


// const storagetoken = localStorage.getItem("token");
const storagetoken = getCookie('token');

let user: string = '';
if (storagetoken !== undefined) {
  const tokenDecodificado = getUser(storagetoken);
  if (tokenDecodificado) {
    user = tokenDecodificado.user
  }
}


const initialUser: StateTC = {
  user: user,
  token: storagetoken,
  toast: { text: "", show: false },
  modal: { body: <></>, subtitle: "", title: "", show: false , button: true},
  date: yesterday.toISOString().slice(0,10),
  spinner: {loading: false}
};

const initialContext: Context = {
  state: initialUser,
  setContext: (): void => {
    throw new Error("setContext function must be overridden");
  },
};

const GlobalStatesContext = createContext<Context>(initialContext);

const GlobalStatesProvider = ({ children }: Props): JSX.Element => {
  const [state, setContext] = useState<StateTC>(initialUser);

  return (
    <GlobalStatesContext.Provider value={{ state, setContext }}>
      {children}
    </GlobalStatesContext.Provider>
  );
};

export { GlobalStatesContext, GlobalStatesProvider };

export const useAuth = () => {
  return useContext(GlobalStatesContext);
};