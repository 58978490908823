import React, { useEffect, useState, useContext } from "react";
import { OnLine } from "Functions/tableroApi";
import { dateCompProps, PricipalData } from "Models/models";
import { ParseYearMonthDay } from "Functions/functions";
import Numbers from "components/Numbers/Numbers";
import TrafficLight from "components/TrafficLight/TrafficLight";
import { GlobalStatesContext } from "Providers/context";

export default function Daily(props: dateCompProps) {
  const { state, setContext } = useContext(GlobalStatesContext);
  const [data, setdata] = useState<PricipalData[]>();
  const [data2, setdata2] = useState<PricipalData[]>();
  const [totales, settotales] = useState<PricipalData>({
    Recaudacion: 0,
    Utilidad: 0,
    Nombre: "",
    Comision: 0,
    Premios: 0,
    UtilidadNeta: 0,
  });

  useEffect(() => {
    setContext({ ...state, spinner: { loading: true } });
    const DateSplit = ParseYearMonthDay(props.date);
    OnLine.getOnLineDiaria(
      Number(DateSplit?.day),
      Number(DateSplit?.month),
      Number(DateSplit?.year)
    )
      .then((data) => {
        setdata(data);
        let DataRec = 0;
        let DataPre = 0;
        let DataUti = 0;
        let DataUtiNeta = 0;
        let DataCom = 0;

        data.forEach((sub) => {
          if (sub.Recaudacion !== null) DataRec = DataRec + sub.Recaudacion;
          if (sub.Comision !== null) DataCom = DataCom + sub.Comision;
          if (sub.Premios !== null) DataPre = DataPre + sub.Premios;
          if (sub.Utilidad !== null) DataUti = DataUti + sub.Utilidad;
          if (sub.UtilidadNeta !== null)
            DataUtiNeta = DataUtiNeta + sub.UtilidadNeta;
        });
        settotales({
          ...totales,
          Recaudacion: DataRec,
          Comision: DataCom,
          Premios: DataPre,
          Utilidad: DataUti,
          UtilidadNeta: DataUtiNeta,
        });

        OnLine.getDiariaBplay(
          Number(DateSplit?.day),
          Number(DateSplit?.month),
          Number(DateSplit?.year)
        ).then(bp => {
          setdata2(bp)
        });
      })
      .catch(() => {
        setContext({
          ...state,
          toast: {
            text: "Hubo un problema al conectar con el WS.",
            show: true,
          },
        });
        console.log("No esta corriendo la API");
      }).finally(() => {
        setContext({ ...state, spinner: { loading: false } });
      })

  }, [props.date]);

  return (
    <div className="tablaContainer">
      <div className="cardTitulo">
        <div className="icon "></div>TOTALES DIARIOS [
        {ParseYearMonthDay(props.date)?.day}/
        {ParseYearMonthDay(props.date)?.month}/
        {ParseYearMonthDay(props.date)?.year}]
      </div>
      <table className="tabla">
        <thead>
          <tr className="cardTotales">
            <th>
              <div className="celda dataFont smallTotal">total</div>
            </th>
            <th>
              <div className="celda dataFont smallTotal">
                <span className="dataSymbol">$</span>
                <Numbers value={totales.Recaudacion} />
              </div>
            </th>
            <th>
              <div className="celda dataFont smallTotal">
                <span className="dataSymbol">$</span>
                <Numbers value={totales.Premios} />
              </div>
            </th>
            <th>
              <div className="celda dataFont smallTotal">
                <span className="dataSymbol">$</span>
                <Numbers value={totales.Utilidad} />
              </div>
            </th>
            <th>
              <div className="celda dataFont smallTotal">
                <span className="dataSymbol">$</span>
                <Numbers value={totales.Comision} />
              </div>
            </th>
            <th>
              <div className="celda dataFont smallTotal">
                <span className="dataSymbol">$</span>
                <Numbers total={true} value={totales.UtilidadNeta} />
              </div>
            </th>
            <th>
              <div className="celda dataFont smallTotal porcentaje">
                <Numbers
                  value={(totales.Utilidad / totales.Recaudacion) * 100}
                />
                <span>%</span>
              </div>
            </th>
          </tr>
        </thead>
        <thead className="textFont">
          <tr>
            <th>nombre</th>
            <th>recaudacion</th>
            <th>premios</th>
            <th>utilidad</th>
            <th>captación</th>
            <th>utilidad neta</th>
            <th>%</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((on, i) => {
            if (on.Nombre === 'Juego OnLine BPlay') {
              return (
                <React.Fragment key={`daily${i}`}>
                  <tr >
                    <td>
                      <div className="celda textFont">{on.Nombre}</div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={on.Recaudacion} />
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={on.Premios} />
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={on.Utilidad} />
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={on.Comision} />
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={on.UtilidadNeta} />
                      </div>
                    </td>
                    <td className="celda dataFont porcentaje">
                      <TrafficLight value={(on.Utilidad / on.Recaudacion) * 100} />
                    </td>
                  </tr>
                  <tr className="subItemTable">
                    {data2?.map((bp, i) => {
                      return (
                        <React.Fragment key={i}>
                          <td>
                            <div className="celda textFont">{bp.Nombre}</div>
                          </td>
                          <td>
                            <div className="celda dataFont">
                              <span className="dataSymbol">$</span>
                              <Numbers value={bp.Utilidad} />
                            </div>
                          </td>

                        </React.Fragment>
                      )
                    })}
                  </tr>
                </React.Fragment>
              );
            }
            else {
              return (
                
                  <tr key={`daily2${i}`}>
                    <td>
                      <div className="celda textFont">{on.Nombre}</div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={on.Recaudacion} />
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={on.Premios} />
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={on.Utilidad} />
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={on.Comision} />
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={on.UtilidadNeta} />
                      </div>
                    </td>
                    <td className="celda dataFont porcentaje">
                      <TrafficLight value={(on.Utilidad / on.Recaudacion) * 100} />
                    </td>
                  </tr>

                
              );
            }
          })}
        </tbody>
      </table>
    </div>
  );
}
