import { useEffect, useState, useContext } from "react";
import { PotencialesAPI } from "Functions/tableroApi";
import { Potenciales, dateCompProps } from "Models/models";
import { ParseYearMonthDay } from "Functions/functions";
import Numbers from "components/Numbers/Numbers";
import { GlobalStatesContext } from "Providers/context";

export default function AgenciasMasTicketVendidos(props: dateCompProps) {
  const [AgenciasMasTicketVendidos, setAgenciasMasTicketVendidos] =
    useState<Potenciales.AgenciasMasTicketVendidos[]>();
  const { state, setContext } = useContext(GlobalStatesContext);

  useEffect(() => {
    setContext({ ...state, spinner: { loading: true } });
    const DateSplit = ParseYearMonthDay(props.date);
    PotencialesAPI.getAgenciasMasTicketVendidos(
      Number(DateSplit?.day),
      Number(DateSplit?.month),
      Number(DateSplit?.year)
    ).then((data) => {
      setAgenciasMasTicketVendidos(data);
    }).finally(() => {
      setContext({ ...state, spinner: { loading: false } });
    })
  }, [props.date]);

  return (
    <>
      <div className="tablaContainerComercialXS" id="potentialTablaFirst">
        <div className="cardTitulo">
          <div className="icon "></div>Agencias con mas Ticket Vendidos [
          {ParseYearMonthDay(props.date)?.day}/
          {ParseYearMonthDay(props.date)?.month}/
          {ParseYearMonthDay(props.date)?.year}]
        </div>
        <table className="tabla" id="potenciales">
          <thead className="textFont">
            <tr>
              <th>cantidad</th>
              <th>agencia</th>
              <th>localidad</th>
            </tr>
          </thead>
          <tbody>
            {AgenciasMasTicketVendidos?.map((item, i) => {
              return (
                <tr key={`agenciasmasticketsvendidos${i}`}>
                  <td>
                    <div className="celda celdaCenter textFont">
                      <Numbers value={item.Cantidad} intenger={true} />
                    </div>
                  </td>
                  <td>
                    <div className="celda celdaCenter textFont">{item.Agencia}</div>
                  </td>
                  <td>
                    <div className="celda textFont">{item.Localidad}</div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
