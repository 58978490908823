import { useEffect, useState } from "react";
import { Comercial } from "Functions/tableroApi";
import { dateCompProps, dataUtilidadesComparativos } from "Models/models";
import { ParseYearMonthDay, difPorc } from "Functions/functions";
import Numbers from "components/Numbers/Numbers";
import TrafficLight from "components/TrafficLight/TrafficLight";

export default function AnnualAccumulated(props: dateCompProps) {
  const [data, setdata] = useState<dataUtilidadesComparativos[]>();
  const [dif, setdif] = useState<dataUtilidadesComparativos>({
    Mes: "Dif",
    Comision: 0,
    Recaudacion: 0,
    Premios: 0,
    Utilidad: 0,
    UtilidadNeta: 0,
  });
  useEffect(() => {
    const DateSplit = ParseYearMonthDay(props.date);
    Comercial.getComercialAcumuladoAnual(
      Number(DateSplit?.day),
      Number(DateSplit?.month),
      Number(DateSplit?.year)
    )
      .then((com) => {
        setdata(com);
        let DataRec = 0;
        let DataPre = 0;
        let DataUti = 0;
        let DataNet = 0;
        com.forEach((sub) => {
          if (sub.Recaudacion !== null) DataRec = sub.Recaudacion - DataRec;
          if (sub.Premios !== null) DataPre = sub.Premios - DataPre;
          if (sub.Utilidad !== null) DataUti = sub.Utilidad - DataUti;
          if (sub.UtilidadNeta !== null) DataNet = sub.UtilidadNeta - DataNet;
        });
        setdif({
          ...dif,
          Recaudacion: DataRec * -1,
          Premios: DataPre * -1,
          Utilidad: DataUti * -1,
          UtilidadNeta: DataNet * -1,
        });
      })
      .catch(() => {
        console.error("Error en la API");
      });

  }, [props.date]);

  return (
    <div className="card cardTragRight">
      <div className="cardTitulo">
        <div className="icon"></div>ACUMULADO ANUAL VS ACUMULADO ANUAL ANTERIOR
        [{ParseYearMonthDay(props.date)?.day}/
        {ParseYearMonthDay(props.date)?.month}/
        {ParseYearMonthDay(props.date)?.year}]
      </div>
      <div className="tablaContainerRight">
        <table className="tabla tablaSmall">
          <thead>
            <tr className="cardTotales">
              <th>
                <div className="celda dataFont smallTotal">dif.</div>
              </th>
              <th>
                <div className="celda dataFont smallTotal">
                  <span className="dataSymbol">$</span>
                  <Numbers value={dif.Recaudacion} />
                </div>
              </th>
              <th>
                <div className="celda dataFont smallTotal">
                  <span className="dataSymbol">$</span>
                  <Numbers value={dif.Premios} />
                </div>
              </th>
              <th>
                <div className="celda dataFont smallTotal">
                  <span className="dataSymbol">$</span>
                  <Numbers value={dif.Utilidad} />
                </div>
              </th>
              <th>
                <div className="celda dataFont smallTotal porcentaje">
                  {data !== undefined && (
                    <Numbers
                      value={difPorc(data[0]?.Utilidad, data[1]?.Utilidad)}
                    />
                  )}
                  <span>%</span>
                </div>
              </th>
              <th>
                <div className="celda dataFont bigTotal">
                  <span className="dataSymbol">$</span>
                  <Numbers total={true} value={dif.UtilidadNeta} />
                </div>
              </th>
            </tr>
          </thead>
          <thead className="textFont">
            <tr>
              <th>año</th>
              <th>recaudación</th>
              <th>premios</th>
              <th>utilidad bruta</th>
              <th>%</th>
              <th>utilidad neta</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((comp, i) => {
              const Porcentaje = (comp.Utilidad / comp.Recaudacion) * 100;
              return (
                <tr key={`comparativosmensual${i}`}>
                  <td>
                    <div className="celda textFont">
                      {comp.Anio}
                    </div>
                  </td>
                  <td>
                    <div className="celda dataFont">
                      <span className="dataSymbol">$</span>
                      <Numbers value={comp.Recaudacion} />
                    </div>
                  </td>
                  <td>
                    <div className="celda dataFont">
                      <span className="dataSymbol">$</span>
                      <Numbers value={comp.Premios} />
                    </div>
                  </td>
                  <td>
                    <div className="celda dataFont">
                      <span className="dataSymbol">$</span>
                      <Numbers value={comp.Utilidad} />
                    </div>
                  </td>
                  <td className="celda dataFont porcentaje">
                    <TrafficLight value={Porcentaje} />
                  </td>
                  <td>
                    <div className="celda dataFont">
                      <span className="dataSymbol">$</span>
                      <Numbers value={comp.UtilidadNeta} />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
