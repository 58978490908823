import { useContext } from "react";
import { GlobalStatesContext } from "Providers/context";
import Engranaje from "img/engranaje.png";

export default function Spinner(): JSX.Element {
  const { state } = useContext(GlobalStatesContext);

  return (<>
    {state.spinner.loading && <div className="engranaje rotating"><img src={Engranaje} alt="Cargando"></img></div>}
    {!state.spinner.loading && <div className="engranaje"><img src={Engranaje} alt="ropos"></img></div>}
  </>);
}
