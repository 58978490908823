import { useEffect, useContext } from "react";
import { GlobalStatesContext } from "Providers/context";
import { useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();
  const { state } = useContext(GlobalStatesContext);

  useEffect(() => {
    setTimeout(function () {}, 5000);
    if (state.token == null) {
      setTimeout(function () {
        navigate(`/login/`);
      }, 5000);
    } else {
      setTimeout(function () {
        navigate(`/dashboard`);
      }, 2000);
    }
  }, [navigate, state.token]);

  return (
      <div className="bgIAFASGreen">
        <div className="splashContainer">
          <img
            className="splashLogo"
            src={require("./img/TC_logo.png")}
            alt="Logo IAFAS"
          />
        </div>
      </div>
  );
}

export default App;
