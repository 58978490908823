import { useContext } from "react";
import { GlobalStatesContext } from "Providers/context";
import Daily from "components/Dashboard/OnLine/Daily";
import Cash from "components/Dashboard/OnLine/Cash";
//import Retiro from "components/Dashboard/OnLine/Retiro";
import MonthlyComparisons from "components/Dashboard/OnLine/MonthlyComparisons";
import MonthyComparasonsForYears from "components/Dashboard/OnLine/MonthyComparasonsForYears";
import AnnualAccumulated from "components/Dashboard/OnLine/AnnualAccumulated";
import GraphMonthy from "components/Dashboard/OnLine/GraphMonthy";
import GraphAnnual from "components/Dashboard/OnLine/GraphAnnual";
import IO from "components/Dashboard/OnLine/IO";

export default function OnLine() {
  const { state } = useContext(GlobalStatesContext);

  return (
    <>
      <div className="containerLeft reduced">
        <div className="tituloTablero">
          <div className="iconLarge"></div>OnLine
        </div>
        <div className="card cardsOnline" id="cardCash1">
          <Daily date={state.date} />
        </div>
        <div className="cardsVerticales">
          <div className="card cardsOnline" id="cardCash3">
            <IO date={state.date} />
          </div>
          <div className="card cardsOnline" id="cardCash2">
            <Cash date={state.date} />
          </div>
        </div>

      </div>
      <div className="containerRight expanded">
        <MonthlyComparisons date={state.date} />
        <MonthyComparasonsForYears date={state.date} />
        <AnnualAccumulated date={state.date} />
        <div className="containerCardGraphRight">
          <GraphMonthy date={state.date} />
          <GraphAnnual date={state.date} />
        </div>
      </div>
    </>
  );
}
