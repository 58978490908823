import { lazy } from "react";

 /*
import Users from "components/Dashboard/Setting/Users";
import Info from "components/Dashboard/Setting/Info";
import Systemhealth from "components/Dashboard/Setting/Systemhealth/Systemhealth";
*/


const Users = lazy(() => import('components/Dashboard/Setting/Users'));
const Info = lazy(() => import('components/Dashboard/Setting/Info'));
const Systemhealth = lazy(() => import('components/Dashboard/Setting/Systemhealth/Systemhealth'));

export default function Setting() {


  return (
    <>
      <div className="containerSettingL">
        <div className="tituloTablero">
          <div className="iconLarge"></div>Configuración
        </div>
        <div className="card cardLarge cardLargeCenter cardLargeCenterReducedSettings">
            <Users />
        </div>
      </div>
      <div className="containerSettingR">
        <div className="card cardRight cardRightReducedSetting">
          <Info />
          <Systemhealth />
        </div>

      </div>
    </>
  );
}
