import { useEffect, useState } from "react";
import { TragIAFAS } from "Functions/tableroApi";
import { tragamonedasDetailsCierre } from "Models/models";
import Numbers from "components/Numbers/Numbers";


interface cierreProps {
    idCierre: number;
}

export default function Bills(props: cierreProps) {
    const [Bills, setBills] = useState<tragamonedasDetailsCierre.Billetero[]>();
    const [TBills, setTBills] = useState<tragamonedasDetailsCierre.Billetero>({
        Bill2000: 0,
        Bill1000: 0,
        Bill500: 0,
        Bill200: 0,
        Bill100: 0,
        Bill50: 0,
        Bill20: 0,
        Bill10: 0,
        Bill5: 0,
        Bill2: 0,
        Fecha: new Date('1982-06-15'),
        FKCierre: 0,
        IdArqueo: 0,
        idsala: '0',
        TBill: 0,
        TipoDispositivo: 0
    });
    const [BillsR, setBillsR] = useState<tragamonedasDetailsCierre.Billetero[]>();
    const [TBillsR, setTBillsR] = useState<tragamonedasDetailsCierre.Billetero>({
        Bill2000: 0,
        Bill1000: 0,
        Bill500: 0,
        Bill200: 0,
        Bill100: 0,
        Bill50: 0,
        Bill20: 0,
        Bill10: 0,
        Bill5: 0,
        Bill2: 0,
        Fecha: new Date('1982-06-15'),
        FKCierre: 0,
        IdArqueo: 0,
        idsala: '0',
        TBill: 0,
        TipoDispositivo: 0
    });
    useEffect(() => {
        TragIAFAS.getBilletero(props.idCierre).then(bill => {
            setBills(bill);
            let Total: tragamonedasDetailsCierre.Billetero = {
                Bill2000: 0,
                Bill1000: 0,
                Bill500: 0,
                Bill200: 0,
                Bill100: 0,
                Bill50: 0,
                Bill20: 0,
                Bill10: 0,
                Bill5: 0,
                Bill2: 0,
                Fecha: new Date('1982-06-15'),
                FKCierre: 0,
                IdArqueo: 0,
                idsala: '0',
                TBill: 0,
                TipoDispositivo: 0
            };
            bill.forEach(b => {
                const tMoney = (Total.Bill2000 + b.Bill2000) * 2000 + (Total.Bill1000 + b.Bill1000) * 1000 + (Total.Bill500 + b.Bill500) * 500
                    + (Total.Bill200 + b.Bill200) * 200 + (Total.Bill100 + b.Bill100) * 100 + (Total.Bill50 + b.Bill50) * 50 + (Total.Bill20 + b.Bill20) * 20
                    + (Total.Bill10 + b.Bill10) * 10;
                Total = {
                    Bill2000: Total.Bill2000 + b.Bill2000,
                    Bill1000: Total.Bill1000 + b.Bill1000,
                    Bill500: Total.Bill500 + b.Bill500,
                    Bill200: Total.Bill200 + b.Bill200,
                    Bill100: Total.Bill100 + b.Bill100,
                    Bill50: Total.Bill50 + b.Bill50,
                    Bill20: Total.Bill20 + b.Bill20,
                    Bill10: Total.Bill10 + b.Bill10,
                    Bill5: Total.Bill5 + b.Bill5,
                    Bill2: Total.Bill2 + b.Bill2,
                    Fecha: new Date('1982-06-15'),
                    FKCierre: 0,
                    IdArqueo: 0,
                    idsala: '0',
                    TBill: tMoney,
                    TipoDispositivo: 0
                }
            })
            setTBills(Total);
        })
        TragIAFAS.getBilleteroR(props.idCierre).then(bill => {
            setBillsR(bill);

            let Total: tragamonedasDetailsCierre.Billetero = {
                Bill2000: 0,
                Bill1000: 0,
                Bill500: 0,
                Bill200: 0,
                Bill100: 0,
                Bill50: 0,
                Bill20: 0,
                Bill10: 0,
                Bill5: 0,
                Bill2: 0,
                Fecha: new Date('1982-06-15'),
                FKCierre: 0,
                IdArqueo: 0,
                idsala: '0',
                TBill: 0,
                TipoDispositivo: 0
            };
            bill.forEach(b => {
                const tMoney = (Total.Bill2000 + b.Bill2000) * 2000 + (Total.Bill1000 + b.Bill1000) * 1000 + (Total.Bill500 + b.Bill500) * 500
                    + (Total.Bill200 + b.Bill200) * 200 + (Total.Bill100 + b.Bill100) * 100 + (Total.Bill50 + b.Bill50) * 50 + (Total.Bill20 + b.Bill20) * 20
                    + (Total.Bill10 + b.Bill10) * 10;
                Total = {
                    Bill2000: Total.Bill2000 + b.Bill2000,
                    Bill1000: Total.Bill1000 + b.Bill1000,
                    Bill500: Total.Bill500 + b.Bill500,
                    Bill200: Total.Bill200 + b.Bill200,
                    Bill100: Total.Bill100 + b.Bill100,
                    Bill50: Total.Bill50 + b.Bill50,
                    Bill20: Total.Bill20 + b.Bill20,
                    Bill10: Total.Bill10 + b.Bill10,
                    Bill5: Total.Bill5 + b.Bill5,
                    Bill2: Total.Bill2 + b.Bill2,
                    Fecha: new Date('1982-06-15'),
                    FKCierre: 0,
                    IdArqueo: 0,
                    idsala: '0',
                    TBill: tMoney,
                    TipoDispositivo: 0
                }
            })
            setTBillsR(Total);
        })
    }, [props.idCierre])


    return <div className="modalBilletero">
        <div>
            <table className="tabla tablaSup">
                <thead>
                    <tr className="cardTotales">
                        <th>
                            <div className="celda dataFont smallTotal">totales</div>
                        </th>
                        <th>
                            <div className="celda dataFont smallTotal">
                                <span className="dataSymbol">$</span>
                                <Numbers value={(TBills.Bill2000 + TBillsR.Bill2000) * 2000} intenger={true} />
                            </div>
                        </th>
                        <th>
                            <div className="celda dataFont smallTotal">
                                <span className="dataSymbol">$</span>
                                <Numbers value={TBills.Bill1000 * 1000} intenger={true} />
                            </div>
                        </th>
                        <th>
                            <div className="celda dataFont smallTotal">
                                <span className="dataSymbol">$</span>
                                <Numbers value={TBills.Bill500 * 500} intenger={true} />
                            </div>
                        </th>
                        <th>
                            <div className="celda dataFont smallTotal">
                                <span className="dataSymbol">$</span>
                                <Numbers value={TBills.Bill200 * 200} intenger={true} />
                            </div>
                        </th>
                        <th>
                            <div className="celda dataFont smallTotal">
                                <span className="dataSymbol">$</span>
                                <Numbers value={TBills.Bill100 * 100} intenger={true} />
                            </div>
                        </th>
                        <th>
                            <div className="celda dataFont smallTotal">
                                <span className="dataSymbol">$</span>
                                <Numbers value={TBills.Bill50 * 50} intenger={true} />
                            </div>
                        </th>
                        <th>
                            <div className="celda dataFont smallTotal">
                                <span className="dataSymbol">$</span>
                                <Numbers value={TBills.Bill20 * 20} intenger={true} />
                            </div>
                        </th>
                        <th>
                            <div className="celda dataFont smallTotal">
                                <span className="dataSymbol">$</span>
                                <Numbers value={TBills.Bill10 * 10} intenger={true} />
                            </div>
                        </th>
                        <th>
                            <div className="celda dataFont smallTotal">
                                <span className="dataSymbol">$</span>
                                <Numbers value={TBills.TBill} intenger={true} total={true} />
                            </div>
                        </th>
                    </tr>
                </thead>
                <thead>
                    <tr className="cardTotales ">
                        <th>
                            <div className="celda dataFont smallTotal centerUnico">total</div>
                        </th>
                        <th><div className="celda dataFont smallTotal centerUnico">Bill 2000</div></th>
                        <th><div className="celda dataFont smallTotal centerUnico">Bill 1000</div></th>
                        <th><div className="celda dataFont smallTotal centerUnico">Bill 500</div></th>
                        <th><div className="celda dataFont smallTotal centerUnico">Bill 200</div></th>
                        <th><div className="celda dataFont smallTotal centerUnico">Bill 100</div></th>
                        <th><div className="celda dataFont smallTotal centerUnico">Bill 50</div></th>
                        <th><div className="celda dataFont smallTotal centerUnico">Bill 20</div></th>
                        <th><div className="celda dataFont smallTotal centerUnico">Bill 10</div></th>
                        <th><div className="celda dataFont smallTotal centerUnico">Total</div></th>
                    </tr>
                </thead>
            </table>
        </div>
        <div className="modalBilleteroRow">
            <div className="Billetero">
                <div className="">
                    <div className="cardTitulo">
                        <div className="icon "></div>Tragamonedas

                    </div>
                    <table className="tabla">
                        <thead>
                            <tr className="cardTotales">
                                <th>
                                    <div className="celda dataFont smallTotal">totales</div>
                                </th>
                                <th>
                                    <div className="celda dataFont smallTotal">
                                        <span className="dataSymbol">$</span>
                                        <Numbers value={TBills.Bill2000 * 2000} intenger={true} />
                                    </div>
                                </th>
                                <th>
                                    <div className="celda dataFont smallTotal">
                                        <span className="dataSymbol">$</span>
                                        <Numbers value={TBills.Bill1000 * 1000} intenger={true} />
                                    </div>
                                </th>
                                <th>
                                    <div className="celda dataFont smallTotal">
                                        <span className="dataSymbol">$</span>
                                        <Numbers value={TBills.Bill500 * 500} intenger={true} />
                                    </div>
                                </th>
                                <th>
                                    <div className="celda dataFont smallTotal">
                                        <span className="dataSymbol">$</span>
                                        <Numbers value={TBills.Bill200 * 200} intenger={true} />
                                    </div>
                                </th>
                                <th>
                                    <div className="celda dataFont smallTotal">
                                        <span className="dataSymbol">$</span>
                                        <Numbers value={TBills.Bill100 * 100} intenger={true} />
                                    </div>
                                </th>
                                <th>
                                    <div className="celda dataFont smallTotal">
                                        <span className="dataSymbol">$</span>
                                        <Numbers value={TBills.Bill50 * 50} intenger={true} />
                                    </div>
                                </th>
                                <th>
                                    <div className="celda dataFont smallTotal">
                                        <span className="dataSymbol">$</span>
                                        <Numbers value={TBills.Bill20 * 20} intenger={true} />
                                    </div>
                                </th>
                                <th>
                                    <div className="celda dataFont smallTotal">
                                        <span className="dataSymbol">$</span>
                                        <Numbers value={TBills.Bill10 * 10} intenger={true} />
                                    </div>
                                </th>
                                <th>
                                    <div className="celda dataFont smallTotal">
                                        <span className="dataSymbol">$</span>
                                        <Numbers value={TBills.TBill} intenger={true} total={true} />
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <thead className="textFont">
                            <tr>
                                <th>máquina</th>
                                <th>Bill 2000</th>
                                <th>Bill 1000</th>
                                <th>Bill 500</th>
                                <th>Bill 200</th>
                                <th>Bill 100</th>
                                <th>Bill 50</th>
                                <th>Bill 20</th>
                                <th>Bill 10</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Bills?.map((bill, i) => {
                                const Total = (bill.Bill2000 * 2000 + bill.Bill1000 * 1000 + bill.Bill500 * 500 + bill.Bill200 * 200 + bill.Bill100 * 100 + bill.Bill50 * 50 + bill.Bill20 * 20 + bill.Bill10 * 10);
                                return <tr key={`tablaBills${i}`}>
                                    <td> <div className="celda celdaKey">
                                        {bill.idsala}
                                    </div>
                                    </td>
                                    <td>
                                        <div className="celda dataFont">
                                            <Numbers value={bill.Bill2000} intenger={true} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="celda dataFont">
                                            <Numbers value={bill.Bill1000} intenger={true} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="celda dataFont">
                                            <Numbers value={bill.Bill500} intenger={true} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="celda dataFont">
                                            <Numbers value={bill.Bill200} intenger={true} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="celda dataFont">
                                            <Numbers value={bill.Bill100} intenger={true} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="celda dataFont">
                                            <Numbers value={bill.Bill50} intenger={true} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="celda dataFont">
                                            <Numbers value={bill.Bill20} intenger={true} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="celda dataFont">
                                            <Numbers value={bill.Bill10} intenger={true} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="celda dataFont">
                                            <span className="dataSymbol">$</span>
                                            <Numbers value={Total} total={true} intenger={true} />
                                        </div>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>

            </div>

            <div className="Billetero">
                <div className="">
                    <div className="cardTitulo">
                        <div className="icon "></div>Ruletas

                    </div>
                    <table className="tabla">
                        <thead>
                            <tr className="cardTotales">
                                <th>
                                    <div className="celda dataFont smallTotal">totales</div>
                                </th>
                                <th>
                                    <div className="celda dataFont smallTotal">
                                        <span className="dataSymbol">$</span>
                                        <Numbers value={TBillsR.Bill2000 * 2000} intenger={true} />
                                    </div>
                                </th>
                                <th>
                                    <div className="celda dataFont smallTotal">
                                        <span className="dataSymbol">$</span>
                                        <Numbers value={TBillsR.Bill1000 * 1000} intenger={true} />
                                    </div>
                                </th>
                                <th>
                                    <div className="celda dataFont smallTotal">
                                        <span className="dataSymbol">$</span>
                                        <Numbers value={TBillsR.Bill500 * 500} intenger={true} />
                                    </div>
                                </th>
                                <th>
                                    <div className="celda dataFont smallTotal">
                                        <span className="dataSymbol">$</span>
                                        <Numbers value={TBillsR.Bill200 * 200} intenger={true} />
                                    </div>
                                </th>
                                <th>
                                    <div className="celda dataFont smallTotal">
                                        <span className="dataSymbol">$</span>
                                        <Numbers value={TBillsR.Bill100 * 100} intenger={true} />
                                    </div>
                                </th>
                                <th>
                                    <div className="celda dataFont smallTotal">
                                        <span className="dataSymbol">$</span>
                                        <Numbers value={TBillsR.Bill50 * 50} intenger={true} />
                                    </div>
                                </th>
                                <th>
                                    <div className="celda dataFont smallTotal">
                                        <span className="dataSymbol">$</span>
                                        <Numbers value={TBillsR.Bill20 * 20} intenger={true} />
                                    </div>
                                </th>
                                <th>
                                    <div className="celda dataFont smallTotal">
                                        <span className="dataSymbol">$</span>
                                        <Numbers value={TBillsR.Bill10 * 10} intenger={true} />
                                    </div>
                                </th>
                                <th>
                                    <div className="celda dataFont smallTotal">
                                        <span className="dataSymbol">$</span>
                                        <Numbers value={TBillsR.TBill} intenger={true} total={true} />
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <thead>
                            <tr className="textFont">
                                <th>máquina</th>
                                <th>Bill 2000</th>
                                <th>Bill 1000</th>
                                <th>Bill 500</th>
                                <th>Bill 200</th>
                                <th>Bill 100</th>
                                <th>Bill 50</th>
                                <th>Bill 20</th>
                                <th>Bill 10</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {BillsR?.map((bill, i) => {
                                const Total = (bill.Bill2000 * 2000 + bill.Bill1000 * 1000 + bill.Bill500 * 500 + bill.Bill200 * 200 + bill.Bill100 * 100 + bill.Bill50 * 50 + bill.Bill20 * 20 + bill.Bill10 * 10);
                                return <tr key={`tablaBills${i}`}>
                                    <td> <div className="celda celdaKey">
                                        {bill.idsala}
                                    </div>
                                    </td>
                                    <td>
                                        <div className="celda dataFont">
                                            <Numbers value={bill.Bill2000} intenger={true} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="celda dataFont">
                                            <Numbers value={bill.Bill1000} intenger={true} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="celda dataFont">
                                            <Numbers value={bill.Bill500} intenger={true} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="celda dataFont">
                                            <Numbers value={bill.Bill200} intenger={true} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="celda dataFont">
                                            <Numbers value={bill.Bill100} intenger={true} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="celda dataFont">
                                            <Numbers value={bill.Bill50} intenger={true} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="celda dataFont">
                                            <Numbers value={bill.Bill20} intenger={true} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="celda dataFont">
                                            <Numbers value={bill.Bill10} intenger={true} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="celda dataFont">
                                            <span className="dataSymbol">$</span>
                                            <Numbers value={Total} total={true} intenger={true} />
                                        </div>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>


    </div>
}